import React, { useContext, useEffect } from "react";
import clss from "./DisasterListItem.module.css";
import { Box } from "@mui/material";
import { sbHeightCtx } from "../../store/sidebarWiHeContext";
import PopoverBtn from "./PopoverBtn";

const DisasterListItem = (props) => {
  //console.log(props)
  let bgc = "";
  const { width } = useContext(sbHeightCtx);
  sessionStorage.onid == props.id ? bgc="lightgreen" : bgc="lightgrey";

  useEffect(() => {
    //console.log("DisasterListItem: ", props.onStatus);
  }, [props.onStatus]);

  //let sbwidth = width * 0.9;
  return (
    <Box 
      bgcolor={bgc}
      borderRadius={3}
    >
      <li key={Math.random()} className={clss.meal} style={{ width: width }}>
        <div>
          <h4>{props.name}</h4>
          <div className={clss.inputdate}>{props.ipdt}</div>
          <div className={clss.inputdate}>{props.addr}</div>
        </div>
        <div>
          <PopoverBtn
            ingox={props.ox}
            id={props.id}
            name={props.name}
            ipdt={props.ipdt}
            addr={props.addr}
            dsid={props.dsid}
            lat={props.lat}
            lon={props.lon}
            onDataChanged={props.onDataChanged}
            apiAddr={props.apiAddr}
            onStatus={props.onStatus}
            onSelectDS={props.onSelectDS}
            hdmf={props.hdmf}
            mapfresh={props.mapfresh}
          />
        </div>
      </li>
    </Box>
  );
};

export default DisasterListItem;
