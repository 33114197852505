import react, { useRef, useEffect, useState, Fragment } from "react";
import { Box, Paper } from "@mui/material";
import clss from "./Mfmsg.module.css";
import ReactDOM from 'react-dom';
import {QRCodeSVG} from 'qrcode.react';

  <QRCodeSVG value="https://reactjs.org/" />
  document.getElementById('mountNode')


const MFqrcode = (props) => {
  const code = props.code
  console.log(code)

  const viewHandler = () => { // 닫기 버튼 클릭시
    props.viewqr();
  };

  return (
    <Fragment>
      <Box className={clss.background} >
        <div className={clss.maincss} onClick={viewHandler} >
          <div className={clss.titlebar}>
            <span className={{ fontSize: "1.5rem", fontWeight: "bold", color: "white", alignContent: "center" }}>상태변경QR (출입관리자에 제시)</span>
          </div>
          <QRCodeSVG
            value={code}
            size={"100%"}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"Q"}
            includeMargin={true}
            // imageSettings={{
            //   src: "https://static.zpao.com/favicon.png",
            //   x: undefined, 
            //   y: undefined,
            //   height: 24,
            //   width: 24,
            //   excavate: true,
            // }}
          />
        </div>
      </Box>
    </Fragment>
  );
};

export default MFqrcode;
