import React, { useState, useEffect, useRef, Fragment } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import axios from "axios";
import cls from "./UpperSgnBrd.module.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export default function UpperSgnBrd(props) {
  const [list, setList] = useState([]);
  const [transtxt, setTranstxt] = useState([]);
  const initRef = useRef(0); // 초기값 저장 useRef

  // 엘레멘트접근 useRef
  const txtRef = useRef();
  const tRef = useRef();
  const sRef = useRef();
  const wRef = useRef();
  const mRef = useRef();
  const aRef = useRef();
  const rRef = useRef();

  // 현재값 저장 useRef
  const NowtotlRef = useRef(0);
  const NowstrtRef = useRef();
  const NowwaitRef = useRef(0);
  const NowmoveRef = useRef(0);
  const NowactvRef = useRef(0);
  const NowrtrnRef = useRef(0);

  // 이전값 저장 useRef
  const BeforetotlRef = useRef(0);
  const BeforestrtRef = useRef(0);
  const BeforewaitRef = useRef(0);
  const BeforemoveRef = useRef(0);
  const BeforeactvRef = useRef(0);
  const BeforertrnRef = useRef(0);

  useEffect(() => {
    console.log(props)
    const getStatus = async () => {
      let wstring = `${props.apiAddr}/get/signboard?dsid=${sessionStorage.ondsid}`;
      console.log(wstring);
      const result = await axios.get(wstring);
      const responseData = result.data;
      console.log(responseData);
      saveNowRef(responseData)
      setList(responseData);
      txtlist();
      // tmp = result.data;
      // setorgmfData(tmp);
    };
    getStatus();
  }, [props.viewSGN]);

  const saveNowRef = (data) => { // 현재값 저장
    NowtotlRef.current = data[0].totl;
    NowstrtRef.current = data[0].strt;
    NowwaitRef.current = data[0].wait;
    NowmoveRef.current = data[0].move;
    NowactvRef.current = data[0].actv;
    NowrtrnRef.current = data[0].rtrn;
    
  }

  const saveBeforeRef = (item) => { // 이전값 저장
    BeforetotlRef.current = item.totl;
    BeforestrtRef.current = item.strt;
    BeforewaitRef.current = item.wait;
    BeforemoveRef.current = item.move;
    BeforeactvRef.current = item.actv;
    BeforertrnRef.current = item.rtrn;
  }

  let count ;
  function viewRef () { // 변경값 배경반전 표시
    setTimeout(() => {
      console.log("배경반전호출수" +  ++count)
      if(initRef.current > 1){
        setTimeout(() => {
          tRef.current.classList.remove(cls.blackblink);  
          sRef.current.classList.remove(cls.greyblink);  
          wRef.current.classList.remove(cls.greenblink);
          mRef.current.classList.remove(cls.blueblink);
          aRef.current.classList.remove(cls.redblink);
          rRef.current.classList.remove(cls.greyblink); 
        }, 3000);
        if(sessionStorage.sgnTotl == "true") {tRef.current.classList.add(cls.blackblink); console.log("totl")}
        if(sessionStorage.sgnStrt == "true") {sRef.current.classList.add(cls.greyblink); console.log("strt")}
        if(sessionStorage.sgnWait == "true") {wRef.current.classList.add(cls.greenblink); console.log("wait")}
        if(sessionStorage.sgnMove == "true") {mRef.current.classList.add(cls.blueblink); console.log("move")}
        if(sessionStorage.sgnActv == "true") {aRef.current.classList.add(cls.redblink); console.log("actv")}
        if(sessionStorage.sgnRtrn == "true") {rRef.current.classList.add(cls.greyblink); console.log("rtrn")}
      }
      initRef.current = initRef.current + 1;
    }
    , 10);
  }

  const txtlist = () => {
    if(sessionStorage.refreshTrantxt == undefined) return;
    const newtxt = sessionStorage.refreshTrantxt;
    const addtxt = [newtxt, ...transtxt];
    setTranstxt(addtxt);
    console.log(addtxt)
  }

  const makelist = transtxt.map((item) => (
    <div key={Math.random()} >
      {item}
    </div>
  ))

  const copy = () => {
    const copyText = txtRef.current;
    const textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
    alert("복사되었습니다.")
  }

  const signBrd = list.map((item) => (
    <Fragment>
      {/* {console.log(NowwaitRef.current + '/' + BeforewaitRef.current)} */}
      {NowtotlRef.current != BeforetotlRef.current ? sessionStorage.sgnTotl = true : sessionStorage.sgnTotl = false}
      {NowstrtRef.current != BeforestrtRef.current ? sessionStorage.sgnStrt = true : sessionStorage.sgnStrt = false}
      {NowwaitRef.current != BeforewaitRef.current ? sessionStorage.sgnWait = true : sessionStorage.sgnWait = false}
      {NowmoveRef.current != BeforemoveRef.current ? sessionStorage.sgnMove = true : sessionStorage.sgnMove = false}
      {NowactvRef.current != BeforeactvRef.current ? sessionStorage.sgnActv = true : sessionStorage.sgnActv = false}
      {NowrtrnRef.current != BeforertrnRef.current ? sessionStorage.sgnRtrn = true : sessionStorage.sgnRtrn = false}

      <div key={Math.random()}  className={cls.sgntxtbox} ref={txtRef} >
        <LightTooltip title="내용복사" placement="bottom" arrow style={{marginLeft:"5px"}} >
          <ContentCopyIcon style={{float:"right"}} onClick={copy}/>
        </LightTooltip>
        {makelist}
      </div>

      <LightTooltip title="전체 국가동원 자원 현황" placement="top" arrow style={{marginLeft:"5px"}} >
        <div key={Math.random()}  className={`${cls.sgnbox} ${cls.black}`} ref={tRef} >
          <Stack direction="column" key={Math.random()}>
            <Typography variant="h6" mb={-1.5} >
              전체
            </Typography>
            <Typography variant="h4" align="center">
              {item.totl}
            </Typography>
          </Stack>
        </div>
      </LightTooltip>

      <LightTooltip title="각 시도에서 동원령이후 재난지역으로 이동중(재난지역 도착전)" placement="top" >
        <div key={Math.random()}  className={`${cls.sgnbox} ${cls.grey}`} ref={sRef} >
          <Stack direction="column" key={Math.random()}>
            <Typography variant="h6" mb={-1.5} >
              집결중
            </Typography>
            <Typography variant="h4" align="center">
              {item.strt}
            </Typography>
          </Stack>
        </div>
      </LightTooltip>
      
      <LightTooltip title="자원집결지(방면지휘소)에서 대기중" placement="top">
        <div key={Math.random()}  className={`${cls.sgnbox} ${cls.green}`} ref={wRef}>
          <Stack direction="column" key={Math.random()}>
            <Typography variant="h6" mb={-1.5} >
              대기
            </Typography>
            <Typography variant="h4" align="center">
              {item.wait}
            </Typography>
          </Stack>
        </div>
      </LightTooltip>

      <LightTooltip title="자원집결지(방면지휘소) <-> 재난현장 이동중" placement="top" >
        <div key={Math.random()}  className={`${cls.sgnbox} ${cls.blue}`} ref={mRef}>
          <Stack direction="column" key={Math.random()}>
            <Typography variant="h6" mb={-1.5} >
              이동
            </Typography>
            <Typography variant="h4" align="center">
              {item.move}
            </Typography>
          </Stack>
        </div>
      </LightTooltip>

      <LightTooltip title="재난현장에서 활동중" placement="top" >
        <div key={Math.random()}  className={`${cls.sgnbox} ${cls.red}`} ref={aRef}>
          <Stack direction="column" key={Math.random()}>
            <Typography variant="h6" mb={-1.5} >
              활동
            </Typography>
            <Typography variant="h4" align="center">
              {item.actv}
            </Typography>
          </Stack>
        </div>
      </LightTooltip>

      <LightTooltip title="원소속 시도로 복귀" placement="top" arrow style={{marginRight:"3px"}}>
        <div id="uppersgn" key={Math.random()} className={`${cls.sgnbox} ${cls.grey}`} ref={rRef}>
          <Stack direction="column" key={Math.random()}>
            <Typography variant="h6" mb={-1.5} sx={{ letterSpacing:"-5px" }}>
              원대복귀
            </Typography>
            <Typography variant="h4" align="center">
            {item.rtrn}
            </Typography>
          </Stack>
        </div>
      </LightTooltip>
      {saveBeforeRef(item)}
    </Fragment>
    
  ));

  let upsgbpos ;
  const getposuppersgnbrd = () => { 
    const windowWidth = window.innerWidth;
    upsgbpos = windowWidth/2 - 425;
  }
  getposuppersgnbrd();

  return (
    <Box
      id="uppersgn"
      key={Math.random()}
      sx={{
        position: "fixed",
        top: 0,
        left: upsgbpos,
        
        width: 850,

        // marginLeft: "20%",
        //how to horizontal centering?
        // marginLeft: "300px",
        // marginRight: "calc(50% - 225px)",
        zIndex: 10000,
      }}
    >
      <Stack
        key={Math.random()}
        direction="row"
        gap={1}
        sx={{
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "60px",
          padding: "2px",
          borderRadius: "5px",
          backgroundColor: "lightgreen",
          zIndex: 10,
        }}
      >
        {signBrd}
      </Stack>
      {viewRef()}
    </Box>
  );
}
