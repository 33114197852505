import React, {useState} from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import MFregiApp from "./components/MFregister/MFregiApp";
import MFuserApp from "./components/MFuser/MFuserApp";
import io from "socket.io-client";

///////////////////////// 개발 또는 배포에 따라 주소 변경 /////////////////////////
let env = "l";
let apiAddr = "";
if (env == "l") {
  apiAddr = "https://qry.119survey.org";
} else {
  apiAddr = "http://localhost:8080";
}
///////////////////////// 개발 또는 배포에 따라 주소 변경 /////////////////////////

function App(props) {
  return (
    <div>
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/regi">regi</Link>
          </li>
          <li>
            <Link to="/user">user</Link>
          </li>
        </ul>
      </nav> */}
      <Routes>
        <Route path="/" element={<Home apiAddr={apiAddr} />} />
        <Route path="/regi" element={<MFregiApp apiAddr={apiAddr} />} />
        <Route path="/user" element={<MFuserApp apiAddr={apiAddr} />} />
      </Routes>
    </div>
  );
}

export default App;
