import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import App from "./App";
//const getBasename = (path: any) => path.substr(0, path.lastIndexOf('/'));
console.log(window.location.pathname);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // <BrowserRouter basename='/brd'>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
