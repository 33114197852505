import { useEffect } from 'react';
let map

function KakaoMap(props) {
  const scles = props.scle.split("^");
  // console.log(scles)
  const wd = scles[0]*1;
  const ht = scles[1]*1;

  const new_script = (src) => { 
    return new Promise((resolve, reject) => { 
      const script = document.createElement('script'); 
      script.src = src; 
      script.addEventListener('load', () => { 
        resolve(); 
      }); 
      script.addEventListener('error', e => { 
        reject(e); 
      }); 
      document.head.appendChild(script); 
    }); 
  };

  useEffect(() => { 
    //카카오맵 스크립트 읽어오기
    const my_script = new_script('https://dapi.kakao.com/v2/maps/sdk.js?appkey=b7967dc1f2c2b6f788a029bc7b701f09&libraries=services,clusterer,drawing"');
    //스크립트 읽기 완료 후 카카오맵 설정
    my_script.then(() => { 
      console.log('script loaded!!!');  
      const kakao = window['kakao']; 
      kakao.maps.load(() => {
        const mapContainer = document.getElementById('map');
        const options = { 
          center: new kakao.maps.LatLng(37.884878, 127.729656), //좌표설정
          level: 3 
        }; 
        
        const ismap = !map // 처음에만 맵 생성
        const iscor = (props.newcoor.length == 0); // 좌표값이 없으면 맵 생성

        if(ismap || iscor) { // 처음에만 맵 생성
          (map = new kakao.maps.Map(mapContainer, options)); //맵생성
          //지도종류 선택
          const mapTypeControl = new kakao.maps.MapTypeControl();
          map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
          //스카이뷰를 기본값으로 설정
          map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
        }
        //마커설정
        const markerPosition = new kakao.maps.LatLng(37.884878, 127.729656); 
        const marker = new kakao.maps.Marker({ 
          position: markerPosition
        }); 
        marker.setMap(map);

        //기존자료 좌표있으면 마커위치 이동  
        const newcoor = props.newcoor;
        const newlat = newcoor[0];
        const newlon = newcoor[1];
        const newlatlng = new kakao.maps.LatLng(newlat, newlon);
        if(newlat != undefined){
          // console.log(props.newcoor);
          map.setCenter(newlatlng);
          marker.setPosition(newlatlng);
        }
      
        window.kakao.maps.event.addListener(map, 'click', (mouseEvent) => {
          // 클릭한 위치의 좌표를 가져옵니다.
          const latlng = mouseEvent.latLng;
          // console.log(`클릭한 위치 - 위도: ${latlng.getLat()}, 경도: ${latlng.getLng()}`);
          let lat = latlng.getLat().toFixed(6);
          let lon = latlng.getLng().toFixed(6);
          let addr = "";

          //클릭한 위치의 주소를 가져옵니다.
          const geocoder = new kakao.maps.services.Geocoder();
          geocoder.coord2Address(latlng.getLng(), latlng.getLat(), (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
              addr = result[0].address.address_name.replace("강원특별자치도 ","");
              props.clickedvalue(lat, lon, addr);
            }
            else{
              props.clickedvalue(lat, lon, "주소없음");
            }
          });
          marker.setPosition(latlng);
        });
      });  
    }); 
  }, [props.newcoor]);

  return (
    <div >
      <div id="map" style={{width:wd, height:ht}} />
    </div>
  );
}

export default KakaoMap;
