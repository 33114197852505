import react, {useRef, useEffect, useState } from "react";
import Modal from "../../UI/Modal";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import axios from "axios";
import Map from "./Rdmap";
import Snackbar from "../../UI/Snackbar"

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Link,
  Button,
  Input,
  TextField
} from "@mui/material";
import {
  lightBlue,
  grey,
  red,
  blue,
  green,
  teal,
  purple,
  white,
  yellow
} from "@mui/material/colors";
import { SetMealOutlined } from "@mui/icons-material";

const statusTitle={
  fontWeight: "bold",
  backgroundColor: green[900],
  color: "white",
}
const mgrbox={
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: green[50],
  padding: "0px 10px 0px 10px", 
}
const mgrboxbtnsE={
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  backgroundColor: green[100],
  width: "30%",
}
const mgrboxbtnsN={
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  backgroundColor: yellow[100],
  width: "30%",
}

const mgrboxmap={
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  backgroundColor: grey[300],
  width: "60%",
}
const iptxt = {
  margin: "0.3rem",
}
const ipbtnE={
  fontWeight: "bold",
  backgroundColor: green[500],
  color: "white",
  marginTop: "1rem",
  marginBottom: "1rem",
  border: "solid 0.5px black",
}
const ipbtnN={
  fontWeight: "bold",
  backgroundColor: yellow[500],
  color: "black",
  marginTop: "1rem",
  marginBottom: "1rem",
  border: "solid 0.5px black",
}

const mgrbtn = {
  fontWeight: "bold",
  backgroundColor: green[400],
  color: "white",
  maxWidth: "1rem",
  padding: "0px 3px 0px 3px",
  marginRight: "0.5rem",
  border: "solid 0.5px black",
  //마우스 올렸을때
  "&:hover": {
    color: "black",
  },
}
const tblstyle = {
  fontWeight: "bold",
  backgroundColor: grey[200],
  color: "white",
}
const thead ={
  color: "white",
  fontWeight: "bold",
  backgroundColor: green[500],
  textAlign: "center",
}
const trow ={
  fontWeight: "bold",
  textAlign: "center",
}
const listtrow ={
  "&:hover": {
    backgroundColor: green[100],
  }, 
}
const fixedthead ={
  position: "sticky",
  top: 0,
  backgroundColor: green[500],
  color: "white",
  fontWeight: "bold",
  zIndex: 10,
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


const RendezvousMgr = (props) => {
  const [rdvlist, setRdvlist] = useState([]);
  const [scle, setScle] = useState("100^100");
  const [newcoor, setNewcoor] = useState([]);//[lat, lon, addr
  const [btnmode, setBtnmode] = useState("신규등록");//[lat, lon, addr
  const [getlist, setGetlist] = useState("");// 목록갱신

  const idRef = useRef();
  const sigunRef = useRef();
  const rnRef = useRef();
  const addrRef = useRef();
  const latRef = useRef();
  const lonRef = useRef();
  const mapctnrRef = useRef();
  
  useEffect(() => {
    const getrdvlist = async () => {
      // let wstring = `${props.apiAddr}/get/getrdvlist?id=${org}&id2=${eqp}&dsid=${sessionStorage.ondsid}`;
      let wstring = `${props.apiAddr}/get/getrdvlist`;
      const response = await axios.get(wstring);
      const responseData = response.data;
      console.log(responseData);
      setRdvlist(responseData);
    }
    getrdvlist()
  }, [getlist]);

  function editHandler(e) {
    //e의 상위 엘레맨트 td값 불러오기
    console.log(e.target.parentNode.parentNode.childNodes[0].innerText);
    idRef.current.value = e.target.parentNode.parentNode.childNodes[0].innerText;
    sigunRef.current.value = e.target.parentNode.parentNode.childNodes[1].innerText;
    rnRef.current.value = e.target.parentNode.parentNode.childNodes[2].innerText;
    addrRef.current.value = e.target.parentNode.parentNode.childNodes[3].innerText;
    latRef.current.value = e.target.parentNode.parentNode.childNodes[4].innerText;
    lonRef.current.value = e.target.parentNode.parentNode.childNodes[5].innerText;
    setNewcoor(newcoor => [latRef.current.value, lonRef.current.value, addrRef.current.value]);
    setBtnmode("수정");
  }

  const deleteHandler = (e) => { // 삭제
    if(window.confirm("삭제하시겠습니까?") == false) return;
    const id = e.target.parentNode.parentNode.childNodes[0].innerText;
    console.log(id)
    async function delrdvdata() {
      const wstring = `${props.apiAddr}/get/delrdvdata?dsid=${id}`; // 수정
      console.log(wstring);
      const response = await axios.get(wstring);
      if(response.data.result== "success"){
        callSnakbar("삭제되었습니다.","success")
        setGetlist(+new Date())
      }else{
        callSnakbar("삭제에 실패하였습니다.","error")
      }
    }
    delrdvdata();
  }


  // 텍스트 데이터를 받아서 공백을 제거하고 리턴
  const trmd = (txt) => {
    return (txt.trim());
  }

  const tblist = rdvlist.map((item) => (
    <TableRow key={item.id} sx={listtrow}>
      <TableCell sx={trow}>{item.id}</TableCell>
      <TableCell>{trmd(item.sigun)}</TableCell>
      <TableCell>{trmd(item.rname)}</TableCell>
      <TableCell>{trmd(item.raddr)}</TableCell>
      <TableCell>{trmd(item.lat)}</TableCell>
      <TableCell>{trmd(item.lon)}</TableCell>
      <TableCell sx={trow}>
        <Button id={item.id} sx={mgrbtn} onClick={editHandler} >수정</Button>
        <Button sx={mgrbtn} onClick={deleteHandler}>삭제</Button>
      </TableCell>
    </TableRow>
  ));

  const clikhdlr = () => {
    setScle(mapctnrRef.current.clientWidth + "^" + mapctnrRef.current.clientHeight);
  }
 
  console.log("lsakdjflaskdjflasdkjfl")

  const clickedvalue = (lat, lon, addr) => {
    console.log(lat + "-" + lon + "-" + addr)
    const arr = addr.split(" ");
    sigunRef.current.value = arr[0];
    latRef.current.value = lat;
    lonRef.current.value = lon;
    addrRef.current.value = addr;
  }

  const addrdvs = () => { // 집결지 신규 추가
    console.log("추가");
    setBtnmode("신규등록");
    initRef();
    idRef.current.value = "자동생성";
    sigunRef.current.value = "지도클릭시 자동생성";
    addrRef.current.value = "지도클릭시 자동생성";
    latRef.current.value = "지도클릭시 자동생성";
    lonRef.current.value = "지도클릭시 자동생성";
  }
  const initRef = () => { // 입력창 초기화
    idRef.current.value = "";
    sigunRef.current.value = "";
    rnRef.current.value = "";
    addrRef.current.value = "";
    latRef.current.value = "";
    lonRef.current.value = ""; 
  }
  const sendDataHandler = () => { // 입력창 데이터 전송
    // 진행여부 확인후 전송
    if(window.confirm("입력하신 내용을 전송하시겠습니까?") == false) return;

    // 입력여부 확인후 전송
    const entdid = idRef.current.value !== "";
    const entdsigun = sigunRef.current.value !== "";;
    const entdrn = rnRef.current.value !== "";
    const entdaddr = addrRef.current.value !== "";
    const entdlat = latRef.current.value !== "";
    const entdlon = lonRef.current.value !== "";

    const entdIsValid = entdid && entdsigun && entdrn && entdaddr && entdlat && entdlon;
    if(!entdIsValid){
      alert("입력되지 않은 항목이 있습니다.");
      return;
    }
    
    const rdvData = {
      id: idRef.current.value,
      sigun: sigunRef.current.value,
      name: rnRef.current.value,
      addr: addrRef.current.value,
      lat: latRef.current.value,
      lon: lonRef.current.value,
    };
    // console.log(rdvData);
    async function sendrdvdata() {
      const wstring = `${props.apiAddr}/post/sendrdvdata`; // 수정
      console.log(wstring);
      const response = await axios.post(wstring,rdvData);
      if(response.data.result== "success"){
        callSnakbar("수정되었습니다.","success")
        setGetlist(+new Date())
      }else{
        callSnakbar("수정에 실패하였습니다.","error")
      }
    }
    sendrdvdata();
  }

  //////////////  스낵바 호출 //////////////
  const [sbShow, setSbShow] = useState("");
  const callSnakbar = (msg,sort) => {
    // const msg = "삭제되었습니sdfsdfsd다.";
    // const sort = "warning" // info, success, warning, error
    setSbShow(+new Date() + "^" + msg + "^" + sort);
  };
  //////////////  스낵바 호출 //////////////

  return (
    <Modal onClose={props.onClose} modalSort={"rdvs"} >
        {/* <div id="del" onClick={callSnakbar("삭제되었습니다.","success")}>snackbar call</div> */}
        <Snackbar sbShow={sbShow} />
        <Box sx={{display:"flex", justifyContent:"space-evenly", color:"white"}} bgcolor={green[900]} >
          <LightTooltip title="자원집결지 신규 추가" placement="top" arrow style={{marginTop:"3px"}}>
            <AddBoxIcon sx={{color:"yellow"}} onClick={addrdvs}/>
          </LightTooltip>
          <Typography variant="h6" sx={statusTitle}>
            자원집결지 관리 
          </Typography>
          <LightTooltip title="입력초기화" placement="top" arrow style={{marginTop:"3px"}}>
            <RefreshIcon sx={{color:"yellow"}} onClick={initRef} />
          </LightTooltip>
          
        </Box>
        <Box sx={mgrbox}>
          <Box sx={btnmode == "신규등록" ? mgrboxbtnsN : mgrboxbtnsE} mt={1} mb={1}>
            <TextField size="small" label="연번" name="id" sx={iptxt} inputRef={idRef} InputLabelProps={{shrink:true}} disabled/>
            <TextField size="small" label="시군" name="sigun" sx={iptxt} inputRef={sigunRef} InputLabelProps={{shrink:true}} />
            <TextField size="small" label="집결지명" name="name" sx={iptxt} inputRef={rnRef} InputLabelProps={{shrink:true}}/>
            <TextField size="small" label="주소" name="addr" sx={iptxt} inputRef={addrRef} InputLabelProps={{shrink:true}}/>
            <TextField size="small" label="좌표(X)" name="lat" sx={iptxt} inputRef={latRef} InputLabelProps={{shrink:true}}/>
            <TextField size="small" label="좌표(Y)" name="lon" sx={iptxt} inputRef={lonRef} InputLabelProps={{shrink:true}}/>
          </Box>
          <Box sx={mgrboxmap} ref={mapctnrRef}>
            <Map scle={scle} clickedvalue={clickedvalue} newcoor={newcoor}/>
          </Box>
          <Button sx={btnmode == "신규등록" ? ipbtnN : ipbtnE} onClick={sendDataHandler}>{btnmode}</Button>
          
        </Box>
        <TableContainer component={Paper} sx={{height:"400px"}}>
          <Table
            size="small"
            aria-label="simple table"
          >
            <TableHead sx={fixedthead}>
              <TableRow bgcolor={green[500]} >
                <TableCell sx={thead}>연번</TableCell>
                <TableCell sx={thead}>시군</TableCell>
                <TableCell sx={thead}>집결지명</TableCell>
                <TableCell sx={thead}>주소</TableCell>
                <TableCell sx={thead}>좌표(Y)</TableCell>
                <TableCell sx={thead}>좌표(X)</TableCell>
                <TableCell sx={thead}>관리</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tblist}
            </TableBody>
          </Table>
        </TableContainer>
        {setTimeout(() => { clikhdlr() }, 1)}
    </Modal>
  );
};

export default RendezvousMgr;
