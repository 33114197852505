import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import Status from "../components/Contents/Status";
import Map from "../components/Map/Map";
import MobilizePower from "../components/Contents/MobilizedPower";
import DispatchList from "../components/Contents/DispatchList";
import UpperSgnBrd from "../components/Contents/UpperSgnBrd";
import ChatApp from "../components/Contents/ChatApp";
import { sbHeightCtx } from "../store/sidebarWiHeContext";
// import io from "socket.io-client";

// ///////////// socket.io //////////////
// const socket = io("https://chat.119survey.org/");
// ///////////// socket.io //////////////

export const Contents = (props) => {
  let thisContainerHeight = window.innerHeight - 65; // 65 is the height of the navbar
  const [width, setWidth] = useState(thisContainerHeight);
  const [mflist, setMflist] = useState(""); // 동원자원 목록
  // const [viewMFP, setViewMFP] = useState(false); // 동원자원 목록 표출 여부
  window.addEventListener("resize", () => {
    thisContainerHeight = window.innerHeight - 65;
    setWidth(thisContainerHeight);
  });
  const sbHeight = useContext(sbHeightCtx);
  const { height } = sbHeight;

  const handleMflist = (e) => {
    setMflist(+new Date());
  };

  return (
    <Box id="contents" bgcolor="lightgreen" flex={10} p={0} style={{ height: height }}>
      {props.start && (
        <UpperSgnBrd 
          apiAddr={props.apiAddr} 
          viewSGN={props.viewSGN}
          callupsgnbrdRefresh={props.callupsgnbrdRefresh}
        />
      )}
      <Map 
        mapfresh={props.mapfresh} 
        apiAddr={props.apiAddr} 
        LocRefrsh={props.LocRefrsh}
        viewmaptool={props.viewmaptool}
      />
      {props.start && (
        <Status
          apiAddr={props.apiAddr}
          mflist={handleMflist}
          onShowDS={props.onShowDS}
          viewmf={props.viewmf}
          statechanged={props.statechanged}
          mapfresh={props.mapfresh}
          viewMflist={props.setViewHandler}
          hidemf={props.hidemf}
          LocRefrsh={props.LocRefrsh}
          fullswitch={props.fullswitch}
        />
      )}
      {props.hdmf && (
        <MobilizePower
          apiAddr={props.apiAddr}
          mflist={mflist}
          viewMflist={props.setViewHandler}
        />
      )}
      {props.start && (
        <ChatApp
          apiAddr={props.apiAddr}
          refreshBrd={props.refreshBrd}
          refreshChat={props.refreshChat}
          reqmapfresh={props.reqmapfresh}
          fullswitch={props.fullswitch}
          // hidechat={props.hidechat}
          // viewchat={props.viewchat}
        />
      )}
    </Box>
  );
};
