/* global history */
/* global location */
/* global window */

/* eslint no-restricted-globals: ["off"] */

import React, { useEffect, useState } from "react";
import { Bluetooth, Fullscreen, Pets } from "@mui/icons-material";
import PortraitIcon from '@mui/icons-material/Portrait';
import SettingsIcon from '@mui/icons-material/Settings';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import OpenWith from '@mui/icons-material/OpenWith';
import Switch from '@mui/material/Switch';
import {
  AppBar,
  Toolbar,
  styled,
  Typography,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { blue, grey,red,orange } from "@mui/material/colors";

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const titlecontainer = {
  xs: "none", 
  sm: "block", 
  marginBottom:0,
  fontWeight: "bold",

}

const titlebar={
  paddingLeft: 10,
  paddingRight: 10,
  backgroundColor: red[800],
  border: "1px solid",
  borderRadius: "10px",
  fontWeight: "bold",
  lineHeight: "0.7",
}

const dsdetail = {
  marginTop: "-1.5px",
  lineHeight: "0.7",
  letterSpacing: "-0.5px",
}

const iconitem = {
  color: "white",
  width: 30,
  height: 30,
  // marginRight: 5,
  // marginLeft: 5,
};

const switchstyle = {
  backgroundColor: grey[200],
  borderRadius: 3,
}

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1px",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

export const Navbar = (props) => {
  // console.log(props.onStatus);
  let tstring, ingox = "";
  const selectedDS = props.onStatus;

  useEffect(() => {
    console.log("Navbar: ", props.onStatus);
  }, [props.onStatus]);

  const [open, setOpen] = useState(false);
  const [viewOX, setViewOX] = useState(false);

  if(selectedDS != ""){
    tstring = `${sessionStorage.onname}/${sessionStorage.onipdt}/${sessionStorage.onaddr}`;
    ingox = sessionStorage.oningox;
  }

  const callRDVlist = () => {
    props.onShowDS();
    sessionStorage.modalSort = "RDV";
  }

  const FullscreenOX = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  const viewOXHandler = (e) => { // 컴포넌트 전체 보이기/숨기기 설정
    const ox = e.target.checked;
    sessionStorage.fullswitch = ox;
    props.fullswitch(ox);
  }

  const nochange = false; // 전체화면 스위치 최초 활성화를 위한 더미
  useEffect(() => {
      const sw = document.getElementById("fsw");
      sw.click();
  }
  , [nochange]);  

  let scale = 1;
  const zoombrsr = (e) => {
    // 브라우져 확대
    const sort = e.target.id;


    if(sort == "out"){
      scale += 0.05;
      console.log(sort+ scale);
      document.body.style.zoom = scale;
    }else{
      scale -= 0.05;
      console.log(sort + scale);
      document.body.style.zoom = scale;
    }


  }
  
  return (
    <div>
      <AppBar 
        position="sticky"
        style={ingox == "1" ? {backgroundColor: orange[900]} : {backgroundColor: grey[500]}}
      >
        <StyledToolbar>
          <Box >
            <Typography
              variant="h4"
              //sx={{ display: { xs: "none", sm: "block", marginBottom:0 }}}
              sx={titlecontainer}
            >
              <span>동원자원관리시스템</span>
            </Typography>
            {selectedDS != "" ? <Typography variant="h7" sx={dsdetail}>{tstring}</Typography>: null}
            
          </Box>
          <Pets sx={{ display: { xs: "block", sm: "none" } }} />
          <Icons>
            <ZoomOutIcon sx={iconitem} onClick={zoombrsr} id="in" />
            <ZoomInIcon sx={iconitem} onClick={zoombrsr} id="out" />
            <Switch onClick={viewOXHandler} sx={switchstyle} size="small" id="fsw" />
            <OpenWith sx={iconitem} onClick={FullscreenOX} />
            {/* <PortraitIcon sx={iconitem} /> */}
            <SettingsIcon 
              sx={iconitem}
              onClick={() => setOpen(true)}
            />
          </Icons>
          {/* <UserBox onClick={() => setOpen(true)}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              src="https://th.bing.com/th?id=OIP.iQpntdoa9DkZuar82QxBZQHaGC&w=276&h=225&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2"
            />
            <Typography variant="span">John</Typography>
          </UserBox> */}
        </StyledToolbar>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={callRDVlist}>자원집결지관리</MenuItem>
          <MenuItem onClick={() => location.reload()}>Logout</MenuItem>
        </Menu>
      </AppBar>
    </div>
  );
};
