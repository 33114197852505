import {QrScanner} from '@yudiel/react-qr-scanner';
import React, { Fragment, useState } from 'react';
import io from "socket.io-client";
import axios from "axios";
import Snackbar from "../../UI/Snackbar"

///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////

const scanbtn = {
  width: '100%',
  marginTop: '10px',
  padding: '10px',
  height: '50px',
  backgroundColor: '#000000',
  color: '#ffffff',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  border: 'none',
  borderRadius: '10px',
  cursor: 'pointer',
}

const resulttxt = {
  width: '90%',
  padding: '10px',
  height: '50px',
  backgroundColor: '#ffffff',
  color: '#000000',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  margin: '10px',
  border: '1px solid #000000',
  borderRadius: '10px',
  cursor: 'pointer',
  marginTop: '20px',
  marginRight: '20px',
  marginLeft: '20px',
  textAlign: 'center',
}


// 대기중 ? 0 
// 이동중 ? 1 
// 활동중 ? 2 
// 원복귀 ? 3
const Qrcodereader = (props) => {
  const [result, setResult] = useState('No result'); // 스캔결과
  const [scanox, setScanox] = useState(false); // 스캔여부

  ///////////// socket.io //////////////
  // useEffect(() => {
  //   console.log("socket.on useEffect")
  //   socket.on("stsync", (data) => {  // 소켓서버에서 보낸 자료를 받음
  //     const { name, msg, time } = data;
  //     console.log(+new Date()+" : "+name+" : "+msg+" : "+time);
  //     // const { name, msg, time } = data;
  //     // const item = new LiModel(name, msg, time);
  //     // item.makeLi();
  //     alert(+new Date()+" : "+name+" : "+msg+" : "+time );
  //   });
  // }, []);  

  const sendsync = (sort,org,eqp,csgn) => {  // 소캣서버로 갱신변경 자료를 보냄
    // yy-dd-mm hh:mm:ss 값으로 변환
    const nowdt = new Date()
    const yy = nowdt.getFullYear()
    const mm = nowdt.getMonth()+1
    const dd = nowdt.getDate()
    const hh = nowdt.getHours()
    const mi = nowdt.getMinutes()
    const nowdtstr = `${yy}.${mm}.${dd} ${hh}:${mi}`
    const param = {
      sort: sort,
      inputuser: sessionStorage.mfmid,
      dsid: sessionStorage.selectedDSid.trim(),
      org: org, // 기관 서울,경기 등
      eqp: eqp, // 장비 종류
      csgn: csgn, // 장비명 
      transtxt: `${nowdtstr} ${org} ${csgn.trim()} ${sessionStorage.selectedFromState} -> ${sessionStorage.selectedToState} 변경`,
    };
    console.log(param);
    socket.emit("stsync", param);
  };
  ///////////// socket.io //////////////

  let arr, seqnum, dsid, org, csgn, eqp, state, status;
  const handleScan = data => { // qr스캔시 성공시
    if (data) {
      console.log(data);
      // setResult(data);
      arr = data.split("^");
      seqnum = arr[0];
      dsid = arr[1];
      org = arr[2].split(" ")[0];
      csgn = arr[2].split(" ")[1];

      if(csgn.indexOf("펌프") > -1){ // 펌프일때
        eqp = "펌프";
      }else if(csgn.indexOf("탱크") > -1){ // 발전기일때
        eqp = "물탱크";
      }else if(csgn.indexOf("화학") > -1){ // 발전기일때
        eqp = "화학";
      }else if(csgn.indexOf("험지") > -1){ // 발전기일때
        eqp = "험지";
      }else if(csgn.indexOf("구조") > -1){ // 발전기일때
        eqp = "구조";
      }else if(csgn.indexOf("구급") > -1){ // 발전기일때
        eqp = "구급";
      }else if(csgn.indexOf("헬기") > -1){ // 발전기일때
        eqp = "헬기";
      }else if(csgn.indexOf("지휘") > -1){ // 발전기일때
        eqp = "지휘";
      }else{
        eqp = "";
      }

      state = arr[3];
      sessionStorage.selectedFromState = state;
      if(state == "대기중"){
        status = 1; // 이동중으로
        sessionStorage.selectedToState = "이동중";
      }
      if(state == "이동중" || state == "활동중" || state == "집결중"){
        status = 0; // 대기중으로
        sessionStorage.selectedToState = "대기중";
      }

      console.log(seqnum, dsid, csgn,state, status);
      sendStateChagedData(seqnum, dsid, org, eqp, csgn, state, status);
      setScanox(true);
    }
  }

  const handleError = err => {
    console.error(err); // 에러메시지
  }    

  const scanHandler = () => { // 재스캔 버튼 클릭시
    setScanox(!scanox);
    setResult("");
  }

  async function sendStateChagedData(seqnum,dsid,org,eqp,csgn,state,status) {
    // 상태변경 DB입력
    const wstring = `${props.apiAddr}/get/sendStateChagedDatafromQr?dsid=${dsid}&seqnum=${seqnum}&status=${status}`;
    console.log(wstring);
    const response = await axios.get(wstring);
    const result = response.data.result;
    if (result == "success") {
      // alert("등록되었습니다.");
      callSnakbar("수정되었습니다.","success")
      setTimeout(() => {
        sendsync("stateChaged", org, eqp, csgn);
      }, 100);

      let txt = `${org} ${csgn.trim()} ${sessionStorage.selectedFromState} -> ${sessionStorage.selectedToState} 변경`
      let secondSpaceIndex = txt.indexOf(' ', txt.indexOf(' ') + 1);
      // 두 번째 공백을 찾았다면, 그 위치에서 줄바꿈으로 대체합니다.
      if (secondSpaceIndex !== -1) {
          txt = txt.substring(0, secondSpaceIndex) + '\n' + txt.substring(secondSpaceIndex + 1);
      }
      setResult(txt);

    } else {
      // alert("등록에 실패하였습니다.");
      callSnakbar("수정에 실패했습니다.","error")
    }
  }

  //////////////  스낵바 호출 //////////////
  const [sbShow, setSbShow] = useState("");
  const callSnakbar = (msg,sort) => {
    // const msg = "삭제되었습니sdfsdfsd다.";
    // const sort = "warning" // info, success, warning, error
    setSbShow(+new Date() + "^" + msg + "^" + sort);
  };
  //////////////  스낵바 호출 //////////////

  return (
    <Fragment>
      <Snackbar sbShow={sbShow} />
      <QrScanner
        // onDecode={(result) => console.log(result)}
        // onError={(error) => console.log(error?.message)}
        onDecode={handleScan}
        onError={handleError}
        stopDecoding={scanox}
      />
      {/* <p>{result}</p> */}
      <span><button style={scanbtn} onClick={scanHandler}>재스캔</button></span>
      <div style={resulttxt}>
        <p>{result}</p>
      </div>
    </Fragment>
  );
}

export default Qrcodereader;
