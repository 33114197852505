import react, { useEffect, useState } from "react";
import { Sidebar } from "./layout/Sidebar";
import { Contents } from "./layout/Contents";
// import DisasterAdd from "./components/DisasterList/DisasterAdd";
import RendezvousMgr from "./components/Modal/Rendezvous";
import MFlistExcelInput from "./components/Modal/MflistExcelinput";
import { Box, Stack, createTheme } from "@mui/material";
import { sbHeightCtx } from "./store/sidebarWiHeContext";
import { Navbar } from "./layout/Navbar";
import io from "socket.io-client";
import Login from "./components/Login/Login";

///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////

const Home = (props) => {
  let sbContainerHeight = window.innerHeight - 65; // 65 is the height of the navbar
  const [height, setHeight] = useState(sbContainerHeight);
  let sbContainerWidth = ((window.innerWidth * 2) / 12) * 0.9;
  const [width, setWidth] = useState(sbContainerWidth);

  window.addEventListener("resize", () => {
    sbContainerHeight = window.innerHeight - 65;
    setHeight(sbContainerHeight);
    sbContainerWidth = ((window.innerWidth * 2) / 12) * 0.9;
    setWidth(sbContainerWidth);
  });

  useEffect(() => {
    sessionStorage.clear(); // 세션스토리지 초기화
  }, []);

  ///////////// socket.io //////////////
  useEffect(() => {
    socket.on("stsync", (data) => {
      // 소켓서버에서 보낸 자료를 받음
      const { sort, inputuser, dsid, org, eqp, csgn, transtxt, time, time2 } = data;
      sessionStorage.refreshSort = sort;
      sessionStorage.refreshOrg = org;
      sessionStorage.refreshEqp = eqp;
      sessionStorage.refreshCsgn = csgn;
      sessionStorage.refreshTrantxt = transtxt;
      sessionStorage.ondsid == undefined ? sessionStorage.ondsid = "" : sessionStorage.ondsid = sessionStorage.ondsid.trim();
      if(dsid == sessionStorage.ondsid.trim()){
        if(sort == "stateChaged"){ // 상태만 변경이면 
          setStatchanged(+new Date());  //상황판 갱신요청
          setViewSGN(+new Date());  // 사인보드 갱신요청
          mapfreshHandler();  // 맵 갱신요청
        }else{ // 전체변경이면
          onSelectDSHandler() 
        }
      }
    });
  }, []);

  // const sendsync = () => {  // 소캣서버로 자료를 보냄
  //   const param = {
  //     name: "인천",
  //     msg: 20
  //   }
  //   socket.emit("stsync", param);
  // };

  const callrefresh = (sort, time2) => {
    sort == "input"
      ? console.log("new input" + " // " + time2)
      : console.log("state change" + " // " + time2);
    // sort == "input" ? setMflist(+ new Date()) : "";
  };
  ///////////// socket.io //////////////

  const [login, setLogin] = useState(false); // 로그인 여부
  const [onSelectDS, setOnSelectDS] = useState(Math.random()); // 선택된 재난정보
  const [mode, setMode] = useState("light");
  const [dsAddShown, setDsAddShown] = useState(false);
  const [onstatus, setOnstatus] = useState("");
  const [start, setStart] = useState(false); // 상황시작 여부(각종 컴포넌트 표시 여부)
  const [viewSGN, setViewSGN] = useState(""); // 상단상황판 갱신용
  const [viewMFP, setViewMFP] = useState(true); // 동원자원 목록 표출 여부
  const [statchanged, setStatchanged] = useState(""); // 상황판 명칭만 갱신
  const [mapfresh, setMapfresh] = useState(""); // 지도 갱신용
  const [refreshBrd, setRefreshBrd] = useState(""); // 상황판 갱신용
  const [refreshChat, setRefreshChat] = useState(""); // 채팅 갱신용
  const [viewmaptool, setViewmaptool] = useState(false); // 지도툴 표출 여부
  const [fullswitch, setFullswitch] = useState(false); // 전체보기/숨기기 스위치

  const showDsAddModal = () => {
    setDsAddShown(true);
  };
  const hideDsAddModal = () => {
    setDsAddShown(false);
  };

  const onChangeStatus = (e) => {
    setOnstatus(e);
    setStart(true); // 상황시작(각종 컴포넌트 표시)
  };

  const onSelectDSHandler = () => {
    console.log("재난이 선택되었습니다. 상황판갱신이 필요합니다.");
    let nts = +new Date();
    setOnSelectDS(nts);
    setViewSGN(nts);
    setRefreshBrd(nts)
    setRefreshChat(nts)
    setViewmaptool(true);
  };

  const hideMFlist = () => {
    setViewMFP(false); // 동원자원 목록 숨기기
  };
  
  useEffect(() => {
    hideMFlist();
  },[]);

  const viweMFlist = () => {
    setViewMFP(true); // 동원자원 목록 표출
  }

  const mapfreshHandler = () => {
    setMapfresh(Math.random());
  }

  const loginHandler = () => {
    setLogin(true);
  }

  const fullviewChangeHandler = (ox) => { // 전체보기/숨기기 설정 
    if(ox == true){
      sessionStorage.fullswitch = true;
      setFullswitch(true);
    }else{
      sessionStorage.fullswitch = false;
      setFullswitch(false);
    }
  }

  return (
    <sbHeightCtx.Provider value={{ width, height, onSelectDS }}>
      {!login && <Login apiAddr={props.apiAddr} loginox={loginHandler} />}
      {login && (
        <Box bgcolor={"background.default"} color={"text.primary"}>
          {/* // 집결지관리 모달보이기 */}
          {dsAddShown && sessionStorage.modalSort == "RDV" && (
            <RendezvousMgr onClose={hideDsAddModal} apiAddr={props.apiAddr} />
          )}
          {dsAddShown && sessionStorage.modalSort == "MFexcelinput" && (
            <MFlistExcelInput 
              onClose={hideDsAddModal} 
              apiAddr={props.apiAddr} 
              onSelectDS={onSelectDSHandler}
            />
          )}
          <Navbar
            onStatus={onstatus}
            onShowDS={showDsAddModal}
            apiAddr={props.apiAddr}
            hdmf={props.hdmf}
            fullswitch={fullviewChangeHandler}
          />
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Sidebar
              setMode={setMode}
              mode={mode}
              onShowDS={showDsAddModal}
              apiAddr={props.apiAddr} // api주소 프롭 호출
              onStatus={onChangeStatus} // 상황시작 프롭 호출
              onSelectDS={onSelectDSHandler} // 재난선택 프롭 호출
              hdmf={hideMFlist}
              mapfresh={mapfreshHandler}
              fullswitch={fullswitch}
            />
            <Contents
              apiAddr={props.apiAddr}
              start={start}
              viewSGN={viewSGN}
              onShowDS={showDsAddModal}
              hdmf={viewMFP}
              viewmf={viweMFlist}
              statechanged={statchanged}
              mapfresh={mapfresh} // 지도갱신 프롭 호출 to map component
              reqmapfresh={mapfreshHandler} // 지도 갱신 요청 프롭 호출 from chat component
              hidemf={hideMFlist}
              refreshBrd={refreshBrd}
              refreshChat={refreshChat}
              viewmaptool={viewmaptool}
              fullswitch={fullswitch}
              // hidechat={hideChathandler}
              // viewchat={viewChat}
            />
          </Stack>
        </Box>
      )}
    </sbHeightCtx.Provider>
  );
};

export default Home;
