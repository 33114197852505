import { useEffect } from 'react';
import clss from "./Mfmsg.module.css"
let map

const main = {
  position:"relative",
  overflow:"hidden",
  width:"100%",
  height:"100%"
}

function KakaoMap(props) {
  const new_script = (src) => { 
    return new Promise((resolve, reject) => { 
      const script = document.createElement('script'); 
      script.src = src; 
      script.addEventListener('load', () => { 
        resolve(); 
      }); 
      script.addEventListener('error', e => { 
        reject(e); 
      }); 
      document.head.appendChild(script); 
    }); 
  };

  useEffect(() => { 
    //카카오맵 스크립트 읽어오기
    const my_script = new_script('https://dapi.kakao.com/v2/maps/sdk.js?appkey=b7967dc1f2c2b6f788a029bc7b701f09&libraries=services,clusterer,drawing"');
    //스크립트 읽기 완료 후 카카오맵 설정
    my_script.then(() => { 
      console.log('script loaded!!!');  
      const kakao = window['kakao']; 
      kakao.maps.load(() => {
        const lat = sessionStorage.dslat;
        const lon = sessionStorage.dslon;
        const center = new kakao.maps.LatLng(lat, lon);
        const mapContainer = document.getElementById('map');
        const options = { 
          center: center, //좌표설정
          level: 3 
        }; 
        map = new kakao.maps.Map(mapContainer, options); //맵생성
        const mapTypeControl = new kakao.maps.MapTypeControl();
        map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
        map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
        
        //마커설정
        const marker = new kakao.maps.Marker({ 
          position: center
        }); 
        marker.setMap(map);
      }); 
    }); 
    // 맵위에 "재난위치" 라는 글자가  보이도록 설정

    // const maptitle = document.getElementById('maptitle');
    // maptitle.style.position = "absolute";
    // maptitle.style.top = "0px";
    // maptitle.style.left = "0.5%";
    // maptitle.style.right = "0.5%";
    // maptitle.style.overflow = "hidden";
    // maptitle.style.width = "auto";
    // maptitle.style.height = "30px";
    // maptitle.style.zIndex = "20";
    // maptitle.style.fontSize = "22px";
    // maptitle.style.display = "block";
    


  }, []);

  return (
    <div >
      <span id='maptitle' className={clss.maptitle}>재난위치</span>
      <div id="map" className={main}>
      </div>
    </div>
  );
}

export default KakaoMap;
