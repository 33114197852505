import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { blue, green, grey, red } from "@mui/material/colors";
import axios from "axios";
import Snackbar from "../../UI/Snackbar"
import io from "socket.io-client";

const strt = { backgroundColor: grey[500], color:"white", fontSize: "1rem" };
const wait = { backgroundColor: green[500], color:"white", fontSize: "1rem" };
const move = { backgroundColor: blue[500], color:"white", fontSize: "1rem" };
const actv = { backgroundColor: red[500], color:"white", fontSize: "1rem" };
const rtrn = { backgroundColor: grey[500], color:"white", fontSize: "1rem" };

///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////

export default function StateChgPopover(props) {

  ///////////// socket.io //////////////
  // useEffect(() => {
  //   console.log("socket.on useEffect")
  //   socket.on("stsync", (data) => {  // 소켓서버에서 보낸 자료를 받음
  //     const { name, msg, time } = data;
  //     console.log(+new Date()+" : "+name+" : "+msg+" : "+time);
  //     // const { name, msg, time } = data;
  //     // const item = new LiModel(name, msg, time);
  //     // item.makeLi();
  //     alert(+new Date()+" : "+name+" : "+msg+" : "+time );
  //   });
  // }, []);  

  const sendsync = (sort,org,eqp,csgn) => {  // 소캣서버로 갱신변경 자료를 보냄
    // yy-dd-mm hh:mm:ss 값으로 변환
    const nowdt = new Date()
    const yy = nowdt.getFullYear()
    const mm = nowdt.getMonth()+1
    const dd = nowdt.getDate()
    const hh = nowdt.getHours()
    const mi = nowdt.getMinutes()
    const nowdtstr = `${yy}.${mm}.${dd} ${hh}:${mi}`
    const param = {
      sort: sort,
      inputuser: sessionStorage.mfmid,
      dsid: sessionStorage.selectedDSid.trim(),
      org: org,
      eqp: eqp,
      csgn: csgn,
      transtxt: `${nowdtstr} ${org} ${csgn.trim()} ${sessionStorage.selectedFromState} -> ${sessionStorage.selectedToState} 변경`,
    };
    console.log(param);
    socket.emit("stsync", param);
  };
  ///////////// socket.io //////////////

  //console.log(props.onStatus)
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    sessionStorage.selectedFromState = event.currentTarget.textContent
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //////////////  스낵바 호출 //////////////
  const [sbShow, setSbShow] = useState("");
  const callSnakbar = (msg,sort) => {
    // const msg = "삭제되었습니sdfsdfsd다.";
    // const sort = "warning" // info, success, warning, error
    setSbShow(+new Date() + "^" + msg + "^" + sort);
  };
  //////////////  스낵바 호출 //////////////
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const wstat = props.status == 0
  const mstat = props.status == 1
  const astat = props.status == 2
  const rstat = props.status == 3
  const sstat = props.status == 9

  const stateChageHandler = (e) => {
    sessionStorage.selectedToState = e.currentTarget.textContent
    
    let eqp = e.target.name.split("^")[0]
    eqp = eqp.trim().replace("소방","")
    let org = e.target.name.split("^")[1]
    let csgn = e.target.name.split("^")[2]
    org = org.trim()
    org = org.replace(/본부|소방|안전|구조|재난|상|라|청|도/g, "")
    console.log(eqp, org)    
    
    if(window.confirm(e.target.textContent + "으로 수정하시겠습니까? ") == false) return handleClose();
    const dsid = sessionStorage.selectedDSid
    const id = e.target.id
    const status = e.target.textContent == "대기중" ? 0 : e.target.textContent == "이동중" ? 1 : e.target.textContent == "활동중" ? 2 : 3
    console.log(id, status)
    
    async function sendStateChagedData() {
      // 동원력 DB입력
      const wstring = `${props.apiAddr}/get/sendStateChagedData?id=${id}&status=${status}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const result = response.data.result;
      if (result == "success") {
        // alert("등록되었습니다.");
        callSnakbar("수정되었습니다.","success")
        setTimeout(() => {
          sendsync("stateChaged", org, eqp, csgn);
        }, 100);
        
        if(sessionStorage == ""){ // 전체조회일때
          props.callRefreshList();
        }else{ // 기관별조회일때
          // props.oneORGgetlist();
          props.callRefreshList();
        }
        
      } else {
        // alert("등록에 실패하였습니다.");
        callSnakbar("수정에 실패했습니다.","error")
      }
    }
    sendStateChagedData();
    handleClose()
  }

  return (
    <div>
      <Snackbar sbShow={sbShow} />
      {sstat && <Button sx={strt} onClick={handleClick}>집결중</Button>}
      {wstat && <Button sx={wait} onClick={handleClick}>대기중</Button>}
      {mstat && <Button sx={move} onClick={handleClick}>이동중</Button>}
      {astat && <Button sx={actv} onClick={handleClick}>활동중</Button>}
      {rstat && <Button sx={rtrn} onClick={handleClick}>원대복귀</Button>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 2px 0 2px",
          }}
        >
          <Box sx={{ p: 1 }} gap={3}>
            {!sstat && <Button id={props.id} name={props.eqp+"^"+props.org+"^"+props.csgn} sx={strt} style={{margin:"0 3px"}} onClick={stateChageHandler}>집결중</Button>}
            {!wstat && <Button id={props.id} name={props.eqp+"^"+props.org+"^"+props.csgn} sx={wait} style={{margin:"0 3px"}} onClick={stateChageHandler}>대기중</Button>}
            {!mstat && <Button id={props.id} name={props.eqp+"^"+props.org+"^"+props.csgn} sx={move} style={{margin:"0 3px"}} onClick={stateChageHandler}>이동중</Button>}
            {!astat && <Button id={props.id} name={props.eqp+"^"+props.org+"^"+props.csgn} sx={actv} style={{margin:"0 3px"}} onClick={stateChageHandler}>활동중</Button>}
            {!rstat && <Button id={props.id} name={props.eqp+"^"+props.org+"^"+props.csgn} sx={rtrn} style={{margin:"0 3px"}} onClick={stateChageHandler}>원대복귀</Button>}
            
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
