// import * as React from "react";
import react, { useRef} from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import axios from "axios";

const Login = (props) => {
  // console.log(
  //   localStorage.loginid.trim() + " / " + localStorage.loginpw.trim()
  // );
  const idRef = useRef("");
  const pwRef = useRef("");

  const btnClickHandler = () => {
    async function getlogin() {
      console.log("로그인 버튼 클릭");
      const wstring = `${props.apiAddr}/get/getlogin?dsid=${idRef.current.value}&dspw=${pwRef.current.value}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const responseData = response.data;
      console.log(responseData);
      if (responseData.length == 1) {
        sessionStorage.loginOrg = responseData[0].state;
        localStorage.loginid = responseData[0].userid;
        localStorage.loginpw = responseData[0].userpw;
        props.loginox();
      } else {
        alert("아이디 또는 비밀번호를 확인하세요.");
      }
    }
    getlogin();
  };

  const onkeypressHandler = (e) => {
    if (e.key === "Enter") {
      btnClickHandler();
    }
  };

  const remClickHandler = (e) => {
    console.log(e.target.checked);
    localStorage.autoLogin = e.target.checked;
    if (e.target.checked) {
      localStorage.loginid = idRef.current.value;
      localStorage.loginpw = pwRef.current.value;
    } else {
      localStorage.loginid = "";
      localStorage.loginpw = "";
    }
  };

  setTimeout(() => {
    if (localStorage.autoLogin == "true") {

      idRef.current.value = localStorage.loginid.trim();
      pwRef.current.value = localStorage.loginpw.trim();
    }
  }, 100);

  const test = () => {};

  return (
    <Container component="main" maxWidth="xs">
      {/* {console.log(+new Date())} */}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} onClick={test}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          동원자원관리시스템 로그인
        </Typography>
        <TextField
          margin="normal"
          label="ID"
          required
          fullWidth
          name="pw"
          inputRef={idRef}
        />
        <TextField
          label="PW"
          required
          fullWidth
          type="password"
          inputRef={pwRef}
          onKeyPress={onkeypressHandler}
        />
        <FormControlLabel
          control={
            <Checkbox
              value="remember"
              color="primary"
              onClick={remClickHandler}
              defaultChecked={localStorage.autoLogin == "true" ? true : false}
            />
          }
          label="기억하기"
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
          onClick={btnClickHandler}
        >
          로그인
        </Button>
        <Typography variant="body2" color="text.secondary" align="center">
          강원특별자치도 소방본부
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
