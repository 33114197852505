import react, { useRef, useEffect, useState, Fragment } from "react";
import { Box, Paper } from "@mui/material";
import axios from "axios";
import { Close } from "@mui/icons-material";
import clss from "./Mfmsg.module.css";
import Dsmap from "./MfmsgMap";

const MFmessage = (props) => {
  const [inputdata, setInputdata] = useState([]);
  // 세션에 dsinfo 데이터가 있으면 여기서 멈춤 로직추가
  useEffect(() => {
    if(sessionStorage.dsinfo){ // 세션에 데이터가 있으면
      setInputdata(JSON.parse(sessionStorage.dsinfo)); // 세션에 저장된 데이터를 가져옴
    }

    async function getmsg() {
      const wstring = `${props.apiAddr}/get/getmsg?dsid=${sessionStorage.ondsid}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const responseData = response.data;
      if(responseData.length > 0) { // 데이터가 있으면
        console.log(responseData);
        setInputdata(responseData); 
        sessionStorage.dsinfo = JSON.stringify(responseData); // 세션에 저장
        sessionStorage.dslat = responseData[0].dslat; // 세션에 저장
        sessionStorage.dslon = responseData[0].dslon; // 세션에 저장
      }
    }
    getmsg();
  }, []);

  const dsinfocnsts = inputdata.map((item, index) => (
    <span key={index} >
      <span className={clss.dsmain}>(재난명){item.dsnm} / (주소){item.dsaddr} / (발생일시) / {item.dsipdt}</span> {item.dsmessage}
    </span>
  ))

  const viewHandler = () => { // 닫기 버튼 클릭시
    props.viewmsg();
  };
  
  return (
    <Fragment>
      <Box className={clss.background} >
        <div className={clss.maincss} >
          <div className={clss.titlebar}>
            <span className={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}>알림</span>
            <Close onClick={viewHandler} />
          </div>
          <span className={clss.titledetail}>본 앱은 국가동원령에 의해 강원특별자치도 재난에 동원된 시도 소방동원자원 선탑자 또는 지원기관(의소대원, 경찰,군,공무원 등 ) 팀별 대표자가 사용하는 앱입니다. 위의 대상이 아닌분의 사용을 금하며 재난이 종료되면 본앱의 접속이 자동으로 종료됩니다.</span>
          <div className={clss.textcnts}>
            <span className={clss.msgtitle}>알림내용</span>
            <span>{dsinfocnsts}</span>
          </div>
          <div id="map" className={clss.dsmap}>
            <Dsmap />
            {/* <span className={clss.msgtitle}>재난위치</span> */}
          </div>
        </div>
      </Box>
    </Fragment>
  );
};

export default MFmessage;
