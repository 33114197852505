import React, { useState, Fragment, useRef, useEffect } from "react";
import clss from "./MFregiApp.module.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Box, Button } from "@mui/material";
import { blue, green, orange, red, yellow } from "@mui/material/colors";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PopoverBtns from "./StateChgPopover";
import Snackbar from "../../UI/Snackbar"
// import StatSync from "./StatSync"
import io from "socket.io-client";
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRreader from "./MFqrreader"


// 외부 스크립트 불러오기
const script = document.createElement("script");
script.src = "w3.js";
// script.src = "https://www.w3schools.com/lib/w3.js"
script.async = true;
document.body.appendChild(script);


///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////

const ticon = {
  color: "white",
  fontSize: "2rem",
  marginTop: "0.5rem",
  marginRight: "0.5rem",
  marginLeft: "0rem",
  cursor: "pointer",
  backgroundColor: orange[500],
  borderRadius: "50%",
};

const fixedthead ={
  position: "sticky",
  top: 0,
  backgroundColor: green[500],
  color: "white",
  fontWeight: "bold",
  zIndex: 10,
}

const list = clss.list;

const RegiForm = (props) => {
  const location = useLocation();
  const qrs = location.search;
  const params = new URLSearchParams(qrs);
  sessionStorage.mfmid = params.get("id");

  ///////////// socket.io //////////////
  // useEffect(() => {
  //   console.log("socket.on useEffect")
  //   socket.on("stsync", (data) => {  // 소켓서버에서 보낸 자료를 받음
  //     const { name, msg, time } = data;
  //     console.log(+new Date()+" : "+name+" : "+msg+" : "+time);
  //     // const { name, msg, time } = data;
  //     // const item = new LiModel(name, msg, time);
  //     // item.makeLi();
  //     alert(+new Date()+" : "+name+" : "+msg+" : "+time );
  //   });
  // }, []);

  const sendsync = (sort,org,eqp) => {  // 소캣서버로 자료를 보냄
    let orgg = org.replace(/본부|소방|안전|구조|재난|상|라|청|도|입력/g, "");
    let eqpp = eqp.replace("소방","")
    const param = {
      sort: sort,
      inputuser: sessionStorage.mfmid,
      dsid: sessionStorage.selectedDSid.trim(),
      org: orgg,
      eqp: eqpp,
    };
    console.log(param);
    socket.emit("stsync", param);
  };
  ///////////// socket.io //////////////

  const [inputedMflist, setInputedMflist] = useState([]); // 활동중인 재난정보 리스트
  const [inputStatus, setInputStatus] = useState(true); // true : 등록, false : 수정
  const [rfshData, setRfshData] = useState(""); // 갱신요청 변수
  const [view, setView] = useState(false); // 메인화면 표시여부
  const [orglist, setOrglist] = useState([]); // 소속리스트
  const [orgoption, setOrgoption] = useState(); // 소속옵션리스트
  const [viewqr, setViewqr] = useState(false); // QR코드화면 표시여부
  const [viewtable, setViewtable] = useState(true); // 테이블 표시여부

  // const {snumRef, pnumRef, gradeRef, nameRef, orgRef, callsgnRef, sumnumRef} = useRef("");
  const sortRef = useRef("");
  const pnumRef = useRef("");
  const gradeRef = useRef("");
  const nameRef = useRef("");
  const orgRef = useRef("");
  const callsgnRef = useRef("");
  const sumnumRef = useRef("");

  useEffect(() => {
    async function getInputedDsList() {
      const wstring = `${props.apiAddr}/get/mflist?id=${sessionStorage.mfmid}&dsid=${sessionStorage.selectedDSid}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const responseData = response.data;
      console.log(responseData);
      //setInputedMflist(responseData);
      setOrglist(responseData);
      sessionStorage.mflist = JSON.stringify(responseData);
      oneORGgetlist(sessionStorage.nowlistkind);
    }
    getInputedDsList();
  }, [rfshData]);

  const editMfList = (e) => {
    const snum = e.target.parentNode.parentNode.id;
    console.log(snum);
    const cnts = JSON.parse(sessionStorage.mflist);
    for (let i in cnts) {
      if (cnts[i].snum == snum) {
        sessionStorage.editsnum = cnts[i].snum;
        console.log(cnts[i].mfsort);
        sortRef.current.value = cnts[i].mfeqp.trim();
        pnumRef.current.value = cnts[i].mfphnum.trim();
        sessionStorage.edit_pnum = cnts[i].mfphnum.trim();
        gradeRef.current.value = cnts[i].mfgrd.replace(/ /gi, "");
        nameRef.current.value =
          cnts[i].mfnm == null ? "" : cnts[i].mfnm.replace(/ /gi, "");
        orgRef.current.value = cnts[i].mfdorg.trim();
        callsgnRef.current.value = cnts[i].mfcsgn.trim();
        sumnumRef.current.value = cnts[i].mfsumnum.trim();
      }
    }
    console.log(cnts);
    setInputStatus(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    sessionStorage.removeItem("nowlistkind");
  }, []);

  const deleteMfList = (e) => {
    const sort = "delete"
    const snum = e.target.parentNode.parentNode.id;
    const tmp = e.target.parentNode.parentNode.children[3].textContent;
    const org = tmp.split("^")[0].trim();
    const eqp = tmp.split("^")[1].trim();

    const delOX = window.confirm("삭제하시겠습니까?");
    if (delOX) {
      async function deleteMfList() {
        const wstring = `${props.apiAddr}/get/deletemflist?snum=${snum}`;
        console.log(wstring);
        const response = await axios.get(wstring);
        const result = response.data.result;
        if (result == "success") {
          callSnakbar("삭제되었습니다.","success")
          sendsync(sort,org,eqp)
          setRfshData(Math.random());
        } else {
          callSnakbar("삭제에 실패했습니다.","error")
        }
      }
      deleteMfList();
    }
  }

  const callRefreshList = () => {
    // 갱신요청
    setRfshData(Math.random());
  };

  useEffect(() => {
    const makeorglist =() => {
      const orgArr = [];
      for(let i in orglist){
        let short = orglist[i].mfdorg.trim().replace(/본부|소방|안전|구조|재난|상|라|청|도|입력/g, "");
        // mfdorg값을 배열상수 orgArr에다가 중복을 제외하고 f: str, s: short로 넣는데 처음 값은 f:"전체", s:"전체"로 넣는다
        if(orgArr.length == 0) {
          orgArr.push({ f: "", s: "전체" })
        }
        // 배열상수 orgArr에다가 중복을 제외하고 f: str, s: short로 넣는데 처음 값은 f:"전체", s:"전체"로 넣는다
        orgArr.push({ f: orglist[i].mfdorg.trim(), s: short });
        // 중복제거
        orgArr.map((item, index) => {
          for (let i = 0; i < index; i++) {
            if (item.f == orgArr[i].f) {
              orgArr.pop();
            }
          }
        });
      }
      console.log(orgArr)
      
      const oropt = orgArr.map((item) => (
        <option key={item.f} value={item.f}>{item.s}</option>
      ));
      setOrgoption(oropt);
    }
    makeorglist()
  }, [orglist]);

  const oneORGgetlist = async (e) => { // 기관별 동원자원 목록 가져오기
    console.log(e)
    let org
    if(e == undefined) { // 첫번째 조회시 전체조회
      // org = sessionStorage.nowlistkind;
      org = "";
    }else if(e != ""){
      // e 가 텍스트인지 object 인지 구분
      if(typeof e == "object"){
        org = e.target.value;
      }else{
        org = e;
      }
    }else{
      org = "";
    }
    const dsid = sessionStorage.selectedDSid;
    const cnts = JSON.parse(sessionStorage.mflist);
    for (let i in cnts) {
      if(org != ""){
        if(cnts[i].mfdorg.trim() !== org){
          delete cnts[i]
        }
      }
    }
    console.log(cnts);
    setInputedMflist(cnts);
    org == "" ? sessionStorage.nowlistkind = "" : sessionStorage.nowlistkind = org
  }

  //////////////  스낵바 호출 //////////////
  const [sbShow, setSbShow] = useState("");
  const callSnakbar = (msg,sort) => {
    // const msg = "삭제되었습니sdfsdfsd다.";
    // const sort = "warning" // info, success, warning, error
    setSbShow(+new Date() + "^" + msg + "^" + sort);
  };
  //////////////  스낵바 호출 //////////////

  const mflist = inputedMflist.map((item) => (
    //makeorglist(item.mfdorg),
    <tr id={item.snum} key={item.snum} class="list" className={list}>
      <td>{item.seqnum}</td>
      <td>
        {item.mfdorg
          .trim()
          .replace(/본부|소방|안전|구조|재난|상|라|청|도/g, "")
        }
      </td>
      <td>{item.mfcsgn.trim()}</td>
      <td style={{display:"none"}}>{item.mfdorg+"^"+item.mfeqp}</td>
      <td>
        <PopoverBtns
          id={item.snum}
          name={item.mfnm}
          status={item.mfstatus}
          apiAddr={props.apiAddr}
          callRefreshList={callRefreshList}
          oneORGgetlist={oneORGgetlist}
          eqp={item.mfeqp}
          org={item.mfdorg}
          csgn={item.mfcsgn}
        />
      </td>
      <td>
        <button className={clss.listBtn} onClick={editMfList}>
          수정
        </button>
        <button className={clss.listBtn} onClick={deleteMfList}>삭제</button>
      </td>
    </tr>
  ));

  const onSubminHandler = (event) => {
    const farr = [];
    event.preventDefault();
    const etrdSort = sortRef.current.value != "선택";
    farr.push({ name: "자원종류", value: etrdSort, title: sortRef.current });
    const etrdPnum =
      pnumRef.current.value != "" && pnumRef.current.value.length == 13;
    farr.push({ name: "연락처", value: etrdPnum, title: pnumRef.current });
    const etrdCallsgn = callsgnRef.current.value != "";
    farr.push({
      name: "호출명",
      value: etrdCallsgn,
      title: callsgnRef.current,
    });
    const etrdSumnum = sumnumRef.current.value != "";
    farr.push({ name: "총인원", value: etrdSumnum, title: sumnumRef.current });

    for (let i in farr) {
      if (farr[i].value == false) {
        if (farr[i].name == "연락처") {
          alert("연락처를 정확히 입력해주세요.");
          farr[i].title.focus();
        } else {
          alert(`${farr[i].name}(을)를 입력해주세요.`);
          farr[i].title.focus();
        }
        return;
      }
    }

    const verifypnum = async () => {
      // 연락처 중복확인
      console.log("연락처 중복확인");
      const wstring = `${props.apiAddr}/get/verifypnum?id=${pnumRef.current.value}&dsid=${sessionStorage.selectedDSid}`;
      console.log(wstring);
      const response = await fetch(wstring);
      const responseData = await response.json();
      console.log(responseData.length);
      responseData.length === 0
        ? savetoDB()
        : alert("이미 등록된 연락처입니다.");
    };

    const savetoDB = async () => {
      // 데이터 저장
      const svsort = inputStatus ? "등록" : "수정";

      const saveOX = window.confirm(`${svsort}하시겠습니까?`);
      if (saveOX) {
        setInputStatus("i");
        const wstring = `${props.apiAddr}/get/getsnum?dsid=${sessionStorage.selectedDSid}`;
        console.log(wstring);
        const response = await axios.get(wstring);
        const seqnum = (await Number(response.data[0].seqnum)) + 1;
        console.log(seqnum);
        const datakind = inputStatus ? "new" : "edit";
        const regiData = {
          snum: sessionStorage.editsnum, // 수정전 snum
          datakind: datakind, // new : 신규등록, edit : 수정
          seqnum: seqnum,
          dsid: sessionStorage.selectedDSid.trim(),
          rgid: sessionStorage.mfmid,
          sort: sortRef.current.value,
          pnum: pnumRef.current.value,
          grade: gradeRef.current.value,
          name: nameRef.current.value,
          org: orgRef.current.value,
          callsgn: callsgnRef.current.value,
          sumnum: sumnumRef.current.value,
        };
        console.log(regiData);
        sendDataToDb(regiData);
      }
    };

    const initOredit = async () => {
      // 연락처 중복확인
      if (!inputStatus) {
        // 수정입력이면 전화번호 수정여부 확인후 수정이면 연락처 중복확인, 아니면 바로 저장
        const prepnum = sessionStorage.edit_pnum; // 수정전 전화번호
        const nowpnum = pnumRef.current.value; // 수정후 전화번호
        if (prepnum == nowpnum) {
          // 수정전 전화번호와 수정후 전화번호가 같으면 바로 저장
          console.log("전화번호외 다른부분 수정");
          savetoDB();
        } else {
          // 수정전 전화번호와 수정후 전화번호가 다르면 연락처 중복확인
          verifypnum();
        }
      } else {
        // 신규입력이면 연락처 중복확인
        verifypnum();
      }
    };
    initOredit();

    async function sendDataToDb(regiData) {
      // 동원력 DB입력
      const wstring = `${props.apiAddr}/post/mfinput`;
      console.log(wstring);
      const response = await axios.post(wstring,regiData);
      const result = response.data.result;
      console.log(result);
      if (result == "success") {
        inputStatus ? callSnakbar("등록되었습니다.","success") : callSnakbar("수정되었습니다.","success")
        initRef(); // 입력폼 초기화
        setRfshData(Math.random()); // 갱신요청 변수
        sendSms(regiData); // 문자발송
        setTimeout(() => {
          sendsync("input",regiData.org,regiData.sort)
        }, 100);
      } else {
        !inputStatus ? callSnakbar("수정에 실패했습니다.","error") : callSnakbar("등록에 실패했습니다.","error")
      }
    }
  };

  const sendSms = async (regiData) => {
    // 문자발송
    const wstring = `${props.apiAddr}/post/sendsms`;
    console.log(wstring);
    const response = await axios.post(wstring, regiData);
    const result = response.data.result;
    if (result == "success") {
      console.log("발송되었습니다.");
    } else {
      console.log("발송에 실패하였습니다.");
    }
  };

  const initRef = () => {
    //  입력폼 초기화
    sortRef.current.value = "선택";
    pnumRef.current.value = "";
    gradeRef.current.value = "";
    nameRef.current.value = "";
    orgRef.current.value = "선택";
    callsgnRef.current.value = "";
    sumnumRef.current.value = "";
    setInputStatus(true);
  };

  const formatPhoneNumber = (e) => {
    // 전화번호 자동 하이픈
    let inputValue = e.target.value.replace(/\D/g, ""); // 숫자 이외의 문자 제거
    //console.log(inputValue);
    if (inputValue.length > 0) {
      inputValue = inputValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"); // "-" 삽입
    }
    e.target.value = inputValue;
  };

  const arrowbtnHandler = (e) => {
    // 화살표 버튼 클릭시 입력폼 표시여부
    console.log(e.target.id);
    const id = e.target.id;
    id == "up" ? setView(true) : setView(false);
  };

  const callsort = (e) => { 
    // 전달받은 column번호 얻기
    const colnum = e.target.cellIndex+1;
    const coltxt = `td:nth-child(${colnum})`
    console.log(colnum);
    //eslint-disable-next-line
    w3.sortHTML('#regimflist', '.MFregiApp_list__uYYqc', coltxt)
  }

  const qrbtnHandler = () => { // QR코드 버튼 클릭시
    setViewqr(!viewqr)
    if(!viewqr){
      setView(true)
      setViewtable(false)
    }
    else{
      setView(false)
      setViewtable(true)
    }
  }

  return (
    <Fragment>
      <Snackbar sbShow={sbShow} />
      <div className={clss.topmenu}>
        <PublishedWithChangesIcon onClick={props.refreshDSHandler} sx={ticon} />
        <div>
          자원등록앱
          <div style={{ fontSize: "1rem", letterSpacing: -1 }} onClick={sendsync}>
            ({props.dstitle})
          </div>
        </div>
        <div>
          <QrCodeIcon onClick={qrbtnHandler} sx={ticon} />
          {!view && (
            <KeyboardArrowUpIcon id="up" onClick={arrowbtnHandler} sx={ticon} />
          )}
          {view && (
            <KeyboardArrowDownIcon
              id="down"
              onClick={arrowbtnHandler}
              sx={ticon}
            />
          )}
          <RefreshIcon sx={ticon} onClick={initRef} />
        </div>
      </div>

      {viewqr && // qr코드 화면 표시여부
        <div className={clss.qrcontainer}>
          <QRreader apiAddr={props.apiAddr}  />
        </div>
      }


      {!view && (
        <div>
          <div className={clss.row}>
            <div
              className={clss.label1}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              자원종류
            </div>
            <div className={clss.cnts1}>
              <select
                ref={sortRef}
                // className={clss.cnts2}
                style={{ fontSize: "1.3rem", height: "auto" }}
              >
                <option>선택</option>
                <option>소방펌프</option>
                <option>소방물탱크</option>
                <option>소방화학</option>
                <option>소방험지</option>
                <option>소방구조</option>
                <option>소방구급</option>
                <option>소방헬기</option>
                <option>소방지휘</option>
                <option>소방기타차량</option>
                <option>소방의소대</option>
                <option>경찰(인원)</option>
                <option>군(인원)</option>
                <option>공무원(인원)</option>
                {/* <option>기타</option> */}
              </select>
            </div>
          </div>
          <div className={clss.row}>
            <div className={clss.label1}>연락처(대표)</div>
            <input
              ref={pnumRef}
              type="text"
              className={clss.cnts1}
              maxLength={13}
              placeholder="01011119999"
              onChange={formatPhoneNumber}
            />
          </div>
          <div className={clss.row}>
            <div className={clss.label1}>호출명(팀명)</div>
            <input
              type="text"
              className={clss.cnts1}
              placeholder="00펌프1, 1팀, 지원팀"
              ref={callsgnRef}
            />
          </div>
          <div className={clss.row}>
            <div className={clss.label1}>총인원</div>
            <input
              type="number"
              step={1}
              className={clss.cnts1}
              placeholder="3"
              ref={sumnumRef}
            />
          </div>
          <div className={clss.row}>
            <div className={clss.label1}>소속</div>
            <div className={clss.cnts1}>
              <select
                ref={orgRef}
                // className={clss.cnts2}
                style={{ fontSize: "1.3rem", height: "auto" }}
              >
                <option>선택</option>
                <option>중앙구조본부</option>
                <option>서울소방재난본부</option>
                <option>경기도소방본부</option>
                <option>충청북도소방본부</option>
                <option>경상북도소방본부</option>
                <option>대구소방안전본부</option>
                <option>대전소방본부</option>
                <option>인천소방본부</option>
                <option>강원도소방본부</option>
                <option>충청남도소방본부</option>
                <option>전라북도소방본부</option>
                <option>전라남도소방본부</option>
                <option>경상남도소방본부</option>
                <option>세종소방본부</option>
                <option>광주소방본부</option>
                <option>울산소방본부</option>
                <option>부산소방본부</option>
                <option>제주도소방본부</option>
                <option>기타입력</option>
                {/* <option>선택</option>
              <option>중앙구조본부</option>
              <option>서울소방재난본부</option>
              <option>인천소방본부</option>
              <option>부산소방본부</option>
              <option>대구소방안전본부</option>
              <option>대전소방본부</option>
              <option>광주소방본부</option>
              <option>울산소방본부</option>
              <option>세종소방본부</option>
              <option>경기도소방본부</option>
              <option>강원도소방본부</option>
              <option>충청북도소방본부</option>
              <option>충청남도소방본부</option>
              <option>전라북도소방본부</option>
              <option>전라남도소방본부</option>
              <option>경상북도소방본부</option>
              <option>경상남도소방본부</option>
              <option>제주도소방본부</option>
              <option>기타입력</option> */}
              </select>
            </div>
          </div>
          <div className={clss.row}>
            <div className={clss.label2}>직급</div>
            <input
              ref={gradeRef}
              type="text"
              className={clss.cnts2}
              placeholder="소방위, 경위, 주사..."
            />
          </div>
          <div className={clss.row}>
            <div className={clss.label2}>이름</div>
            <input
              id="name"
              type="text"
              className={clss.cnts2}
              placeholder="홍길동"
              ref={nameRef}
              style={{
                msImeMode: "active",
              }}
            />
          </div>
        </div>
      )}

      <div className={clss.row}>
        {!view && (
          <button onClick={onSubminHandler} className={inputStatus ? clss.btn : clss.btne}>
            {inputStatus && <span>등 록</span>}
            {!inputStatus && <span >수 정</span>}
          </button>
        )}
      </div>

      {viewtable && // 테이블 표시여부    
        <table id="regimflist" className={clss.table}>
          <thead style={fixedthead}>
            <tr>
              <th id="id" onClick={callsort}> 
                등록<br></br>번호
              </th>
              <th 
                id="org" 
                ><select style={{width:"80%", fontSize:"1rem"}} onChange={oneORGgetlist}>
                    {orgoption}
                  </select>
                </th>
              <th id="name" onClick={callsort}>장비명</th>
              <th id="eqp" style={{display:"none"}}></th>
              <th id="state" onClick={callsort}>상태관리</th>
              <th id="mgr" onClick={callsort}>관리</th>
            </tr>
          </thead>
          <tbody>{mflist}</tbody>
        </table>
      }
    </Fragment>
  );
};

export default RegiForm;
