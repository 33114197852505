import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, } from "react";
import axios from "axios";
import {
  grey,
  red,
  blue,
  green,
  teal,
} from "@mui/material/colors";
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const bcc = teal[50];
const bcd = teal[50];
let bgc = "";

const statusTitle={
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontWeight: "bold",
  backgroundColor: red[900],
  color: "white",
}
const statusTitleG={
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontWeight: "bold",
  backgroundColor: grey[500],
  color: "white",
}
const tblstyle = {
  fontWeight: "bold",
  backgroundColor: red[400],
  color: "white",
}
const tblstyleG = {
  fontWeight: "bold",
  backgroundColor: grey[400],
  color: "white",
}

const excelbtn ={
  width: 35,
  height: 30,
  border: "solid 1px yellow",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: green[300],
    pointer: "cursor",
  },
  zIndex: 10,
}

let tmp

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const MobilizedPowerPanel = (props) => {
  const sheight = sessionStorage.statusHeight;
  const wheight = window.innerHeight;
  console.log(sheight + " : " + wheight);
  const bheight = wheight - sheight - 35; 

  const [orgmfdata, setorgmfData] = useState([]);

  useEffect(() => {
    const getselectedorgmflist = async () => {
      let org = sessionStorage.selectedOrg;
      let eqp = sessionStorage.selectedEqp;
      let wstring = `${props.apiAddr}/get/selorgmflist?id=${org}&id2=${eqp}&dsid=${sessionStorage.ondsid}`;
      console.log(wstring);
      const result = await axios.get(wstring);
      console.log(result.data);
      tmp = result.data;
      setorgmfData(tmp);
    }
    getselectedorgmflist()
  }, [props.mflist]);

  const tableToExcelExport = () => { //상황판을 엑셀로 저장
    const XLSX = require('xlsx');
    // HTML에서 "tble" ID를 가진 테이블 요소를 가져옵니다.
    const table = document.getElementById('mflistTable');
    // 테이블의 모든 값을 배열로 추출합니다.
    const data = [];
    for (let row of table.rows) {
      const rowData = [];
      for (let cell of row.cells) {
        // 셀 값을 문자열로 추출하고 작은 따옴표로 묶어서 텍스트로 설정합니다.
        rowData.push(`${cell.textContent.trim()}`);
      }
      data.push(rowData);
    }
    // 새 워크북을 생성합니다.
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    // 시트를 워크북에 추가합니다.
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // 파일로 저장합니다.
    XLSX.writeFile(wb, '동원자원현황.xlsx');
  }

  const orgmflist = orgmfdata.map((item) => (
    <TableRow
      key={item.org}
      sx={{ }}
    >
      <TableCell>{item.mfdorg}</TableCell>
      <TableCell sx={bcd}> {item.mfeqp.indexOf('소방') != -1 ? item.mfeqp.replace("소방", "") : item.org} </TableCell>
      <TableCell sx={bcd}>{item.mfcsgn}</TableCell>
      <TableCell align="center"
        {...(item.mfstatus == 0 ? { bgcolor: green[300] } : item.mfstatus == 1 ? { bgcolor: blue[300] } : item.mfstatus == 2 ? { bgcolor: red[300] } : { bgcolor: grey[500] })
        }
      >
        {
          item.mfstatus == 0 ? "대기" : item.mfstatus == 1 ? "이동" : item.mfstatus == 2 ? "활동" : item.mfstatus == 3 ? "복귀" : item.mfstatus == 9 ? "집결중" : "기타"
        }
      </TableCell>
      <TableCell sx={bcd} align="center">{item.mfnm}</TableCell>
      <TableCell align="center">{item.mfphnum}</TableCell>
      <TableCell align="center">{item.mfsumnum}</TableCell>
    </TableRow>
  ));

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1,
        top: sheight*1,
        marginLeft: 0.8,
        backgroundColor: "white",
        border: "solid 1px black",
      }}
    >
      <Box sx={sessionStorage.oningox == 1 ? statusTitle : statusTitleG}>
        <div></div>
        <Typography variant="h6" textAlign="center" sx={{fontWeight:"bold"}}>
          {sessionStorage.selectedOrg == null && sessionStorage.selectedEqp == null ? "동원자원현황" : ("동원자원현황("+sessionStorage.selectedOrg+sessionStorage.selectedEqp+")")}
        </Typography>
        <LightTooltip title="엑셀 저장" placement="top" arrow style={{marginTop:"3px"}}>
          <DownloadIcon style={excelbtn} onClick={tableToExcelExport} />
        </LightTooltip>
      </Box>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 250, maxHeight: bheight, display: { xs: "none", sm: "block" } }}
          size="small"
          aria-label="a dense table"
          id="mflistTable"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>소속</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>종류</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>장비명</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>현상태</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>대표자</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>전화번호</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>탑승인원</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionStorage.selectedOrg == "" && sessionStorage.selectedEqp == "" ? ""  : orgmflist}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MobilizedPowerPanel;
