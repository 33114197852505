import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { lightBlue, grey, teal,purple } from '@mui/material/colors';
const bcc = teal[100];

function createData(snum, div, eqname, spot, status, sttime, edtime, rbtime) {
  return { snum, div, eqname, spot, status, sttime, edtime, rbtime };
}

const DispatchList = () => {
  const rows = [
    createData(1,"중구단",'중구펌프1','골프장방면 진화', '귀소중', '2023.04.05 12:34:25', '2023.04.05 12:45:25', '2023.04.05 14:20:25'),
    createData(2,"서울",'성동물탱크1','경포대방면 진화', '작전중', '2023.04.05 12:50:14', '2023.04.05 13:04:25', ''),
    createData(6,"경기",'의정부펌1','운동장방면 방화선 구축', '작전중', '2023.04.05 12:34:25', '2023.04.05 12:45:25', ''),
    createData(9,"중구단",'중구물탱크2','경포대 주면 민가 방어', '귀소중', '2023.04.05 12:34:25', '2023.04.05 12:45:25', '2023.04.05 14:20:25'),
    createData(11,"서울",'종로펌프2','00가옥 민가 방어', '출동중', '2023.04.05 13:29:25', '', ''),
    createData(3,"경북",'상주물탱크1','복지센터 주변 화선 방어', '출동중', '2023.04.05 13:34:25', '', ''),
  ];
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1,
        top: 620,
        marginLeft: 0.8,
        // height: 200,
        // width: 200,
        backgroundColor: "white",
        border : "solid 1px black",
      }}
    >
      <Typography variant="h6" >
        현장활동 현황
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table" bgcolor={bcc}>
          <TableHead>
            <TableRow style={{fontWeight: 'bold'}}>
              <TableCell align="left">연번</TableCell>
              <TableCell align="left">소속</TableCell>
              <TableCell align="left">장비명</TableCell>
              <TableCell align="left">출동지</TableCell>
              <TableCell align="left">진행단계</TableCell>
              <TableCell align="left">출발시간</TableCell>
              <TableCell align="left">도착시간</TableCell>
              <TableCell align="left">출발시간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={Math.random()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell component="th" scope="row">
                  {row.name}
                </TableCell> */}
                <TableCell align="left">{row.snum}</TableCell>
                <TableCell align="left">{row.div}</TableCell>
                <TableCell align="left">{row.eqname}</TableCell>
                <TableCell align="left">{row.spot}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.sttime}</TableCell>
                <TableCell align="left">{row.edtime}</TableCell>
                <TableCell align="center">{row.rbtime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DispatchList;
