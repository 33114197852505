import React, { useEffect, useState } from "react";
import Card from "../../UI/Card";
import DisasterListItem from "./DisasterListItem";

const DiasterLists = (props) => {
  const [list, setList] = useState([]);
  console.log("refresh : " + props.refresh)
  useEffect(() => {
    const fetchList = async () => {
      const wstring = `${props.apiAddr}/get/dslist`;
      // const wstring = "https://map.119survey.org/nodetodb/get/dslist";
      //const wstring = "http://localhost:8080/get/dslist"
      console.log(wstring);
      const response = await fetch(wstring);
      console.log(response);
      const responseData = await response.json();
      console.log(responseData);
      const loadedList = [];

      for (const k in responseData) {
        //console.log(responseData[k].dsnm);
        loadedList.push({
          id: responseData[k].snum,
          name: responseData[k].dsnm,
          ipdt: responseData[k].dsipdt,
          ox: responseData[k].dsox,
          addr: responseData[k].dsaddr,
          dsid: responseData[k].dsnum,
          lat: responseData[k].dslat,
          lon: responseData[k].dslon,
        });
      }
      console.log(loadedList);
      setList(loadedList);
    };
    fetchList();
  }, [props.refresh]);

  const nowList = list.map((l) => (
    <DisasterListItem
      key={l.id}
      id={l.id}
      name={l.name}
      ipdt={l.ipdt}
      ox={l.ox}
      addr={l.addr}
      dsid={l.dsid}
      lat={l.lat}
      lon={l.lon}
      onDataChanged={props.onDataChanged}
      apiAddr={props.apiAddr}
      onStatus={props.onStatus}
      onSelectDS={props.onSelectDS}
      hdmf={props.hdmf}
      mapfresh={props.mapfresh}
    />
  ));

  return <Card style={{ height: "580px", backgroundColor:"yellow" }}>{nowList}</Card>;
};

export default DiasterLists;
