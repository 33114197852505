import { Fragment } from 'react';
import ReactDOM from 'react-dom';

import classes from './Modal.module.css';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose}/>;
};

let mdStyle;

const mfinput = { // 동원자원입력 모달 스타일
  left: "calc(5%)",
  width: "90%",
}

const rdvs = { // 자원집결지관리 모달 스타일
  left: "calc(15%)",
  width: "70%",
}

const mfmsg = { // 동원자원앱 알림메시지 모달
  left: "calc(15%)",
  width: "70%",
}

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal} style={mdStyle}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const Modal = (props) => {
  // 모달 종류를 읽어서 스타일(넓이)을 변경한다.
  mdStyle = props.modalSort === "mfinput" ? mfinput : rdvs;
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>,portalElement)}
    </Fragment>
  );
};

export default Modal;
