import { useEffect, useRef } from 'react';

// 첫번째 렌더링을 제외한 렌더링에서만 실행되는 useEffect  
export const useOneEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};