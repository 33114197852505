import react, { useRef, useEffect, useState } from "react";
import Modal from "../../UI/Modal";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Snackbar from "../../UI/Snackbar";
import SaveIcon from '@mui/icons-material/Save';
import readXlsxFile from 'read-excel-file'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import {
  grey,
  blue,
  green,
  yellow,
} from "@mui/material/colors";

const statusTitle = {
  fontWeight: "bold",
  backgroundColor: green[900],
  color: "white",
};

const thead = {
  color: "white",
  fontWeight: "bold",
  backgroundColor: green[500],
  textAlign: "center",
};
const trow = {
  fontWeight: "bold",
  textAlign: "center",
};
const listtrow = {
  "&:hover": {
    backgroundColor: green[100],
  },
};
const fixedthead = {
  position: "sticky",
  top: 0,
  backgroundColor: green[500],
  color: "white",
  fontWeight: "bold",
  zIndex: 10,
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const tinfo = {
  width: "70%",
  margin: "5px",
  padding: "5px",
  fontSize: "1rem",
  resize: "none",
  border: "1px solid #ccc",
  borderRadius: "5px",
};

const MFlistexcelinput = (props) => {
  const [refresh, setRefresh] = useState("");

  const cnts = JSON.parse(sessionStorage.mfexcellist);
  //console.log(cnts);
  const tblist = cnts.map((item,idx) => (
    // 첫번째 row는 건너뜀
    idx == 0 ? null :    
    <TableRow key={item.id} sx={listtrow}>
      <TableCell sx={trow}>{item[0]}</TableCell>
      <TableCell>{item[1]}</TableCell>
      <TableCell>{item[2]}</TableCell>
      <TableCell>{item[3]}</TableCell>
      <TableCell>{item[4]}</TableCell>
      <TableCell>{item[5]}</TableCell>
      <TableCell>{item[6]}</TableCell>
      <TableCell>{item[7]}</TableCell>
      <TableCell>{item[8]}</TableCell>
      <TableCell>{item[9]}</TableCell>
      <TableCell>{item[10]}</TableCell>
      <TableCell>{item[11]}</TableCell>
      <TableCell>{item[12]}</TableCell>
      <TableCell>입력전</TableCell>
      {/* <TableCell sx={trow}>
        <Button id={item.id} sx={mgrbtn} onClick={editHandler}>
          수정
        </Button>
        <Button sx={mgrbtn} onClick={deleteHandler}>
          삭제
        </Button>
      </TableCell> */}
    </TableRow>
  ));
  
  const sendSms = async (regiData) => {
    // 문자발송
    const wstring = `${props.apiAddr}/post/sendsms`;
    console.log(wstring);
    const response = await axios.post(wstring, regiData);
    const result = response.data.result;
    if (result == "success") {
      console.log("발송되었습니다.");
    } else {
      console.log("발송에 실패하였습니다.");
    }
  };

  const excelUploadClickHandler = () => {
    alert("동원자원이 입력된 엑셀파일을 선택해주세요.")
    // 엑셀파일 업로드
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.click();
    input.onchange = (event) => {
      readXlsxFile(input.files[0]).then(function(data) {
        sessionStorage.mfexcellist = JSON.stringify(data);
        setRefresh(+new Date());
      }, function (error) {
        console.error(error)
      })
    }
  }

  const sendmessage = (msg) => { // 문자수신자에게 전달사항 입력
    const saveDbmsg = async () => {
      const dsid = sessionStorage.ondsid;
      const msgData = {
        dsid: dsid,
        msg: msg,
      }
      const wstring = `${props.apiAddr}/post/sendmessage`;
      console.log(wstring);
      const response = await axios.post(wstring,msgData);
      console.log(response)
      if(response.data.result == "success"){
        console.log("전달사항이 전송되었습니다.")
      }
    }
    saveDbmsg()
  }
  
  const sendDataHandler = async (e) => {
    // 진행여부 확인
    if (window.confirm("입력하신 내용을 전송하시겠습니까?") == false) return;

    const tbl = e.target.parentNode.parentNode.parentNode.parentNode;
    const trs = tbl.querySelectorAll("tr");
    const tds = trs[1].querySelectorAll("td");
    // console.log(tds[0].textContent);
    const orglist = "중앙구조본부,서울소방재난본부,인천소방본부,부산소방본부,대구소방안전본부,대전소방본부,광주소방본부,울산소방본부,세종소방본부,경기도소방본부,강원도소방본부,충청북도소방본부,충청남도소방본부,전라북도소방본부,전라남도소방본부,경상북도소방본부,경상남도소방본부,제주도소방본부"
    const epqlist = "펌프,물탱크,화학,험지,구조,구급,헬기,지휘,기타차량"

    //한개의 열씩 데이터를 추출하여 regiData에 저장
    for(let i=1; i<trs.length; i++){
      let inputOX;
      // 기입력여부 확인
      const phnum = trs[i].querySelectorAll("td")[9].textContent
      const str = `${props.apiAddr}/get/verifypnum?id=${phnum}&dsid=${sessionStorage.ondsid}`;
      //console.log(str);
      const rspns = await axios.get(str);
      // console.log(rspns.data.length)
      if(rspns.data.length === 0) {
        // console.log("기입력된 전화번호가 아닙니다.");
        inputOX = true;
      }else{
        // console.log("같은전화번호로 등록된 자원이 있습니다");
        inputOX = false;
        trs[i].querySelectorAll("td")[13].textContent = "기입력자료 있음";
        trs[i].querySelectorAll("td")[13].style.backgroundColor = 'yellow';
      }

      if(i == 1){
        sendmessage(document.getElementById('msg').value) // 문자수신자의 전달사항 입력
      }

      // 입력데이터 형식검증
      const iptdOrg = orglist.indexOf(trs[i].querySelectorAll("td")[1].textContent) == -1 ? false : true
      const iptdEqp = epqlist.indexOf(trs[i].querySelectorAll("td")[4].textContent) == -1 ? false : true
      const iptdCallsgn = trs[i].querySelectorAll("td")[5].textContent == "" ? false : true
      const iptdPnum = trs[i].querySelectorAll("td")[9].textContent.length == 13 ? true : false

      const verifydata = iptdOrg && iptdEqp && iptdCallsgn && iptdPnum
      // console.log(verifydata)
      if(!verifydata){
        alert(i+"열 입력데이터를 확인하세요.")
        props.onSelectDS(); // 상황판 갱신 호출
        return
      }

      const saveDB = async (regiData) => {
        async function sendDB() {
          const wstring = `${props.apiAddr}/post/mfinputall`; // 수정
          console.log(wstring);
          const response = await axios.post(wstring,regiData);
          if (response.data.result == "success") {
            // callSnakbar("수정되었습니다.","success")
            console.log("수정되었습니다.") 
            // setGetlist(+new Date())
            sendSms(regiData); // 문자발송
            trs[i].querySelectorAll("td")[13].textContent = "입력완료";
            trs[i].querySelectorAll("td")[13].style.backgroundColor = 'lightgreen';
          } else {
            // callSnakbar("수정에 실패하였습니다.", "error");
            console.log("수정에 실패하였습니다.")
            trs[i].querySelectorAll("td")[13].textContent = "오류발생";
            trs[i].querySelectorAll("td")[13].style.backgroundColor = 'red';
          }
        }
        sendDB();
      };

      //등록번호 추출
      const wstring = `${props.apiAddr}/get/getsnum?dsid=${sessionStorage.ondsid}`;
      // console.log(wstring);
      const response = await axios.get(wstring);
      const seqnum = (await Number(response.data[0].seqnum)) + 1;

      const tdsort = trs[i].querySelectorAll("td")[4].textContent
      const esort = epqlist.indexOf(trs[i].querySelectorAll("td")[4].textContent) != -1 ? "소방"+tdsort : tdsort
      // 입력데이터 생성
      const regiData = {
        datakind: "new", // new : 신규등록, edit : 수정
        seqnum: seqnum,
        dsid: sessionStorage.ondsid.trim(),
        rgid:"",
        sort: esort,
        pnum: trs[i].querySelectorAll("td")[9].textContent,
        org: trs[i].querySelectorAll("td")[1].textContent,
        name: trs[i].querySelectorAll("td")[8].textContent,
        callsgn: trs[i].querySelectorAll("td")[5].textContent,
        sumnum: trs[i].querySelectorAll("td")[7].textContent,
        rdvs: trs[i].querySelectorAll("td")[10].textContent,
        cntct: trs[i].querySelectorAll("td")[11].textContent,
      };
      console.log(regiData);
      inputOX == true ? saveDB(regiData) : console.log("기입력자료 있음");
      //saveDB(regiData) // 디비입력 호출

      // i가 마지막이면 실행
      if(i == trs.length-1) {
        alert("입력이 완료되었습니다.")
        props.onSelectDS(); // 상황판 갱신 호출
      }
    }
  };

  //////////////  스낵바 호출 //////////////
  const [sbShow, setSbShow] = useState("");
  const callSnakbar = (msg, sort) => {
    // const msg = "삭제되었습니sdfsdfsd다.";
    // const sort = "warning" // info, success, warning, error
    setSbShow(+new Date() + "^" + msg + "^" + sort);
  };
  //////////////  스낵바 호출 //////////////

  return (
    <Modal onClose={props.onClose} modalSort={"mfinput"} >
      {/* <div id="del" onClick={callSnakbar("삭제되었습니다.","success")}>snackbar call</div> */}
      <Snackbar sbShow={sbShow} />
      <Box sx={{display:"flex", justifyContent:"space-evenly", color:"white"}} bgcolor={green[900]} >
        <LightTooltip title="새로 불러오기" placement="top" arrow style={{marginTop:"3px"}}>
          <AddBoxIcon sx={{color:"yellow"}} onClick={excelUploadClickHandler} />
        </LightTooltip>
        <Typography variant="h6" textAlign="center" sx={statusTitle}>
          동원자원 일괄입력
        </Typography>
        <LightTooltip title="저장하기" placement="top" arrow style={{marginTop:"3px"}}>
          <SaveIcon sx={{color:"yellow"}} onClick={sendDataHandler} />
        </LightTooltip>
      </Box>
      <TableContainer component={Paper} sx={{ height: "600px" }}>
        <Table size="small" aria-label="simple table">
          <TableHead sx={fixedthead}>
            <TableRow bgcolor={green[500]}>
              <TableCell sx={thead}>연번</TableCell>
              <TableCell sx={thead}>시도</TableCell>
              <TableCell sx={thead}>소방서</TableCell>
              <TableCell sx={thead}>안전센터</TableCell>
              <TableCell sx={thead}>차량종류</TableCell>
              <TableCell sx={thead}>차량명칭</TableCell>
              <TableCell sx={thead}>차량번호</TableCell>
              <TableCell sx={thead}>탑승총인원</TableCell>
              <TableCell sx={thead}>선탑자명</TableCell>
              <TableCell sx={thead}>선탑전화번호</TableCell>
              <TableCell sx={thead}>자원집결지</TableCell>
              <TableCell sx={thead}>연락관</TableCell>
              <TableCell sx={thead}>비고</TableCell>
              <TableCell sx={thead}>입력결과</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tblist}</TableBody>
        </Table>
      </TableContainer>
      <Box sx={{display:"flex", justifyContent:"space-between", color:"white"}} bgcolor={green[900]} >
        <Typography variant="h6" textAlign="center" style={{width:"30%"}} >
            전달사항 입력<br></br>(입력한 내용은 문자수신자에게 전달됩니다.)
        </Typography>
        <textarea 
          id="msg" 
          name="msg" 
          rows="3" 
          cols="100" 
          style={tinfo} 
          placeholder="재난의 개요, 주의사항, 필수정보 등을 요약해서 입력합니다."
        ></textarea>
      </Box>
    </Modal>
  );
};

export default MFlistexcelinput;
