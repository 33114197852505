
import classes from './DisasterAddButton.module.css'
import DisasterAddForm from './DisasterAddForm'
import React, { useContext } from 'react'
import { sbHeightCtx } from "../../store/sidebarWiHeContext";

const DisasterAddButton = (props) => {
  const {width} = useContext(sbHeightCtx);
  let sbwith = width*1.1
  return (
  <div className={classes.button} style={{width:sbwith}} >
    <DisasterAddForm onDataChanged = {props.onDataChanged} apiAddr={props.apiAddr}/>
  </div>
  )
}

export default DisasterAddButton