import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DisasterAddButton from "../components/DisasterList/DisasterAddButton";
import DiasterLists from "../components/DisasterList/DiasterLists";
import Switch from '@mui/material/Switch';
import { grey } from "@mui/material/colors";
import { useOneEffect } from "../components/useOneEffect";

const switchstyle = {
  backgroundColor: grey[200],
  marginRight: 1,
  marginLeft: 1,
  borderRadius: 3,
}

const dsmgr = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  textAlign: "center",
  backgroundColor: grey[200],
  borderRadius: 3,
  marginBottom: 1,
  marginTop: -0.5,
  padding: 0.5,
  fontWeight: "bold",
}

export const Sidebar = (props) => {
  console.log(props.fullswitch)

  const [dataChanged, setDataChanged] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [checked, setChecked] = useState(true); // 상황판 보기/숨기기
  const [width, setWidth] = useState(2); // 상황판 보기/숨기기

  const handleDataChange = () => {
    console.log("자식에게 데이터가 입력되다고 연락왔어요");
    setDataChanged(true);
    handleRefresh(); // 리스트갱신 호출
  };

  const handleRefresh = () => {
    // 리스트갱신용 함수
    console.log("리스트 갱신 요청이 왔어요");
    setRefresh(refresh + 1);
    console.log("updated refresh: ", refresh);
  };

  const handleChange = (e) => {
    // 상황판 보기/숨기기
    const ox = e.target.checked;
    console.log(ox);
    setChecked(ox);
    sessionStorage.SidebarPanel = ox;
    ox == true ? setWidth(2) : setWidth(0.3);
  }

  useOneEffect(() => { // 전체보기/숨기기 프롭처리
    console.log("전체보기/숨기기 설정이 변경되었습니다." + props.fullswitch);
    // 상황판 보기/숨기
    const ox = props.fullswitch;
    console.log(ox);
    setChecked(ox);
    sessionStorage.SidebarPanel = ox;
    ox == true ? setWidth(2) : setWidth(0.3);
  }, [props.fullswitch]);

  return (
    <Box
      id="sidebar"
      bgcolor="yellowgreen"
      flex={width}
      p={1}
      // m={1}
      // sx={{ display: { xs: "none", sm: "block" }, width: 400 }}
    >
      <Box position={"fixed"}>
        <Typography variant="h6" sx={dsmgr}>
          <div></div>
          {/* <button onClick={props.onShowDS}>모드변경</button> */}
          {checked && <div>재난관리</div> }
          <div>
            <Switch checked={checked} onChange={handleChange} sx={switchstyle} size="small"/>
          </div>
        </Typography>
        {checked && 
        <DisasterAddButton
          onDataChanged={handleDataChange}
          apiAddr={props.apiAddr}
        />
        }
        {checked && 
        <DiasterLists
          refresh={refresh}
          onDataChanged={handleDataChange}
          apiAddr={props.apiAddr}
          onStatus={props.onStatus}
          onSelectDS={props.onSelectDS}
          hdmf={props.hdmf}
          mapfresh={props.mapfresh}
        />
        }
      </Box>
    </Box>
  );
};
