import React, { Fragment, useState, useEffect, useRef } from "react";
import clss from "./MFuserApp.module.css";
import Chatting from "../Contents/ChattingMobile";
import { useLocation } from "react-router-dom";
import Mfmessage from "./Mfmsg";
import MfQRcode from "./MfQrcode";
import axios from "axios";
import io from "socket.io-client";
import Switch from '@mui/material/Switch';
import { grey, lightGreen, orange } from "@mui/material/colors";
import { initKakao } from 'kakao-js-sdk';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import FireTruckIcon from '@mui/icons-material/FireTruck';
import CoffeeIcon from '@mui/icons-material/Coffee';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import HelpIcon from '@mui/icons-material/Help';
import { Construction } from "@mui/icons-material";
import QrCodeIcon from '@mui/icons-material/QrCode';
// import FileUpload from "../Contents/Fileuplad";


// initKakao('b7967dc1f2c2b6f788a029bc7b701f09')

///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////

let dsingox = false; // 재난종료 여부

const icon = {
  fontSize: "1.2rem",
}

let cheight = 400 // 채팅장 높이 변수
let num = 0;
sessionStorage.removeItem("latesnum");

const MFuserApp = (props) => {
  console.log(props)
  const gry = { backgroundColor: "grey" };
  const location = useLocation();
  const qrs = location.search;
  const params = new URLSearchParams(qrs);
  sessionStorage.ondsid = params.get("id");
  sessionStorage.seqnum = params.get("sq");
  const [selected, setSelected] = useState(""); // 대기중, 이동중, 활동중
  const [getlist, setGetlist] = useState([]); //
  const [viewinfo, setViewinfo] = useState(true); // 동원자원 목록 표출 여부
  const [viewmsg, setViewmsg] = useState(false); // 알림창 표출 여부
  const [viewQR, setViewQR] = useState(false); // qr창 표출 여부
  const[code, setCode] = useState("") // qr코드 내용

  // const [locsendafter, setLocsendafter] = useState(""); // 위치정보 전송후 리렌더링

  const titleRef = useRef();
  const infoRef = useRef();
  const stateRef = useRef();
  const nameRef = useRef();
  const footRef = useRef();

  const cntrRef = useRef();
  const waitRef = useRef();
  const moveRef = useRef();
  const actvRef = useRef();
  
  const switchstyle = {
    backgroundColor: grey[200],
    // marginRight: 1,
    // marginLeft: 1,
    borderRadius: 3,
    //오른쪽 정렬
    //float: "right",
  }

  useEffect(() => {
    const wstring = `${props.apiAddr}/get/getdsox?dsid=${sessionStorage.ondsid.trim()}`;
    console.log(wstring);
    const getox = async () => {
      const response = await axios.get(wstring);
      const responseData = response.data;
      console.log(responseData.length);

      const viewNothing = () =>{
        // 빈화면을 렌더링 함
        document.body.style.backgroundColor = "white";
        document.body.style.display = "flex";
        document.body.style.alignContent = "center";
        document.body.style.flexDirection = "column";
        document.body.style.justifyContent = "center";
        document.body.style.fontSize = "2rem";
        document.body.style.fontWeight = "bold";
        document.body.style.color = "red";
        document.body.style.textAlign = "center";
        document.body.innerHTML = "재난이 종료되었습니다. 앱을 종료합니다."
        dsingox = true;
        return
      } 
      
      if(responseData.length == 0){
        viewNothing()
      }else if(responseData[0].dsox == "0" ){
        viewNothing()
      }
    }
    setTimeout(() => {
      getox()
    }, 300);
  }, []);
  
  ///////////// socket.io /////////////////////////////////////////////////////////////////////
  useEffect(() => { // dispatch for socket.io(request for location)
    socket.on("getlocaiton", (data) => { 
      const { dsid } = data;  
      if(dsid == sessionStorage.ondsid.trim()){
        setSelected(+new Date());
        setTimeout(() => { // GPS갱신 요청후 1초후에 데이터 전송 
          sendLOCtoDB()
        }, 1000);
      }
    });
  }, []);
  
  const sendLOCtoDB = () => { // GPS갱신 요청후 1초후에 데이터 전송
    const senddb = async () => {
      const wstring = `${props.apiAddr}/post/sendloc`;
      const locData = {
        dsid: sessionStorage.ondsid,
        seqnum: sessionStorage.seqnum,
        glat: sessionStorage.glat,
        glon: sessionStorage.glon,
        gacc: sessionStorage.gacc,
        gtme: sessionStorage.gtme,
      };
      console.log(wstring);
      console.log(locData);
      const response = await axios.post(wstring, locData);
      const result = response.data.result;
      if (result == "success") {
        console.log("전송완료");
        // 현상태 버튼생상적용
        chbg()

      } else {
        console.log("전송실패");
      }
    }
    senddb()
  }

  const chbg = () => { // 현상태 버튼생상적용
    const selstate = sessionStorage.selectedFromState
    if(selstate == "대기중"){
      waitRef.current.style.backgroundColor = "green"
    }else if(selstate == "이동중"){
      moveRef.current.style.backgroundColor = "blue"
    }else if(selstate == "활동중"){
      actvRef.current.style.backgroundColor = "red"
    }else{
      cntrRef.current.style.backgroundColor = "black"
    }
  }

  const sendsync = (sort) => {  // 소캣서버로 자료를 보냄
    let orgg = sessionStorage.chatname.split(" ")[0] ;
    let eqpp = sessionStorage.eqp.replace("소방","")
    let csgn = sessionStorage.chatname.split(" ")[1] ;

    const nowdt = new Date()
    // 모든 값을 두자리로 저장
    const yy = nowdt.getFullYear().toString().substr(2,2)
    //월값을 두자리로 저장
    const mm = (nowdt.getMonth()+1).toString().length == 1 ? "0" + (nowdt.getMonth()+1).toString() : (nowdt.getMonth()+1).toString()
    // 일값을 두자리로 저장
    const dd = nowdt.getDate().toString().length == 1 ? "0" + nowdt.getDate().toString() : nowdt.getDate().toString()    
    // 시간값을 두자리로 저장
    const hh = nowdt.getHours().toString().length == 1 ? "0" + nowdt.getHours().toString() : nowdt.getHours().toString()
    //분값을 두자리로 저장
    const mi = nowdt.getMinutes().toString().length == 1 ? "0" + nowdt.getMinutes().toString() : nowdt.getMinutes().toString()   
    const nowdtstr = `${yy}.${mm}.${dd} ${hh}:${mi}`

    const param = {
      sort: sort,
      inputuser: sessionStorage.ondsid,
      dsid: sessionStorage.ondsid.trim(),
      org: orgg,
      eqp: eqpp,
      csgn: csgn,
      transtxt: `${nowdtstr} ${orgg} ${csgn} ${sessionStorage.selectedFromState} -> ${sessionStorage.selectedToState} 변경`,
    };
    console.log(param);
    socket.emit("stsync", param);
    sessionStorage.selectedFromState = sessionStorage.selectedToState;
  };

  const reqPhotorefresh = () => { // 사진갱신 요청 소켓서버로 전달
    const param = {
      sort: "syncphoto",
      dsid: sessionStorage.ondsid.trim(),
    };
    console.log(param);
    socket.emit("syncphoto", param);
  }

  ///////////// socket.io //////////////////////////////////////////////////////////////////////

  function statBtnClickHandler(e){
    const nowstatxt = e.target.parentNode.id;
    console.log(nowstatxt);
    sessionStorage.selectedToState = nowstatxt;
    let nowstatcode;
    if(nowstatxt == "대기중") {
      (nowstatcode = 0)
    } else if(nowstatxt == "이동중") {
      (nowstatcode = 1)
    } else if(nowstatxt == "활동중"){
      (nowstatcode = 2)
    } else{
      (nowstatcode = 9)
    }

    setSelected(nowstatxt);
    async function changestatus() {
      const wstring = `${props.apiAddr}/post/changestatus`;
      const statData = {
        dsid: sessionStorage.ondsid,
        seqnum: sessionStorage.seqnum,
        nowstat: nowstatcode,
        glat: sessionStorage.glat,
        glon: sessionStorage.glon,
        gacc: sessionStorage.gacc,
        gtme: sessionStorage.gtme,
      };
      console.log(wstring);
      const response = await axios.post(wstring, statData);
      const result = response.data.result;
      if (result == "success") {
        let statement
        const nowstat = sessionStorage.selectedToState;
        if(nowstat == "대기중"){
          statement = "대기중으로 변동되었습니다. \n자원집결지에서 대기하세요."
        }else if(nowstat == "이동중"){
          statement = "이동중으로 변동되었습니다. \n현장으로 이동중임을 나타냅니다. \n안전운전바랍니다."
        }else if(nowstat == "활동중"){
          statement = "활동중으로 변동되었습니다. \n재난현장에서 활동중임을 나타냅니다. \n안전에 유의해서 활동바랍니다.."
        }
        alert(statement);
        sendsync("stateChaged");
      } else {
        alert("변경에 실패하였습니다.");
      }
    }
    changestatus();
  };

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    function success(pos) {
      const crd = pos.coords;
      sessionStorage.glat = crd.latitude;
      sessionStorage.glon = crd.longitude;
      sessionStorage.gacc = crd.accuracy;
      sessionStorage.gtme = new Date().toISOString().slice(0, 19).replace(/-/g, "").replace(/:/g, "").replace("T", "");
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, [selected]);

  useEffect(() => {
    async function getUserData() {
      const wstring = `${props.apiAddr}/get/mfuserdata?dsid=${sessionStorage.ondsid}&seqnum=${sessionStorage.seqnum}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const responseData = response.data;
      console.log(responseData);
      setGetlist(responseData);
      sessionStorage.eqp = responseData[0].mfeqp.trim();
      const org = responseData[0].mfdorg.trim().replace(/본부|소방|안전|구조|재난|상|라|청|도|입력/g, "");
      const sgn = responseData[0].mfcsgn.trim();
      sessionStorage.chatname = org + " " + sgn;
      const nowstat = responseData[0].mfstatus;

      if(nowstat == 0){
        setSelected("대기중")
        sessionStorage.selectedFromState = "대기중"
      }else if(nowstat == 1){
        setSelected("이동중")
        sessionStorage.selectedFromState = "이동중"
      }else if(nowstat == 2){
        setSelected("활동중")
        sessionStorage.selectedFromState = "활동중"
      }else{
        setSelected("집결중")
        sessionStorage.selectedFromState = "집결중"
      }      
    }
    getUserData();

  }, []);

  useEffect(() => { // 최초 랜더링시 설명창 표출
    if(localStorage.viewmsg == undefined){
      setViewmsg(true);
    }
  },[selected]);

  useEffect(() => { 
    const windowHT = window.innerHeight
    const titleHT = titleRef.current.clientHeight
    const stateHT = stateRef.current.clientHeight
    const infotHt = document.getElementById("infotitle").clientHeight
    const infoHT = infoRef.current.clientHeight
    const footHT = footRef.current.clientHeight
    // console.log("화면전체" + windowHT);
    // console.log("타이틀" + titleHT);
    // console.log("상태" + stateHT);
    // console.log("기본정보타이틀" + infotHt);
    // console.log("기본정보" + infoHT);
    // console.log("푸터" + footHT);

    cheight = windowHT-titleHT-infotHt-infoHT-stateHT-footHT-145;
    // console.log("채팅높이는 " + cheight)
  }, []);

  const inputNameHandler = (e) => {
    console.log(e.target.value);
  };

  const saveName = (e) => {
    console.log("저장");
    if(window.confirm("저장하시겠습니까?") !== true) return;
    async function saveName() {
      const wstring = `${props.apiAddr}/post/savename`;
      const statData = {
        dsid: sessionStorage.ondsid,
        seqnum: sessionStorage.seqnum,
        mfnm: e.target.previousSibling.value,
      };
      console.log(statData);
      const response = await axios.post(wstring, statData);
      const result = response.data.result;
      if (result == "success") {
        alert("저장되었습니다.");
        nameRef.current.innerHTML = e.target.previousSibling.value;
      } else {
        alert("저장에 실패하였습니다.");
      }
    }
    saveName()
  }

  const infohandleChange = (event) => {
    const windowHT = window.innerHeight
    const titleHT = titleRef.current.clientHeight
    const stateHT = stateRef.current.clientHeight
    const infotHt = document.getElementById("infotitle").clientHeight
    const infoHT = infoRef.current.clientHeight
    const footHT = footRef.current.clientHeight

    const ox = event.target.checked;
    sessionStorage.InfoPanel = ox; 
    if(ox == false){ //
      cheight = windowHT-titleHT-infotHt-stateHT-footHT-10;
      console.log(cheight)
    }else{
      cheight = windowHT-titleHT-infotHt-infoHT-stateHT-footHT-139;
      console.log(cheight)
    }
    setViewinfo(ox);
  };

  const viewheight = () => {
    console.log(document.getElementById("chat").clientHeight);
  }

  const naviHandler = (e) => { // 집결지 길안내 
    const getrdvs = async () => {
      const navisort = e.target.innerHTML;
      const rname = e.target.parentNode.firstChild.innerHTML;
      const wstring = `${props.apiAddr}/get/getrdvs?rname=${rname}`;
      console.log(wstring);
      const result = await axios.get(wstring);
      const rData = result.data;
      console.log(rData);
      const name = rData[0].rname.trim();
      const lat = rData[0].lat*1;
      const lon = rData[0].lon*1;   
      // console.log(rData[0].lon*1);   

      if(navisort == "Tmap"){ // tmap
        var url = "tmap://route?goalname="+name+"&goalx="+lon+"&goaly="+lat+"&appKey=ZBpEdSCxYI78GflaK0xHx5VrethefIZC1oSWQ5pA";
        console.log(url)
        window.location.href = url;
      }else{ // kakao map
        initKakao('b7967dc1f2c2b6f788a029bc7b701f09').then((isloaded) => {
          window.Kakao.Navi.start({
            name: name,
            x: lon,
            y: lat,
            // name: "test",
            // x: 37.402056,
            // y: 127.108212,
            coordType: 'wgs84',
          })
        })
      }
    }
    getrdvs();
    
  }

  const setList = getlist.map((item) => (
    <tbody  key={Math.random()}>
      <tr  >
        <td>등록번호</td>
        <td>{item.seqnum}</td>
      </tr>
      <tr>
        <td>소속</td>
        {item.mfdorg != "                    " && item.mfdorg != null ? (
          <td style={{ textAlign: "center" }}>{item.mfdorg}</td>
        ) : (
          <select style={{ height: "auto" }}>
            <option style={{ color: "red" }}>수정요망</option>
            <option>중앙구조본부</option>
            <option>서울소방재난본부</option>
            <option>인천소방본부</option>
            <option>부산소방본부</option>
            <option>대구소방안전본부</option>
            <option>대전소방본부</option>
            <option>광주소방본부</option>
            <option>울산소방본부</option>
            <option>세종소방본부</option>
            <option>경기도소방본부</option>
            <option>강원도소방본부</option>
            <option>충청북도소방본부</option>
            <option>충청남도소방본부</option>
            <option>전라북도소방본부</option>
            <option>전라남도소방본부</option>
            <option>경상북도소방본부</option>
            <option>경상남도소방본부</option>
            <option>제주도소방본부</option>
          </select>
        )}
      </tr>
      <tr>
        <td>호출명(팀명)</td>
        <td>{item.mfcsgn}</td>
      </tr>
      <tr>
        <td>집결지</td>
        {item.mfrdvs != null
          ? 
          <td className={clss.rdvscss}><span>{item.mfrdvs}</span><span onClick={naviHandler}>Tmap</span><span onClick={naviHandler}>Kmap</span></td>
          :
          <td></td>
        }
      </tr>
      <tr>
        <td>선탑자명</td>
        {item.mfnm != "                    " && item.mfnm != null ? (
          <td>{item.mfnm}</td>
        ) : (
          <td ref={nameRef}>
            <input
              onClick={inputNameHandler}
              style={{
                textAlign: "center",
                color: "blue",
                fontWeight: "bold",
              }}
              placeholder="수정요망(직급 이름)"
            />
            <button style={{ marginLeft: "1rem" }} onClick={saveName}>저장</button>
          </td>
        )}
      </tr>
      <tr>
        <td>연락처</td>
        <td>{item.mfphnum}</td>
      </tr>
    </tbody>
  ));


  //현재컴포넌트의 배경색을 노란색으로 설정
  useEffect(() => {
    console.log("배경색 변경");
    document.body.style.backgroundColor = "#bddbd9";
    // document.body.style.display = "flex";
    // document.body.style.alignContent = "center";
    // document.body.style.flexDirection = "column";
    // document.body.style.justifyContent = "center"; 
    
  }, []);

  const callMessageModal = () => {
    setViewmsg(!viewmsg);
    localStorage.viewmsg = !viewmsg;
  }
  
  let codetxt = `${sessionStorage.seqnum}^${sessionStorage.ondsid}^${sessionStorage.chatname}^${sessionStorage.selectedFromState}`
  const callQRcode = () => {
    setCode(codetxt)
    setViewQR(!viewQR);
    //alert("qr코드를 눌러서 재난정보를 확인하세요.")
  }

  return (
    
    <Fragment >
      {console.log("랜더링 수 " + ++num)}
      {viewmsg && <Mfmessage viewmsg={callMessageModal} apiAddr={props.apiAddr}  />}
      {viewQR && <MfQRcode viewqr={callQRcode} apiAddr={props.apiAddr} code={codetxt} />}
      <div ref={titleRef} className={clss.title}>동원자원 사용자앱</div>
      <div ref={stateRef} className={clss.row}>
        <div
          id="집결중"
          ref={cntrRef}
          className={clss.tlit}
          // onClick={statBtnClickHandler}
          style={selected == "집결중" ? { backgroundColor: "black" } : gry}
        >
          <span><ZoomInMapIcon sx={icon}/>집결중</span>
          <span className={clss.subtlit}>시도에서 이동중</span>
        </div>
        <div
          id="대기중"
          ref={waitRef}
          className={clss.tlit}
          onClick={statBtnClickHandler}
          style={selected == "대기중" ? { backgroundColor: "green" } : gry}
        >
          <span><CoffeeIcon sx={icon}/>대기중</span>
          <span className={clss.subtlit}>자원집결지 대기</span>
        </div>
        <div
          id="이동중"
          ref={moveRef}
          className={clss.tlit}
          onClick={statBtnClickHandler}
          style={selected == "이동중" ? { backgroundColor: "blue" } : gry}
        >
          <span><FireTruckIcon sx={icon}/>이동중</span>
          <span className={clss.subtlit}>현장으로 이동중</span>
        </div>
        <div
          id="활동중"
          ref={actvRef}
          className={clss.tlit}
          onClick={statBtnClickHandler}
          style={selected == "활동중" ? { backgroundColor: "red" } : gry}
        >
          <span><FireExtinguisherIcon sx={icon} />활동중</span>
          <span className={clss.subtlit}>재난현장 활동중</span>
        </div>
      </div>

      {/* <FileUpload apiAddr={props.apiAddr} /> */}

      {/* 기본정보 */}
      <div id="infotitle" className={clss.infotitlte}>
        <span style={{marginLeft:"10px"}} >
          <HelpIcon onClick={callMessageModal} />
          <QrCodeIcon onClick={callQRcode} style={{marginLeft:"10px"}}/>
        </span>
        <span>기본정보</span>
        <span style={{marginRight:"10px"}}><Switch checked={viewinfo} onChange={infohandleChange} sx={switchstyle} size="small" /></span>
      </div>
      <table ref={infoRef} className={clss.table} key={Math.random()} id="chat" onClick={viewheight}>
        <thead >
        </thead>
        {viewinfo && setList}
      </table>

      {/* 채팅 */}
      <Chatting 
        chatname={sessionStorage.chatname}
        height={cheight}
        apiAddr={props.apiAddr}
        className={clss.chat}
      />
        
        {/* 푸터 */}
      <div className={clss.footer} ref={footRef}>강원특별자치도 소방본부</div>
    </Fragment>
  );
};

export default MFuserApp;
