import React, {Fragment, useState, useEffect} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function SimpleSnackbar(props) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [sort, setSort] = useState("");
  const vertical = "top"
  const horizontal = "center"

  // const handleClick = (props) => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  useEffect(()=>{
    if(props.sbShow != ""){ // sbShow가 ""이 아니면
      const arr = props.sbShow.split("^");
      setMessage(arr[1]); // arr[1]은 메시지
      setSort(arr[2]) // 
      setOpen(true);
    }
  },[props.sbShow])

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open} 
        autoHideDuration={1000} 
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={sort} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
