import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import "./ChatApp.modules.css";
import { Box} from "@mui/material";
import axios from "axios";
import Switch from '@mui/material/Switch';
import { grey, orange } from "@mui/material/colors";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileUpload from "./Fileuplad"; //채팅 & 공지사항 파일업로드 컴포넌트
import UploadIcon from '@mui/icons-material/Upload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useOneEffect } from "../useOneEffect";


const ticon = {
  color: "white",
  fontSize: "1.5rem",
  // marginTop: "0.5rem",
  // marginRight: "0.5rem",
  marginLeft: "0.5rem",
  cursor: "pointer",
  backgroundColor: orange[300],
  borderRadius: "50%",
};
const ficon = {
  color: "white",
  fontSize: "1rem",
  marginLeft: "0",
  paddingLeft: "0",
  cursor: "pointer",
  backgroundColor: orange[300],
  borderRadius: "50%",
};


function ChatApp(props) { 
  const location = useLocation();
  const qrs = location.search;
  const params = new URLSearchParams(qrs);
  // console.log(params.size)
  const csort = params.size == 0; // 0: 메인프로그램, 1: 동원자원앱
  //console.log(csort)
  //const hqox = sessionStorage.loginOrg // 로그인 조직 구분
  let ondsid = sessionStorage.ondsid.trim();

  const socket = io("https://chat.119survey.org/");

  const [reloadbrd, setReloadbrd] = useState(""); // 공지사항 재로딩
  const [reloadchat, setReloadchat] = useState(""); // 채팅 재로딩
  const [viewbrd, setViewbrd] = useState(true); // 공지사항 보기/숨기기
  const [viewchat, setViewchat] = useState(true); // 채팅창 보기/숨기기

  let wheight = window.innerHeight - 100;
  console.log(wheight);

  const switchstyle = {
    backgroundColor: grey[200],
    marginRight: 1,
    marginLeft: 1,
    borderRadius: 3,
  }

  useEffect(() => { // 채팅 데이터 수신 처리
    socket.on("chatting", (data) => { 
      ondsid = sessionStorage.ondsid.trim();
      if(sessionStorage.ChatPanel == "false"){return} // 채팅창 보기가 꺼져있으면 중지
      console.log(data)
      const { name, msg, time, dsid, time2, filename} = data;
      if(dsid != ondsid){return};
      // 이중전송방지를 위하여 변수
      if(sessionStorage.beforeData == undefined){sessionStorage.beforeData = "name^msg^time^dsid"};
      // // 이중전송방지를 위하여 이전데이터와 비교하여 동일하면 중지
      if(sessionStorage.beforeData == name+"^"+msg+"^"+dsid+"^"+time2){return};
      const item = new LiModel(name, msg, time, filename);
      item.makeLi();
      const displayContainer = document.querySelector(".display-container");
      displayContainer.scrollTo(0, displayContainer.scrollHeight);
      sessionStorage.beforeData = name+"^"+msg+"^"+dsid+"^"+time2;
    });
  }, []);

  useEffect(() => { // 공지사항 데이터 수신 처리
    socket.on("brd", (data) => { 
      if(sessionStorage.BrdPanel == "false"){return} // 채팅창 보기가 꺼져있으면 중지
      setReloadbrd(+new Date());
    });
  }, [props.refreshChat]);
  
  
  const delbrd = (e) => { //공지게시판 항목삭제
    if(!window.confirm("삭제하시겠습니까")){return}
    const sendDelbrd = async () => { 
      const snum = e.target.value
      const wstring = `${props.apiAddr}/get/delbrdmsg?dsid=${ondsid}&snum=${snum}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const result = response.data.result;
      console.log(result);
      if(result == 'success'){
        setReloadbrd(+new Date());
        socket.emit("brd");
      }else{
        alert("삭제에 실패했습니다. 잠시후 다시 시도해주세요")
      }
    }
    sendDelbrd()
  }

  useEffect(() => {// 공지사항 데이터 수신 처리
    const getbrdmsg = async () => {
      const wstring = `${props.apiAddr}/get/getbrdmsg?dsid=${ondsid}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const responseData = response.data;
      //console.log(responseData);

      if(viewbrd == false){return} // 공지사항 보기가 꺼져있으면 중지
      const brdlist = document.querySelector(".brd-list");
      brdlist == null ? console.log("공지사항 리스트가 없습니다.") : brdlist.innerHTML = "";
      // brdlist.innerHTML = "";
      responseData.map((item) => {
        // item.cipdt 값을 const tvalue에 yy.mm.dd hh:mm:ss 형식으로 저장
        const tvalue = item.cipdt.substring(2, 16).replace("T", " ");
        const btn = document.createElement("button");
        btn.classList.add("accordion");
        const sp1 = document.createElement("span");
        sp1.classList.add("brd-item-title");
        sp1.innerHTML = item.ctitle;
        const sp2 = document.createElement("span");
        sp2.classList.add("brd-item-date");
        sp2.innerHTML = tvalue;
        btn.appendChild(sp1);
        btn.appendChild(sp2);

        // btn.innerHTML = `<span>${item.ctitle}</span><span className="brd-item-date">${tvalue}</span>`;
        let fnm, filepath;
        fnm = item.cfilename.trim();
        if(fnm == "undefined"){
          fnm = "";
        }else{
          fnm = item.cfilename.trim();
          filepath = `https://mfm.119survey.org/uploads/${fnm}` // 파일경로
        }
        const div = document.createElement("div");
        div.classList.add("panel");
        // fnm의 확장자 추출
        const filexe = fnm.split(".")[1];
        if(filexe == "jpg" || filexe == "png" || filexe == "gif" || filexe == "jpeg" || filexe == "bmp"){
          // div.innerHTML = `<p>${item.cmsg}</p><p><img src='${filepath}' width=200 height=200 ></p>`;
          const p1 = document.createElement("p");
          const p2 = document.createElement("p");
          const img = document.createElement("img");
          img.src = filepath;
          img.width = 200;
          img.height = 200;
          img.addEventListener("click", function() {
            openImage(filepath);
          });
          p1.innerHTML = item.cmsg;
          p2.appendChild(img);
          div.appendChild(p1);
          div.appendChild(p2);

        }else{
          div.innerHTML = `<p>${item.cmsg}</p><p><a href=${filepath}>${fnm}</a></p>`;
        }
        const sp3 = document.createElement("button");
        sp3.classList.add("brd-item-del");
        sp3.innerHTML = "삭제";
        sp3.value = item.snum;
        sp3.addEventListener("click", delbrd);
        csort && div.appendChild(sp3);

        btn.addEventListener("click", function() {
          this.classList.toggle("active");
          var panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
          }
          // 선택한거 외에는 모두 닫기 기능 추가
          var acc = document.getElementsByClassName("accordion");
          for (var i = 0; i < acc.length; i++) {
            if(acc[i] != this){
              acc[i].classList.remove("active");
              var panel = acc[i].nextElementSibling;
              panel.style.maxHeight = null;
            }
          }
        });
        brdlist == null ? console.log("공지사항 리스트가 없습니다.") : brdlist.appendChild(btn);
        brdlist == null ? console.log("공지사항 리스트가 없습니다.") : brdlist.appendChild(div);
      });
    }
    // getbrdmsg()
    setTimeout(() => {
      getbrdmsg()
    }, 1000);
  }
  , [reloadbrd, props.refreshBrd]);

  const imgview = (path) => {
    alert(path)
    // window.open(path, "imgview", "width=500, height=500, toolbar=no, menubar=no, scrollbars=no, resizable=yes");

  }

  function openImage(filepath) {
    // 이미지를 새 창에서 열도록 하는 로직을 추가
    window.open(filepath, '_blank');
  }

  useEffect(() => { // DB로부터 받은 채팅데이터 수신 처리
    // console.log(sessionStorage.rprops + "--" + props.refreshChat)
    if(sessionStorage.rprops < props.refreshChat){
      sessionStorage.removeItem("latesnum");
      const chatList = document.querySelector(".chatting-list");
      chatList.innerHTML = "";
    }
    sessionStorage.rprops = props.refreshChat;
    // alert("채팅창 갱신요청됨")

    const getchatmsg = async () => {
      if(sessionStorage.latesnum == undefined){sessionStorage.latesnum = 0}
      const wstring = `${props.apiAddr}/get/getchatmsg?dsid=${ondsid}&latesnum=${sessionStorage.latesnum}`;
      console.log(wstring);
      const response = await axios.get(wstring);
      const responseData = response.data;
      // console.log(responseData);
      
      if(viewchat == false){return} // 채팅창 보기가 꺼져있으면 중지
      const chatList = document.querySelector(".chatting-list");
      //chatList의 리스트갯수 콘솔출력
      // console.log(chatList.childElementCount);
      if(chatList.childElementCount == 0){
        chatList.innerHTML = "";
      }
      responseData.map((item) => {
        // item.cipdt 값을 const tvalue에 hh:mm AM PM 형식으로 저장
        let tvalue = item.cipdt.substring(11, 16);
        tvalue > "12:00" ? tvalue = tvalue + " PM" : tvalue = tvalue + " AM";      
        const li = document.createElement("li");
        const on = item.cname.split(" ");
        li.classList.add(nameRef.current.value.trim() == item.cname.trim() ? "sent" : "received");

        const fn = item.cfilename.trim(); // 파일명
        const filexe = fn.split(".")[1]; // 파일확장자 획득
        const filepath = `https://mfm.119survey.org/uploads/${fn}` // 파일경로

        if(fn == "undefined" || fn == ""){
          const dom = `<span class="profile">
            <span class="user_org">${on[0]}</span>
            <span class="user">${on[1]}</span>
            </span>
            <span class="message">${item.cmsg}</span>
            <span class="time">${tvalue}</span>`
          li.innerHTML = dom;
        }else{
          // 파일종류가 이미지이면 이미지로 출력
          if(filexe == "jpg" || filexe == "png" || filexe == "gif" || filexe == "jpeg" || filexe == "bmp"){
            const dom = `
            <span class="profile">
              <span class="user_org">${on[0]}</span>
              <span class="user">${on[1]}</span>
              <img src='${filepath}' width=100 height=100 >
            </span>
            <span class="message">${item.cmsg}</span>
            <span class="time">${tvalue}</span>`
            li.innerHTML = dom;
            li.addEventListener("click", function() {
              openImage(filepath);
            });
          }else if(filexe == 'mp4'){
            const dom = `
            <span class="profile">
              <span class="user_org">${on[0]}</span>
              <span class="user">${on[1]}</span>
              <video src='${filepath}' width=100 height=100 >
            </span>
            <span class="message">${item.cmsg}</span>
            <span class="time">${tvalue}</span>`
            li.innerHTML = dom;
            li.addEventListener("click", function() {
              openImage(filepath);
            });
          }
          else{ // 파일종류가 이미지가 아니면 파일명으로 출력
            const dom = `
            <span class="profile">
              <span class="user_org">${on[0]}</span>
              <span class="user">${on[1]}</span>
            </span>
            <span class="message"><a href=${filepath}>${item.cmsg}</a></span>
            <span class="time">${tvalue}</span>`
            li.innerHTML = dom;
          }
        }
        chatList.appendChild(li);
      });
      const displayContainer = document.querySelector(".display-container");
      displayContainer.scrollTo(0, displayContainer.scrollHeight);
      // responseData에 값이있으면 마지막값의 snum값을 sessionStorage.chatmsgs에 저장
      if(responseData.length > 0){
        sessionStorage.latesnum = responseData[responseData.length - 1].snum;
      }
    }
    getchatmsg()
  }
  , [reloadchat, props.refreshChat]);

  const btinputRef = useRef(); // 공지사항 제목(title) 데이터
  const bminputRef = useRef(); // 공지사항 본분(msg) 데이터
  const bfinputRef = useRef(); // 공지사항 파일첨부(file) 데이터
  const inputRef = useRef(); // 채팅 입력데이터
  const nameRef = useRef(); // 대화명 입력데이터
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      send();
    }
  };

  const getloc = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    function success(pos) {
      const crd = pos.coords;
      sessionStorage.glat = crd.latitude;
      sessionStorage.glon = crd.longitude;
      sessionStorage.gacc = crd.accuracy;
      sessionStorage.gtme = new Date().toISOString().slice(0, 19).replace(/-/g, "").replace(/:/g, "").replace("T", "");
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  function sendChattoDB(param) { // 채팅(공지) 데이터 DB입력
    const inputchatmsg = async () => {
      // 동원력 DB입력
      const wstring = `${props.apiAddr}/post/inputchatmsg`;
      // console.log(wstring);
      const response = await axios.post(wstring,param);
      const result = response.data.result;
      // console.log(result);
      if (result == "success") {
        console.log("등록성공")
        setReloadbrd(+new Date());
        sessionStorage.filename = ""; // 파일명 초기화
      } else {
        console.log("등록실패")
      }
    }
    setTimeout(() => {
      inputchatmsg()
    }
    , 1000);
  }

  const send = () => { // 채팅 데이터 실시간 전송 처리
    if(sessionStorage.fileSendOx == "true") { // 파일전송중이면 파일전송기능으로 변경
      getloc(); // 위치정보 획득
      if(window.confirm("파일을 전송하시겠습니까?")){
        sendBtnClickHandler(); // 파일전송기능 호출
      }else{
        inputRef.current.value = ""; // 채팅 입력데이터 초기화
      }
      sessionStorage.fileSendOx = "false"; // 파일전송중이 아니도록 변경
      // return;
    }

    if(inputRef.current.value==""){return}
    const param = {
      name: nameRef.current.value,
      msg: inputRef.current.value,
      filename: sessionStorage.filename,
      dsid: ondsid,
      lat: sessionStorage.glat,
      lon: sessionStorage.glon,
      msgsort: 1, // 0:공지, 1:채팅
    };
    socket.emit("chatting", param);
    inputRef.current.value = ""; // 채팅 입력데이터 초기화
    inputRef.current.focus();
    console.log(param);
    sendChattoDB(param);
  };

  const syncphoto = () => { // 채팅창 사진 동기화 소켄서버로 보내기
    const param = {
      dsid: ondsid,
    };
    socket.emit("syncphoto", param);
  }

  function LiModel(name, msg, time, filename){
    let narr = name.split(" ");
    let name_org = narr[0];
    let name_name= narr[1];
    this.name = name;
    // console.log(this.name + " : " + nameRef.current.value);
    this.msg = msg;
    this.time = time;
    if(filename == undefined){filename = ""}
    this.filename = filename;
  
    this.makeLi = () => { // 수신 채팅메시지 만들기
      if(sessionStorage.ChatPanel == "false"){return} // 채팅창 보기가 꺼져있으면 중지
      const chatList = document.querySelector(".chatting-list");
      const li = document.createElement("li");
      li.classList.add(nameRef.current.value == this.name ? "sent" : "received");

      const fn = this.filename.trim(); // 파일명
      const filexe = fn.split(".")[1]; // 파일확장자 획득
      const filepath = `https://mfm.119survey.org/uploads/${fn}` // 파일경로
      
      if(fn == "undefined" || fn == ""){ // 파일이 없으면 일반메시지로 출력
        const dom = 
          `<span class="profile">
            <span class="user_org">${name_org}</span>
            <span class="user">${name_name}</span>
          </span>
          <span class="message">${this.msg}</span>
          <span class="time">${this.time}</span>`
        li.innerHTML = dom;
      }else{
        // 파일종류가 이미지이면 이미지로 출력
        if(filexe == "jpg" || filexe == "png" || filexe == "gif" || filexe == "jpeg" || filexe == "bmp"){
          const dom = `
          <span class="profile">
            <span class="user_org">${name_org}</span>
            <span class="user">${name_name}</span>
            <img src='${filepath}' width=100 >
          </span>
          <span class="message">${this.msg}</span>
          <span class="time">${this.time}</span>`
          li.addEventListener("click", function() {
            openImage(filepath);
          });
          setTimeout(() => {
            li.innerHTML = dom;  
            const displayContainer = document.querySelector(".display-container");
            displayContainer.scrollTo(0, displayContainer.scrollHeight);
            syncphoto()
          }, 1000);
        }else if(filexe == 'mp4'){
          const dom = `
          <span class="profile">
            <span class="user_org">${name_org}</span>
            <span class="user">${name_name}</span>
            <video src='${filepath}' width=100 >
          </span>
          <span class="message">${this.msg}</span>
          <span class="time">${this.time}</span>`
          li.addEventListener("click", function() {
            openImage(filepath);
          });
          li.innerHTML = dom;
        }else{ // 파일종류가 이미지가 아니면 파일명으로 출력
          const dom = 
          `<span class="profile">
            <span class="user_org">${name_org}</span>
            <span class="user">${name_name}</span>
          </span>
          <span class="message"><a href=${filepath}>${this.msg}</a></span>
          <span class="time">${this.time}</span>`
          li.innerHTML = dom;
        }
      }
      chatList.appendChild(li);
    }
  }

  const bsend = () => { // 공지사항 데이티 실시간 전송 처리
    if(sessionStorage.fileSendOx == "true") { // 파일전송중이면 파일전송기능으로 변경
      sendBtnClickHandler(); // 파일전송기능 호출
      sessionStorage.fileSendOx = "false"; // 파일전송중 해제
    }

    const ox = window.confirm("등록하시겠습니까?")
    if(!ox){return}
    if(btinputRef.current.value==""){return}
    const param = {
      name: "관리자",
      title: btinputRef.current.value,
      msg: bminputRef.current.value,
      filename: bfinputRef.current.value,
      dsid: ondsid,
      msgsort: 0,
    };
    socket.emit("brd");
    btinputRef.current.value = ""; // 공지제목 입력초기화
    btinputRef.current.focus();
    bminputRef.current.value = ""; // 공지본문 입력초기화
    bfinputRef.current.value = ""; // 공지파일 입력초기화
    bminputRef.current.focus();
    console.log(param);
    sendChattoDB(param);
  }

  const brdhandleChange = (e) => {
    // 공지사항 보이기/숨기기
    const ox = e.target.checked;
    // 보이기면 공지데이터 데이터 재로딩
    ox ? setReloadbrd(+new Date()) : console.log("숨기기");
    setViewbrd(ox);
    sessionStorage.BrdPanel = e.target.checked;
    //props.hidechat() // 동원자원 목록 숨기기 프롭 호출
  }
  const chathandleChange = (e) => { 
    const ox = e.target.checked;
    console.log(ox)
    // 보이기면 채팅데이터 데이터 재로딩
    sessionStorage.removeItem("latesnum");
    ox ? setReloadchat(+new Date()) : console.log("숨기기");
    setViewchat(ox);
    sessionStorage.ChatPanel = e.target.checked;
  }

  useOneEffect(() => { // 공지사항 전체보기/숨기기 프롭처리
    // 공지사항 보이기/숨기기
    const ox = props.fullswitch;
    // 보이기면 공지데이터 데이터 재로딩
    ox ? setReloadbrd(+new Date()) : console.log("숨기기");
    setViewbrd(ox);
    sessionStorage.BrdPanel = ox;
    //props.hidechat() // 동원자원 목록 숨기기 프롭 호출
  }, [props.fullswitch]);

  useOneEffect(() => { // 채팅 전체보기/숨기기 프롭처리
    const ox = props.fullswitch;
    console.log(ox)
    // 보이기면 채팅데이터 데이터 재로딩
    sessionStorage.removeItem("latesnum");
    ox ? setReloadchat(+new Date()) : console.log("숨기기");
    setViewchat(ox);
    sessionStorage.ChatPanel = ox;
  }, [props.fullswitch]);

  if(!viewbrd && !viewchat){
    wheight = 80;
  }

  const refreshHandler = () => {
    setReloadbrd(+new Date())
  }

  const crefreshHandler = () => {
    setReloadchat(+new Date())
  }

  const test = () => {
    // sessionStorage.chatmsgs오브젝트형식을 문자열로 변환하여 저장
    const chatmsgs = JSON.stringify(sessionStorage.chatmsgs);
    console.log(chatmsgs)
  }

  // 채팅창 파일입력 호출
  const [inputclicked, setInputclicked] = useState(0);
  const uploadBtnClickHandler = (e) => {
    sessionStorage.inputedFileSort = e.target.id; // 파일입력버튼 구분값 저장
    setInputclicked(+new Date());
  }

  const [sendclicked, setSendclicked] = useState(0);
  const sendBtnClickHandler = () => {
    setSendclicked(+new Date());
  }

  //const [filesendingox, setFilesendingox] = useState(false); // 파일전송중인지 여부
  const inputFilename = (filename) => {
    const sort = sessionStorage.inputedFileSort; // 파일입력버튼 구분값
    if(sort == 'bf') {
      bfinputRef.current.value = filename;
    }
    if(sort == 'cf') {
      inputRef.current.value = filename;
    }
    filename == "" ? sessionStorage.fileSendOx = false : sessionStorage.fileSendOx = true; // 파일전송중으로 변경(채팅전송버튼이 파일전송기능을수행하도록 변경)
  }
  
  return (
    <Box
      className="wrapper"
      sx={
        csort == true ? // 0: 메인프로그램, 1: 동원자원앱
        {
          position: "absolute",
          zIndex: 10,
          top: 70,
          right: 20,
          marginLeft: 0.8,
          width: "400px",
          height: wheight,
          border: "solid 1px black",
        } 
        : 
        {
          position: "relative",
          zIndex: 10,
          width: "100%",
          // height: "300px",
          height: props.height,
          border: "solid 1px black",
        }
      }
    >
      <div className="wrapper">
        <div className="titlecontaier">
          <div className="titleLeft"><RefreshIcon sx={ticon} onClick={refreshHandler} /></div>
          <div className="brd-title">
            <label htmlFor="nickname">
              공지사항
            </label>
          </div>
          <div className="titleRight"><Switch checked={viewbrd} onChange={brdhandleChange} sx={switchstyle} size="small" /></div>
        </div>

        {viewbrd && // 채팅창 보기/숨기기
        <div className="brd-container">
          <div className="brd-list">
            <button className="accordion"><span>제목</span><span>2023.11.14 15:35:45</span></button>
            <div className="panel">
              <p>내용 </p>
            </div>
          </div>
        </div>
        }
        
        {csort && viewbrd && 
        <div className="brd-input-container">
          <span style={{display:"flex"}}>
            <div style={{display:"flex",flexDirection:"column", flex:"8"}}>
              <input
                type="text"
                className="board-input-title"
                ref={btinputRef}
                placeholder="제목을 입력하세요"
              />
              <textarea
                className="board-input-msg"
                ref={bminputRef}
                placeholder="내용을 입력하세요"
              >
              </textarea>


              <div className="brd-input-file-container">
                <AttachFileIcon id="bf" sx={ficon} style={{marginRight:"10px", border:"1px solid black"}} onClick={uploadBtnClickHandler} />
                <input
                  type="text"
                  className="board-input-file-msg"
                  ref={bfinputRef}
                  placeholder="파일첨부"
                />
              </div>


            </div>
            <button className="bsend-button" onClick={bsend}>
              등록
            </button>
          </span>
        </div>
        }

        <div className="user-container">
          <div className="ctitleLeft"><RefreshIcon sx={ticon} onClick={crefreshHandler} /></div>
          <div className="ctitleCenter">
            <label style={{fontWeight:"bold", fontSize:"20px"}} onClick={test}>
              채팅 
            </label>
            {viewchat &&  
            <input
              type="text"
              className="titleInput"
              ref={nameRef}
              defaultValue={csort == true ? "본부 통제단" : props.chatname}
            />
            }
          </div>
          <div className="ctitleRight"><Switch checked={viewchat} onChange={chathandleChange} sx={switchstyle} size="small"/></div>
        </div>

        {viewchat &&
        <div className="display-container">
          <ul className="chatting-list">
          </ul>
        </div>
        }
        {viewchat &&
        <div className="input-container">
          <span>
            {/* 하단 FileUpload 를 숨기기 */}
            <FileUpload 
              apiAddr={props.apiAddr} 
              inputclicked={inputclicked} // 파일선택 프롬 호출
              inputFilename={inputFilename} // 채팅창에 파일명입력 함수호출
              sendclicked={sendclicked}  // 전송버튼 클릭 프롭 호출
            />
            <UploadIcon id="cf" sx={ticon} style={{marginRight:"10px", border:"1px solid black"}} onClick={uploadBtnClickHandler} />
            <input
              type="text"
              className="chatting-input"
              onKeyPress={handleKeyPress}
              ref={inputRef}
            />
            <button className="send-button" onClick={send}>
              전송
            </button>
          </span>
        </div>
        }
      </div>
    </Box>
  );
}

export default ChatApp;
