
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FileUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);

  // data()함수를 이용해서 "yyyymmddhhmmss"형식으로 날짜를 구함
  const timevalue = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}${month < 10 ? `0${month}` : `${month}`}${day < 10 ? `0${day}` : `${day}`}${hours < 10 ? `0${hours}` : `${hours}`}${minutes < 10 ? `0${minutes}` : `${minutes}`}${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
  };

  useEffect(() => { // 파일 입력 대화상자를 열기 위한 useEffect
    //처음 렌더싱시 실행을 방지
    if(props.inputclicked === 0) return;
    document.getElementById('file').click();
  }, [props.inputclicked] );

  useEffect(() => { // 파일이 선택되면 파일 업로드 함수 호출
    // document.getElementById('sendFileBtn').click();
    if(selectedFile !== null){
      handleFileUpload();
    }
  }, [props.sendclicked]);

  const handleFileChange = (event) => {
    // 파일이 이미지인지 아닌지 검사
    if(event.target.files[0] === undefined){ // 파일 선택후 재선택시 취소하여 파일이 없을시 건너뛰기
      props.inputFilename("")
      return
    } 
    const file = event.target.files[0];
    const fileType = file.type.split('/')[0];
    let fn = file.name;
    fn = fn.replace(/ /gi, '_'); // 파일명에 공백이 있으면 _로 변경
    if (fileType !== 'image') {
      //이미지가 아닐경우 바로 전송
      const fnm = sessionStorage.filename = timevalue() + '_'+fn;
      setSelectedFile(event.target.files[0]);
      props.inputFilename(fnm)
    }else{
      // 이미지일 경우 가로세로 1000px 이하로 리사이징 후 전송
      const fnm = sessionStorage.filename = timevalue() + '_'+fn;
      sessionStorage.filename = timevalue() + '_'+fn;
      props.inputFilename(fnm)
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {

          const dataURItoBlob = (dataURI) => {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
          };

          const MAX_IMAGE_SIZE = 1000; // Maximum dimensions for the image
          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > MAX_IMAGE_SIZE || img.height > MAX_IMAGE_SIZE) {
            if (img.width > img.height) {
              newWidth = MAX_IMAGE_SIZE;
              newHeight = (img.height * MAX_IMAGE_SIZE) / img.width;
            } else {
              newHeight = MAX_IMAGE_SIZE;
              newWidth = (img.width * MAX_IMAGE_SIZE) / img.height;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          const resizedDataURL = canvas.toDataURL('image/jpeg', 0.7); // Adjust quality if needed
          setSelectedFile(dataURItoBlob(resizedDataURL));
        };
      }
      reader.readAsDataURL(file);
    }
  };
    //setSelectedFile(event.target.files[0]);

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      const encodedFileName = encodeURIComponent(sessionStorage.filename);
      formData.append('file', selectedFile, encodedFileName);

      //formData에 dsid, chatname 추가
      const ondsid = sessionStorage.ondsid;
      const chatname = sessionStorage.chatname;
      const seqnum = sessionStorage.seqnum;
      formData.append('dsid', ondsid);
      formData.append('chatname', chatname);
      formData.append('seqnum', seqnum);
      
      const wstring = `${props.apiAddr}/upload/`;
      console.log('wstring', wstring);
      const response = await axios.post(wstring, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      //console.log('File upload successful:', response.data);
      console.log('File upload successful:');
      setTimeout(() => {
        document.getElementById('file').value = '';
        props.inputFilename('');
      }, 100);
      

    } catch (error) {
      // console.error('File upload failed:', error);
      console.error('File upload failed:', error);
      alert('파일 업로드에 실패하였습니다. 다시 시도해주세요.')
    }

    // // 파일전송 목록 저장
    // const wstring = `${props.apiAddr}/get/filename?dsid=${ondsid}&snum=${snum}`;
    // const response = await axios.get(wstring);
    
  };
  return (
    <div>
      <input id='file' type="file" onChange={handleFileChange} style={{display:"none"}}/>
      {/* input 에 모바일 카메라 전용 으로 열기 */}
      {/* <input type="file" accept="image/*" capture="camera" /> */}
      {/* <button id='sendFileBtn' onClick={handleFileUpload} style={{display:"none"}} >Upload File</button> */}
    </div>
  );
};

export default FileUpload;

