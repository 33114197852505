import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Link,
  Button,
} from "@mui/material";

import React, { useEffect, useState, useContext,useRef } from "react";
import { sbHeightCtx } from "../../store/sidebarWiHeContext";
import { lightBlue, grey, green, blue, teal, red, yellow, purple } from "@mui/material/colors";
import axios from "axios";
import excel_up from "../../image/excel_up.jpeg";
import excel_down from "../../image/excel_down.png";
import ListIcon from '@mui/icons-material/List';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import cls from "./Status.module.css";
import readXlsxFile from 'read-excel-file'
import MFexlinput from "../Modal/MflistExcelinput";
import * as XLSX from 'xlsx';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useOneEffect } from "../useOneEffect";

const excelbtn ={
  width: 35,
  height: 30,
  border: "solid 1px yellow",
  borderRadius: 3,
  "&:hover": {
    backgroundColor: green[300],
    pointer: "cursor",
  },
  // backgroundColor: "white",
  zIndex: 10,
}

const statusTitle={
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "bold",
  backgroundColor: red[900],
  color: "white",
  padding:0.5,
  fontWeight: "bold",
}
const statusTitleG={
  display: "flex",
  justifyContent: "space-between",
  fontWeight: "bold",
  backgroundColor: grey[500],
  color: "white",
  padding:0.5,
  fontWeight: "bold",
}

const tblstyle = {
  position: "sticky",
  top: 0,
  fontWeight: "bold",
  backgroundColor: red[400], 
  color: "white",
  textAlign: "center",
}
const tblstyleG = {
  fontWeight: "bold",
  backgroundColor: grey[400],
  color: "white",
  textAlign: "center",
}

const rowstyle={
  fontWeight: "bold",
  color: "black",
  border: "solid 1px black",
  fontSize: "0.9rem",
  textAlign: "center",
  paddingLeft: 0.5,
  paddingRight: 0.5,
  marginLeft: -1,
  marginRight: -1,
  marginTop: -0.3,
  marginBottom: -0.3,
  borderRadius: 1,
  "&:hover": {
    backgroundColor: green[300],
  },
}

const rowstyleG={
  fontWeight: "bold",
  color: "white",
  border: "solid 1px black",
  textAlign: "center",
}
const volbgc = {
  position: "sticky",
  top: 0,
  fontWeight: "bold",
  backgroundColor: red[200],
  color: "white",
  textAlign: "center",
  paddingLeft: 0.5,
  paddingRight: 0.5,
}
const volbgcG = {
  fontWeight: "bold",
  backgroundColor: grey[300],
  color: "white",
  textAlign: "center",
  paddingLeft: 0.5,
  paddingRight: 0.5,
}

const eqpBtn = {
  fontWeight: "bold",
  color: "white",
  marginLeft: -1,
  marginRight: -1,
  paddingLeft: 0,
  paddingRight: 0,
  border: "solid 1px black",
  borderRadius: 1,
  "&:hover": {
    backgroundColor: green[300],
  },
  pointer: "cursor",
}

// const bcc = teal[100];
const bcd = teal[50];
let bgcF,bgcV = "";

const dcell ={
  textAlign: "center",
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: teal[100],
}

const cellnum = {
  fontSize: "0.9rem",
  border: "dotted 1px black",
  paddingLeft: 3,
  paddingRight: 3,
  borderRadius: 3,
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const StatusPanel = (props) => {
  console.log(props);
  const {onSelectDS} = useContext(sbHeightCtx);
  console.log(onSelectDS);
  const [mfdata, setmfData] = useState([]);
  const [isdata, setIsdata] = useState(false); // 데이터가 있는지 여부 목록표출
  const [checked, setChecked] = useState(true); // 상황판 보기/숨기기

  useEffect(() => {
    // 동원자원 통계 데이터 가져오기
    const fetchData = async () => {
      const wstring = `${props.apiAddr}/get/onmflist?dsid=${sessionStorage.ondsid}`;
      console.log(wstring);
      const result = await axios.get(wstring);
      console.log(result.data);
      let tmp = result.data;
      // tmp배열 마지막값을 처음으로 보내기
      tmp.unshift(tmp.pop());
      if(tmp.length > 1){
        setmfData(tmp);
        setIsdata(true);
        sessionStorage.selectedOrg = ""
        sessionStorage.selectedEqp = ""
      }
      else{
        setIsdata(false);
        sessionStorage.selectedOrg = ""
        sessionStorage.selectedEqp = ""
      }
    };
    fetchData();
    if(sessionStorage.StatusPanel == "true"){
      changedNum()
    }
  }, [onSelectDS]);

  const orgBtnClickHandler = (e) => { // 기관버튼 클릭시 해당 기관의 동원자원 목록 가져오기
    sessionStorage.selectedOrg = e.target.id;
    sessionStorage.selectedEqp = "";
    props.mflist() // 동원자원 목록 프롭 호출
    props.viewmf() // 동원자원 목록 표출 여부 프롭 호출
  }

  const eqpBtnHandler = (e) => { // 펌프, 물탱크 버튼 클릭시 해당 기관의 동원자원 목록 가져오기
    sessionStorage.selectedEqp = e.target.textContent;
    sessionStorage.selectedOrg = "";
    props.mflist() // 동원자원 목록 프롭 호출
    props.viewmf() // 동원자원 목록 표출 여부 프롭 호출
  }

  useEffect(() => {
    // 상황판 갱신항목 선별 배경반전
    if(sessionStorage.StatusPanel == "true"){
      changedNum()
    }
  }, [props.statechanged]);

  useEffect(() => {
    sessionStorage.StatusPanel = true;
  }, []);

  const changedNum = () => { // 상황판 갱신항목 선별 배경반전
    const table = document.getElementById("statusTable");
    // console.log(table.rows.length)
    const org = sessionStorage.refreshOrg;
    const eqp = sessionStorage.refreshEqp;
    let xval, yval = "";
    // 테이블 첫열의 값을 org와 비교하여 같으면 배경색을 변경
  
    for (let i = 1; i < table.rows.length; i++) {
      const xcells = table.rows[i].cells[0]
      if(xcells.textContent == org) {
        setTimeout(() => {
          xcells.children[0].classList.remove(cls.blackblink);
        }, 2000);
        xcells.children[0].classList.add(cls.blackblink);
        //선택된 td안의 span 요소의 백경을 바꿈 
        xval = i;
      }
    }   
    // 테이블 첫행의 값을 eqp와 비교하여 같으면 배경색을 변경
    for (let i = 1; i < table.rows[0].cells.length; i++) {
      const ycells = table.rows[0].cells[i]
      if (ycells.textContent == eqp) {
        setTimeout(() => {
          ycells.children[0].classList.remove(cls.blackblink);
        }, 2000);
        ycells.children[0].classList.add(cls.blackblink);
        yval = i;
      }
    }
    if(sessionStorage.refreshSort == "input"){
      setTimeout(() => {
        table.rows[xval].cells[yval].children[0].classList.remove(cls.blackblink);
        table.rows[1].cells[1].children[0].classList.remove(cls.blackblink);
      }, 2000);
      table.rows[xval].cells[yval].children[0].classList.add(cls.blackblink);
      table.rows[1].cells[1].children[0].classList.add(cls.blackblink);
    }
  }

  const docdownClickHandler = () => {
    // src/download폴더의 doc.xlsx파일을 다운로드 연결
    const link = document.createElement("a");
    link.href = `https://mfm.119survey.org/download/mfm.xlsx`;
    link.download = "mfm.xlsx";
    document.body.appendChild(link);
    link.click();
  }

  const excelUploadClickHandler = () => {
    alert("동원자원이 입력된 엑셀파일을 선택해주세요.")
    // 엑셀파일 업로드
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.click();
    input.onchange = (event) => {
      readXlsxFile(input.files[0]).then(function(data) {
        sessionStorage.mfexcellist = JSON.stringify(data);
        callMFexlinputModal()
      }, function (error) {
        console.error(error)
      })
    }
  }

  const callMFexlinputModal = () => {
    // 동원자원 엑셀파일 입력 모달 호출
    sessionStorage.modalSort = "MFexcelinput";
    props.onShowDS();
  }

  const tableToExcelExport = () => { //상황판을 엑셀로 저장
    const XLSX = require('xlsx');
    // HTML에서 "tble" ID를 가진 테이블 요소를 가져옵니다.
    const table = document.getElementById('statusTable');
    // 테이블의 모든 값을 배열로 추출합니다.
    const data = [];
    for (let row of table.rows) {
      const rowData = [];
      for (let cell of row.cells) {
        // 셀 값을 문자열로 추출하고 작은 따옴표로 묶어서 텍스트로 설정합니다.
        rowData.push(` ${cell.textContent}`);
      }
      data.push(rowData);
    }
    // 새 워크북을 생성합니다.
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    // 시트를 워크북에 추가합니다.
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // 파일로 저장합니다.
    XLSX.writeFile(wb, '상황판.xlsx');
  }
  
  const handleChange = (e) => {
    // 상황판 보기/숨기기
    console.log(e.target.checked);
    setChecked(e.target.checked);
    sessionStorage.StatusPanel = e.target.checked;
    props.hidemf() // 동원자원 목록 숨기기 프롭 호출
  }

  useOneEffect(() => { // 전체보기/숨기기 프롭처리
    console.log("전체보기/숨기기 설정이 변경되었습니다." + props.fullswitch);
    // 상황판 보기/숨기
    const ox = props.fullswitch;
    // 상황판 보기/숨기기
    setChecked(ox);
    sessionStorage.StatusPanel = ox;
    props.hidemf() // 동원자원 목록 숨기기 프롭 호출
  }, [props.fullswitch]);


  const mflist = mfdata.map((item) => (
    <TableRow
      key={item.org}
      sx={{ "&:first-of-type td, &:first-of-type th": { fontWeight: 'bold', backgroundColor: yellow[200] } }}
    >
      <TableCell component="th" scope="row" sx={{ fontStyle:"bold" }} bgcolor={teal[100]} >
        {/* <Link href="#" onClick={orgBtnClickHandler}>
          {item.org == 'sum' ? "계" : (item.org.trim().replace(/본부|소방|안전|구조|재난|상|라|청|도|입력/g, ""))}
        </Link> */}
        <Typography id={item.org} sx={sessionStorage.oningox == 1 ? rowstyle : rowstyleG} onClick={orgBtnClickHandler}>
          {item.org == 'sum' ? "계" : (item.org.trim().replace(/본부|소방|안전|구조|재난|상|라|청|도|입력/g, ""))}
        </Typography>
      </TableCell>
      <TableCell sx={dcell} >
        <span style={cellnum}>
        {
          item.pump.split("/")[0]*1 +
          item.tank.split("/")[0]*1 +
          item.chem.split("/")[0]*1 +
          item.humg.split("/")[0]*1 +
          item.rscu.split("/")[0]*1 +
          item.emer.split("/")[0]*1 +
          item.heli.split("/")[0]*1 +
          item.control.split("/")[0]*1 +
          item.etcar.split("/")[0]*1+"/"+
          (item.pump.split("/")[1]*1 +
          item.tank.split("/")[1]*1 +
          item.chem.split("/")[1]*1 +
          item.humg.split("/")[1]*1 +
          item.rscu.split("/")[1]*1 +
          item.emer.split("/")[1]*1 +
          item.heli.split("/")[1]*1 +
          item.control.split("/")[1]*1 +
          item.etcar.split("/")[1]*1)
        }
        </span>
      </TableCell>
      <TableCell sx={dcell}><span style={item.pump == "0/0" ? null : cellnum}>{item.pump=="0/0"?"":item.pump}</span></TableCell>
      <TableCell sx={dcell}><span style={item.tank == "0/0" ? null : cellnum}>{item.tank=="0/0"?"":item.tank}</span></TableCell>
      <TableCell sx={dcell}><span style={item.chem == "0/0" ? null : cellnum}>{item.chem=="0/0"?"":item.chem}</span></TableCell>
      <TableCell sx={dcell}><span style={item.humg == "0/0" ? null : cellnum}>{item.humg=="0/0"?"":item.humg}</span></TableCell>
      <TableCell sx={dcell}><span style={item.rscu == "0/0" ? null : cellnum}>{item.rscu=="0/0"?"":item.rscu}</span></TableCell>
      <TableCell sx={dcell}><span style={item.emer == "0/0" ? null : cellnum}>{item.emer=="0/0"?"":item.emer}</span></TableCell>
      <TableCell sx={dcell}><span style={item.heli == "0/0" ? null : cellnum}>{item.heli=="0/0"?"":item.heli}</span></TableCell>
      <TableCell sx={dcell}><span style={item.control == "0/0" ? null : cellnum}>{item.control=="0/0"?"":item.control}</span></TableCell>
      <TableCell sx={dcell}><span style={item.etcar == "0/0" ? null : cellnum}>{item.etcar=="0/0"?"":item.etcar}</span></TableCell>
      <TableCell bgcolor={bcd}>
        {
          item.volunteer.split("/")[0]*1 +
          item.police.split("/")[0]*1 +
          item.military.split("/")[0]*1 +
          item.official.split("/")[0]*1 +
          item.etc.split("/")[0]*1+"/"+
          (item.volunteer.split("/")[1]*1 +
          item.police.split("/")[1]*1 +
          item.military.split("/")[1]*1 +
          item.official.split("/")[1]*1 +
          item.etc.split("/")[1]*1)
        }
      </TableCell>
      <TableCell bgcolor={bcd}>{item.volunteer=="0/0"?"":item.volunteer}</TableCell>
      <TableCell bgcolor={bcd}>{item.police=="0/0"?"":item.police}</TableCell>
      <TableCell bgcolor={bcd}>{item.military=="0/0"?"":item.military}</TableCell>
      <TableCell bgcolor={bcd}>{item.official=="0/0"?"":item.official}</TableCell>
      <TableCell bgcolor={bcd}>{item.etc=="0/0"?"":item.etc}</TableCell>
    </TableRow>
  ));    

  setTimeout(() => {
    sessionStorage.statusHeight = document.getElementById("statusPanel").offsetHeight + 75;
  }, 100);

  const switchstyle = {
    backgroundColor: grey[200],
    
    marginRight: 1,
    marginLeft: 1,
    borderRadius: 3,
  }

  const hidemflist = () => {
    props.LocRefrsh()
  }

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1,
        top: 70,
        marginLeft: 0.8,
        backgroundColor: "white",
        border: "solid 1px black",
      }}
      id="statusPanel"
    >
      
      <Box id="tblcontainer" sx={sessionStorage.oningox == 1 ? statusTitle : statusTitleG}  >
        {checked && 
          <div style={{display:"flex",justifyContent:"center"}}>
            <LightTooltip title="엑셀일괄입력" placement="top" arrow style={{marginTop:"3px"}}>
              <UploadIcon src={excel_up} style={excelbtn} onClick={excelUploadClickHandler}/>
            </LightTooltip>
            <LightTooltip title="엑셀일괄입력 서식 다운로드" placement="top" arrow style={{marginTop:"3px"}}>
              <ListIcon variant="h5" sx={{marginLeft:"3px"}} style={excelbtn} onClick={docdownClickHandler}/>
            </LightTooltip>
          </div>
        }
        <Typography variant="h6" onClick={hidemflist} sx={{fontWeight:"bold"}}>
          상황판
        </Typography>
        <div style={{display:"flex", flexDirection:"row", alignContent:"middle"}}>
          <div><Switch checked={checked} onChange={handleChange} sx={switchstyle} size="small" /></div>
          {checked && // 상황판 보기/숨기기
            <LightTooltip title="엑셀 저장" placement="bottom" arrow style={{marginTop:"3px", zIndex:10}}>
              <DownloadIcon src={excel_down} style={excelbtn} onClick={tableToExcelExport} />
            </LightTooltip>
          }
        </div>
      </Box>    
      
      {checked && 
      <TableContainer component={Paper}>
        <Table
          sx={{
            maxHeight: 800,
            // maxWidth: 600,
            display: { xs: "none", sm: "block" },
          }}
          size="small"
          aria-label="a dense table"
          id="statusTable"
        >
          <TableHead>
            <TableRow >
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}>기관</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><div style={{lineHeight:"1"}}>소방계<br></br>(장비/인원)</div></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>펌프</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>물탱크</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>화학</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>험지</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>구조</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>구급</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>헬기</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>지휘</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? tblstyle : tblstyleG}><Typography sx={eqpBtn} onClick={eqpBtnHandler}>기타차량</Typography></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? volbgc : volbgcG}><div style={{lineHeight:"1"}}>지원계<br></br>(장비/인원)</div></TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? volbgc : volbgcG}>의소대</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? volbgc : volbgcG}>경찰</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? volbgc : volbgcG}>군</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? volbgc : volbgcG}>공무원</TableCell>
              <TableCell sx={sessionStorage.oningox == 1 ? volbgc : volbgcG}>기타</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isdata == false ? mflist == "자료가 없습니다." : mflist}
          </TableBody>
        </Table>
      </TableContainer>
      }
      <span id="result"></span>
    </Box>
    
  );
};
export default StatusPanel;