import React, { useContext } from 'react';
import classes from './Card.module.css';
import { sbHeightCtx } from "../store/sidebarWiHeContext";

const Card = props => {
  const {height} = useContext(sbHeightCtx);
  // console.log(height);
  let sbheight = height*0.9
  return <div style={{height:sbheight}} className={classes.card}>{props.children}</div>
};

export default Card;