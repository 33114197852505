import { Fragment, useEffect } from 'react';
import './DsMap.mudule.css';
let map

function KakaoMap(props) {
  const new_script = (src) => { 
    return new Promise((resolve, reject) => { 
      const script = document.createElement('script'); 
      script.src = src; 
      script.addEventListener('load', () => { 
        resolve(); 
      }); 
      script.addEventListener('error', e => { 
        reject(e); 
      }); 
      document.head.appendChild(script); 
    }); 
  };

  useEffect(() => {
    const w = document.getElementById("mapcntr").clientWidth;
    document.getElementById("dsmap").style.width = w+"px";
  }
  ,[]);

  useEffect(() => { 
    //카카오맵 스크립트 읽어오기
    const my_script = new_script('https://dapi.kakao.com/v2/maps/sdk.js?appkey=b7967dc1f2c2b6f788a029bc7b701f09&libraries=services,clusterer,drawing"');
    //스크립트 읽기 완료 후 카카오맵 설정
    my_script.then(() => { 
      const kakao = window['kakao']; 
      kakao.maps.load(() => {
        const mapContainer = document.getElementById('dsmap');
        const options = { 
          center: new kakao.maps.LatLng(37.884878, 127.729656), //좌표설정
          level: 3 
        }; 
        map = new kakao.maps.Map(mapContainer, options); //맵생성
        //지도종류 선택
        const mapTypeControl = new kakao.maps.MapTypeControl();
        map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
        //스카이뷰를 기본값으로 설정
        map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
      
        //마커설정
        const markerPosition = new kakao.maps.LatLng(37.884878, 127.729656); 
        const marker = new kakao.maps.Marker({ 
          position: markerPosition
        }); 
        marker.setMap(map);
      
        window.kakao.maps.event.addListener(map, 'click', (mouseEvent) => {
          // 클릭한 위치의 좌표를 가져옵니다.
          const latlng = mouseEvent.latLng;
          let lat = latlng.getLat().toFixed(6);
          let lon = latlng.getLng().toFixed(6);
          let addr = "";

          //클릭한 위치의 주소를 가져옵니다.
          const geocoder = new kakao.maps.services.Geocoder();
          geocoder.coord2Address(latlng.getLng(), latlng.getLat(), (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
              addr = result[0].address.address_name.replace("강원특별자치도 ","");
              props.clickedvalue(lat, lon, addr);
            }
            else{
              props.clickedvalue(lat, lon, "주소없음");
            }
          });
          marker.setPosition(latlng);
        });

        // 주소검색 
        const ps = new kakao.maps.services.Places();
        const infowindow = new kakao.maps.InfoWindow({zIndex:1});
        const search = document.getElementById('search');
        search.addEventListener('keydown', (e) => {
          if(e.keyCode === 13){
            e.preventDefault();
            ps.keywordSearch(search.value, placesSearchCB);
          }
        });
        function placesSearchCB(data, status, pagination) {
          if (status === kakao.maps.services.Status.OK) {
            let bounds = new kakao.maps.LatLngBounds();
            for (let i=0; i<data.length; i++) {
              // displayMarker(data[i]);
              bounds.extend(new kakao.maps.LatLng(data[i].y, data[i].x));
            }
            map.setBounds(bounds);
          }
        }
      });  
    }); 
  }, []);

  return (
    <div class="mapctnr">
      <div id="dsmap" class="dsmap" />
      <input type="text" id="search" class="search" placeholder="주소 또는 지명 검색" />
    </div>
  );
}

export default KakaoMap;
