import React, { useState, Fragment, useEffect } from "react";
import { Box, Card, Input } from "@mui/material";
import clss from "./MFregiApp.module.css";
import RegiForm from "./RegiForm";

const MFregiApp = (props) => {
  const [showmain, setShowmain] = useState(false); // 메인화면 표시여부
  const [dstitle, setDstitle] = useState(""); // 선택된 재난정보 제목
  const [nData, setNData] = useState([]); // 활동중인 재난정보 리스트
  const [rfshData, setRfshData] = useState(""); // 갱신요청 변수
  const id = sessionStorage.mfmid; // mfmid: mobilized force member id

  const apiAddr = props.apiAddr;

  useEffect(() => {
    if (id == "undefined") {
      alert("비정상적인 접근입니다.");
      return;
    }
    const getid = async () => {
      // 등록사용자 정보저장
      const wstring = `${apiAddr}/get/getid?id=${id}`;
      //const wstring = `https://map.119survey.org/nodetodb/get/getid?id=${id}`;
      //const wstring = `http://localhost:8080/get/getid?id=${id}`;
      const response = await fetch(wstring);
      const responseData = await response.json();
      console.log(responseData);
      responseData.map((item) => {
        sessionStorage.reg_org = item.fc_org_nm;
        sessionStorage.reg_dpt = item.fc_dpt_nm;
        sessionStorage.reg_rank = item.i_rank;
        sessionStorage.reg_name = item.i_name;
        sessionStorage.reg_pnum = item.i_mphone;
      });
    };
    getid();

    const getActiveDSlist = async () => {
      // 활동중인 재난정보 가져오기
      const wstring = `${apiAddr}/get/getactivedslist`;
      const response = await fetch(wstring);
      const responseData = await response.json();
      console.log(responseData);
      setNData(responseData);
    };
    getActiveDSlist();
  }, [rfshData]);

  const dsselecHandler = (item) => () => {
    let ox = window.confirm("선택하신 재난의 자원등록을 하시겠습니까?");
    if (ox) {
      console.log(item.dsnm);
      sessionStorage.selectedDSname = item.dsnm;
      sessionStorage.selectedDSnum = item.snum;
      sessionStorage.selectedDSid = item.dsnum;
      // setSelectedDS(true);
      setDstitle(item.dsnm);
      setShowmain(true);
    }
  };

  const nactvdslist = nData.map((item) => (
    // console.log(item.dsnm);
    <div style={{cursor: "pointer"}}
      key={Math.random()}
      className={clss.dslisitem}
      onClick={dsselecHandler(item)}
    >
      <h3>{item.dsnm.trim()}</h3>
      <div style={{ fontSize: "1rem" }}>{item.dsaddr.trim()}</div>
      <div style={{ fontSize: "1rem" }}>{item.dsipdt.trim()}</div>
    </div>
  ));

  const btnClickHandler = () => {
    console.log(id);
  };

  const refreshDSHandler = (e) => {
    let ox = window.confirm("다른 재난을 선택하시겠습니까?");
    if (ox) {
      // setSelectedDS(false);
      setShowmain(false);
      setRfshData(Math.random()); // 재난선택화면 갱신
    }
  };

  return (
    <Fragment>
      {!showmain && (
        <>
          <div className={clss.intitle}>재난 선택</div>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              textAlign: "center",
              margin: "10px",
              padding: "10px",
              backgroundColor: "lightgray",
            }}
          >
            동원자원관리시스템에서 각 기관으로부터 동원된 자원을 등록관리하는
            앱입니다. 지정된 사용자가 아니면 사용을 중단합니다.
            <br />
            <br />
            아래 목록에서 등록하고자 하는 재난을 선택하세요
          </p>
          <div className={clss.titlerow}>
            <ul
              style={{
                listStyle: "none",
                padding: "0px",
                margin: "0px",
                width: "70%",
              }}
            >{nactvdslist}</ul>
          </div>
        </>
      )}
      {showmain && (
        <RegiForm 
          apiAddr={apiAddr}
          btnClickHandler={btnClickHandler}
          plusBtnClickHandler={props.plusBtnClickHandler}
          dstitle={dstitle}
          refreshDSHandler={refreshDSHandler}
        />
      )}
    </Fragment>
  );
};

export default MFregiApp;
