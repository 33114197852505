import React, { useState, Fragment, useEffect } from "react";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import io from "socket.io-client";

///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////

const buttonStyle = {
  width: "50px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

export const Mapcontainer = (props) => {
  let thisContainerHeight = window.innerHeight - 70;  // 65 is the height of the navbar
  const [height, setHeight] = useState(thisContainerHeight);
  // const [viewtool, setViewtool] = useState(false);


  ////////////////////////////////////// socket.io ///////////////////////////////////////
  // useEffect(() => {
  //   console.log("socket.on useEffect")
  //   socket.on("stsync", (data) => {  // 소켓서버에서 보낸 자료를 받음
  //     const { name, msg, time } = data;
  //     console.log(+new Date()+" : "+name+" : "+msg+" : "+time);
  //     // const { name, msg, time } = data;
  //     // const item = new LiModel(name, msg, time);
  //     // item.makeLi();
  //     alert(+new Date()+" : "+name+" : "+msg+" : "+time );
  //   });
  // }, []);

  const callgetLoccation = () => {  // 소캣서버로  자료를 보냄
    const text = `동원자원의 위치정보를 갱신합니다. 모든 동원자원에 갱신요청을 보내서 응답온 자원만 갱신되어 표시되며 5초후 지도에 적용됩니다. 동원자원앱이 종료되어 있거나 위치정보제공을 거부한 경우는 정보를 보내지 않습니다. \n\n위치정보를 갱신하시겠습니까? `
    if(window.confirm(text) == false) return;
    const param = {
      sort: "getlocation",
      dsid: sessionStorage.ondsid.trim(),
    };
    console.log(param);
    socket.emit("getlocaiton", param);

    setTimeout(() => { // 5초후 지도에 적용
      props.refreshloc()
    }, 5000);
    timeron();

  };
  ////////////////////////////////////// socket.io ///////////////////////////////////////

  window.addEventListener("resize", () => {
    thisContainerHeight = window.innerHeight - 70;
    setHeight(thisContainerHeight);
  });

  const changemaptypeHandler = (e) => {
    if(e.target.textContent == "지도") props.maptype("roadmap")
    if(e.target.textContent == "위성") props.maptype("hybrid")
    // 클릭한 버튼을 녹색으로 배경 반전
    const btns = document.querySelectorAll("#tool button");
    btns.forEach((btn) => {
      btn.style.backgroundColor = "white";
      btn.style.color = "black";
    });
    e.target.style.backgroundColor = "green";
    e.target.style.color = "white";
  }

  const timeron = () => {
    const timer = document.createElement("span");
    timer.id = "timer";
    timer.className = "timer";
    const body = document.querySelector("body");
    body.appendChild(timer);
    timer.innerHTML = "5";
    // 5초동안 1초마다 1씩 감소하는 타이머
    const interval = setInterval(() => {
      timer.innerHTML = timer.innerHTML - 1;
    }, 1000);
    // 5초 후 타이머 제거
    setTimeout(() => {
      clearInterval(interval);
      timer.remove();
    }, 5000); 
  }

  return (
    <Fragment>    
      
      <div id="map" className="map" style={{height:height}} />
      
      {props.viewmaptool &&
      <div id="tool" className="tool">
        <div>
          <button 
            style={{width:"50px",alignItems:"center",display:"flex",justifyContent:'center'}}
            onClick={callgetLoccation}
          >
            <LocationSearchingIcon fontSize="small"/>갱신
          </button>
          <button onClick={changemaptypeHandler}>지도</button>
          <button onClick={changemaptypeHandler}>위성</button>
        </div>
        <div >
          <span onClick={timeron}>펌프</span>
          <span>물탱크</span>
          <span>기타</span>
        </div>
      </div>
      }
    </Fragment>
  );
};
