import React, { useEffect, useRef, useState} from "react";
import { Box, Grid, Modal, Typography, Button, FormControl, InputLabel, Select, MenuItem, TextField, ButtonGroup } from "@mui/material";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
// import "../Add.css"
import "./DisasterAddForm.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Map from "./DsMap";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

export default function BasicModal(props) {

  const [formInputsValidity, setFormInputsValidity] = useState({
    sort: true,
    name: true,
    address: true,
    date: true,
    lat: true,
    lon: true,
    detailed: true,
  });

  const [sort, setSort] = useState('V'); // 재난종류
  // const [name, setName] = useState("");
  // const [address, setAddress] = useState("");
  const nowDate = new Date().toISOString("YYYY-MM-DDTHH:mm");
  const [date, setDate] = useState(dayjs(nowDate));
  // const [lat, setLat] = useState("");
  // const [lon, setLon] = useState("");
  // const [detailed, setDetailed] = useState("");

  const onSubmitHandler = (event) => { // 재난등록 버튼 클릭시 데이터 전송
    event.preventDefault();
    const enterdSortIsValid = sort.trim() !== 'V';
    const enterdNameIsValid = titleRef.current.value.trim() !== '';
    const enterdAddressIsValid = addrRef.current.value.trim() !== '';
    const enterdDateIsValid = date !== '';
    const enterdLatIsValid = latRef.current.value.trim() !== '';
    const enterdLonIsValid = lonRef.current.value.trim() !== '';
    const enterdDetailedIsValid = detailedRef.current.value.trim() !== '';

    setFormInputsValidity({
      sort: enterdSortIsValid,
      name: enterdNameIsValid,
      address: enterdAddressIsValid,
      date: enterdDateIsValid,
      lat: enterdLatIsValid,
      lon: enterdLonIsValid,
      detailed: enterdDetailedIsValid,
    });

    const formIsValid = 
      enterdSortIsValid &&
      enterdNameIsValid &&
      enterdAddressIsValid &&
      enterdDateIsValid &&
      enterdLatIsValid &&
      enterdLonIsValid &&
      enterdDetailedIsValid;

    if (!formIsValid) {
      alert("모든 항목을 입력해주세요.")
      return;
    }

    //const datastring = sort+"^"+name+"^"+address+"^"+date.format("YYYY-MM-DD HH:mm:ss")+"^"+lat+"^"+lon+"^"+detailed;

    const disasterData = {
      sort: sort,
      name: titleRef.current.value.trim(),
      address: addrRef.current.value.trim(),
      date: date.format("YYYY-MM-DD HH:mm:ss"),
      lat: latRef.current.value.trim(),
      lon: lonRef.current.value.trim(),
      detailed: detailedRef.current.value.trim(),
    };

    console.log(disasterData);

    async function sendDataToDb(disasterData) {
      const wstring = `${props.apiAddr}/post/dsinput`;
      const response = await fetch(wstring, {
      //const response = await fetch('https://map.119survey.org/nodetodb/post/dsinput', {
      //const response = await fetch('http://localhost:8080/post/dsinput', {
      //const response = await fetch('https://react-http-ec9aa-default-rtdb.firebaseio.com/disaster.json', {
        method: 'POST',
        body: JSON.stringify(disasterData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('데이터입력에 오류가 발생하였습니다.!');
      }
      const responseData = await response.json();
      // console.log(responseData);
      console.log(responseData.result);
      const isValid = responseData.result;
      if (isValid == 'success') {
        alert("데이터가 입력되었습니다.");
        props.onDataChanged();
      }else{
        alert("데이터입력에 오류가 발생하였습니다.!");
      }
    }

    sendDataToDb(disasterData);

  };

  const [open, setOpen] = useState(false);
  const sortRef = useRef('');
  const titleRef = useRef('');
  const addrRef = useRef('');
  const dateRef = useRef('');
  const latRef = useRef('');
  const lonRef = useRef('');
  const detailedRef = useRef('');
  
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const confirm = (event) => {
    if (window.confirm("등록하시겠습니까?")) {
      onSubmitHandler(event);
      handleClose();
    }
  };

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const getlocaddr = (rlat, rlon, raddr) => {
    console.log(rlat, rlon, raddr);

    latRef.current.value = " ";
    lonRef.current.value = " "; 
    addrRef.current.value = " ";
    latRef.current.value = rlat+" ";
    lonRef.current.value = rlon; 
    addrRef.current.value = raddr;
  }

  // const inputtitle = () => {
  //   addrRef.current.valueOf = "지도클릭시자동입력";
  //   latRef.current.valueOf = "지도클릭시자동입력";
  //   lonRef.current.valueOf = "지도클릭시자동입력";
  // }


  return (
    <>
      <Button onClick={handleOpen} 
        sx={{
          color: "white",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
      <AddCircleIcon sx={{ fontSize: 36 }} /><h3 style={{color:"white"}}>재난  등록</h3>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h6"
            color="gray"
            textAlign="center"
            mb={1}
          >
            재난등록
          </Typography>

          <form onSubmit={onSubmitHandler}>
            <Box>
              <Grid item={true} container spacing={0}>
                <Grid item={true} className="g1" xs={3} style={{borderTop:"1px solid grey"}}>
                  재난종류
                </Grid>
                <Grid item={true} className="g2" xs={9} style={{borderTop:"1px solid grey"}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      재난선택
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="sort"
                      value={sort}
                      ref={sortRef}
                      label="sort"
                      onChange={(e) => setSort(e.target.value)}
                      size="small"
                    >
                      <MenuItem value={'V'}>항목선택</MenuItem>
                      <MenuItem value={'F'}>대형화재</MenuItem>
                      <MenuItem value={'R'}>수색구조</MenuItem>
                      <MenuItem value={'E'}>다수사상자</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} className="g1" xs={3}>
                  재난명
                </Grid>
                <Grid item={true} className="g2" xs={9}>
                  <TextField
                    id="outlined-basic"
                    label="재난명"
                    variant="outlined"
                    size="small"
                    inputRef={titleRef}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item={true} className="g1" xs={3}>
                  주소
                </Grid>
                <Grid item={true} className="g2" xs={9}>
                  <TextField
                    id="address"
                    label="주소(지도클릭시 자동입력)"
                    variant="outlined"
                    size="small"
                    inputRef={addrRef}
                    fullWidth
                    InputLabelProps={{shrink:true}}
                  />
                </Grid>
                <Grid item={true} className="g1" xs={3}>
                  발생일시
                </Grid>
                <Grid item={true} className="g2" xs={9}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ko"
                  >
                    <DemoContainer
                      components={["DateTimeField", "DateTimeField"]}
                    >
                      <DateTimeField
                        label="발생일시"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        size="small"
                        format="YYYY-MM-DD HH:mm:ss"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item={true} className="g1" xs={3}>
                  좌표
                </Grid>
                <Grid item={true} className="g2" xs={9}>
                  <TextField
                    id="outlined-basic"
                    label="위도(지도클릭시 자동입력)"
                    variant="outlined"
                    size="small"
                    inputRef={latRef}
                    InputLabelProps={{shrink:true}}
                  />
                  <TextField
                    id="outlined-basic"
                    label="경도(지도클릭시 자동입력)"
                    variant="outlined"
                    size="small"
                    inputRef={lonRef}
                    InputLabelProps={{shrink:true}}
                  />
                </Grid>
                <Grid item={true} className="g1" xs={3} sx={{height:"300px"}} >
                  지도
                </Grid>
                <Grid id="mapcntr" item={true} className="g2" xs={9} sx={{height:"300px"}} >
                   <Map id="map" clickedvalue={getlocaddr} />
                </Grid>
                <Grid item={true} className="g1" xs={3}>
                  세부내용
                </Grid>
                <Grid item={true} className="g2" xs={9}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="재난 개요, 현재 양상 등 세부내용"
                    size="small"
                    inputRef={detailedRef}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item={true} xs={12} justifyContent={"center"}>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined button group"
                  sx={{ mt: 1, gap: 2 }}
                >
                  <Button bgcolor={"green"} onClick={confirm}>
                    등록
                  </Button>
                  <Button bgcolor={"red"} onClick={handleClose}>취소</Button>
                </ButtonGroup>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* {setTimeout(() => { inputtitle()}, 100)} */}
    </>
  );
}
