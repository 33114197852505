import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { TitleSharp } from "@mui/icons-material";

export default function BasicPopover(props) {
  //console.log(props.onStatus)
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const btnClickHandler = (e) => {
    let snum = e.target.id;
    let sort = e.target.textContent;
    let qrSort = "";

    const dsManage = async () => {
      if (window.confirm(sort + "하시겠습니까? ")) {
        const wstring = `${props.apiAddr}/get/${qrSort}?id=${snum}`;
        //let wstring = `https://map.119survey.org/nodetodb/get/${qrSort}?id=${snum}`;
        //let wstring = `http://localhost:8080/get/${qrSort}?id=${snum}`;
        console.log(wstring);
        const response = await fetch(wstring);
        // console.log(response);
        const responsedata = await response.json();
        console.log("result is : " + responsedata.result);
        if (responsedata.result === "success") {
          // alert("수정완료");
          props.onDataChanged();
          if(qrSort == 'dsstart'){ 
            if(snum == sessionStorage.onid){ 
              sessionStorage.oningox = 1;
              props.onStatus(Math.random()); // 재난상태변경 프롭 호출
            }
          }
          if(qrSort == 'dsend'){
            if(snum == sessionStorage.onid){
              sessionStorage.oningox = 0;
              props.onStatus(Math.random()); // 재난상태변경 프롭 호출
            }
          }
        } else {
          alert("오류발생");
        }
      }
    }

    const onManage = async () => {
      if(window.confirm(sort + "하시겠습니까? ")) {
        let arr = e.target.name.split("^");
        console.log(arr);

        if(sessionStorage.ondsid == undefined){sessionStorage.firstSelDs = arr[5]}
        sessionStorage.onid = arr[0];
        sessionStorage.onname = arr[1];
        sessionStorage.onipdt = arr[2];
        sessionStorage.onaddr = arr[3];
        sessionStorage.oningox = arr[4];
        sessionStorage.ondsid = arr[5].trim();
        sessionStorage.ondslat = arr[6].trim();
        sessionStorage.ondslon = arr[7].trim();
        props.onStatus(arr[1]);
        props.onSelectDS();
        props.hdmf()
        props.mapfresh()
      }
    }

    if(sort == "종료"){qrSort = 'dsend'; dsManage()}
    else if(sort == "진행"){qrSort = 'dsstart'; dsManage()}
    else if(sort == "수정"){qrSort = 'dsupdate'; dsManage()}
    else if(sort == "삭제"){qrSort = 'dsdel'; dsManage()}
    else if(sort == "상황선택"){qrSort = 'dsselect'; onManage()}
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let txt = "";

  return (
    <div>
      {props.ingox === "1" ? (
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          style={{ backgroundColor: "red" }}
        >
          <Typography>진 행</Typography>
        </Button>
      ) : (
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          style={{ backgroundColor: "black" }}
        >
          <Typography>종 료</Typography>
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: 2 }} gap={3}>
            {props.ingox === "1" ? (
              <Button
                id={props.id}
                style={{ color: "black", border: "1px solid black" }}
                onClick={btnClickHandler}
              >
                종료
              </Button>
            ) : (
              <Button
                id={props.id}
                style={{ color: "red", border: "1px solid black" }}
                onClick={btnClickHandler}
              >
                진행
              </Button>
            )}
            {/* <Button
              id={props.id}
              style={{ color: "green", border: "1px solid black" }}
              onClick={btnClickHandler}
            >
              수정
            </Button> */}
            <Button
              id={props.id}
              style={{ color: "grey", border: "1px solid black" }}
              onClick={btnClickHandler}
            >
              삭제
            </Button>
          </Box>
          <Box style={{marginRight:"1rem"}}>
            <Button
              id={props.id}
              style={{ color: "white", border: "3px solid black", backgroundColor:"orange" }}
              onClick={btnClickHandler}
              name={`${props.id}^${props.name.trim()}^${props.ipdt.trim()}^${props.addr.trim()}^${props.ingox}^${props.dsid}^${props.lat}^${props.lon}`}
            >
              상황선택
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
