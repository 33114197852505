import React from "react";
import "react-chat-elements/dist/main.css";
import { MessageBox, ChatList } from "react-chat-elements";
import { Box } from "@mui/material";
import { red, blue } from "@mui/material/colors";
import ChatApp from "../Contents/ChatApp";
// import Iframe from "react-iframe";

const Chatting = (props) => {
  // console.log(props);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // width: "400px",
        // height: "700px",
        position: "sticky",
        zIndex: 10,
        top: 70,
        // right: 20,
        backgroundColor: blue[50],
        // border: "solid 1px black",
        width:"98%",
        marginLeft:"auto",
        marginRight:"auto",
      }}
    >
      <ChatApp
        
        // height="300px"
        id=""
        className=""
        display="block"
        position="relative"
        chatname={props.chatname}
        height={props.height}
        apiAddr={props.apiAddr}
      />
    </Box>
  );
};

export default Chatting;
