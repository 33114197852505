import React, { useState,useRef } from 'react';
import './Map.css';
import { useEffect } from 'react';
import { Mapcontainer } from './Mapcontainer';
import axios from 'axios';
import io from "socket.io-client";
let map;
let marker

const beforeOverlays = []; // 기존마커삭제를 위한 배열 선언
const nowOverlays = []; // 현재마커를 저장하기 위한 배열 선언
const markerArr = []; // 마커를 담을 배열 선언  
const beforePhotoOverlays = []; // 기존사진마커삭제를 위한 배열 선언
const nowPhotoOverlays = []; // 현재사진마커를 저장하기 위한 배열 선언

///////////// socket.io //////////////
const socket = io("https://chat.119survey.org/");
///////////// socket.io //////////////


function KakaoMap(props) {  
  const [getloc, setGetloc] = useState(false);
  const startRef = useRef(0); // 현재 재난키 저장용 ref (재난키가 변경되면 맵을 새로 그림)
  const [maptype, setMaptype] = useState(false); // 지도종류 (roadmap, hybrid) 
  
  ///////////// socket.io //////////////
  useEffect(() => {
    socket.on("syncphoto", (data) => {  // 소켓서버에서 보낸 자료를 받음(사진목록 갱신요청)
      const { dsid } = data;
      if(dsid == sessionStorage.ondsid.trim()){
        setGetloc(Math.random());
      }
    });
  }, []);



  //스크립트 파일 읽어오기
  // alert("갱신요청 프롭은 ? : " + props.LocRefrsh)
  const new_script = (src) => { 
    return new Promise((resolve, reject) => { 
      const script = document.createElement('script'); 
      script.src = src; 
      script.addEventListener('load', () => { 
        resolve(); 
      }); 
      script.addEventListener('error', e => { 
        reject(e); 
      }); 
      document.head.appendChild(script); 
    }); 
  };

  const refreshLocation = () => { 
    setGetloc(Math.random());
  }
  
  useEffect(() => {
    // startRef.current = sessionStorage.ondsid;
    // alert("startRef : " + startRef.current)
    //카카오맵 스크립트 읽어오기
    // const my_script = new_script('https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=b7967dc1f2c2b6f788a029bc7b701f09');
    const my_script = new_script('https://dapi.kakao.com/v2/maps/sdk.js?appkey=b7967dc1f2c2b6f788a029bc7b701f09&libraries=services,clusterer,drawing');
    
    //스크립트 읽기 완료 후 카카오맵 설정

    my_script.then(() => { 
      console.log('지도 script loaded!!!');  
      const kakao = window['kakao']; 
      let lat, lon;
      const ismap = !(!map) // 지도 유무 확인
      kakao.maps.load(() => {        

        // 최초 재난위치 가져오기 맵 생성 재난선택시 1번만 실행
        const getDSlocation = () => {
          sessionStorage.firstSelDs = sessionStorage.ondsid;
          lat = sessionStorage.ondslat 
          lon = sessionStorage.ondslon
          const mapContainer = document.getElementById('map');
          const options = { 
            center: new kakao.maps.LatLng(lat, lon), //좌표설정
            level: 5 
          }; 

          if(startRef.current < 3) { // 최초 맵실행이면 무조건 맵 생성(맵생성 여부 확인을 건너뜀)
            map = new kakao.maps.Map(mapContainer, options);
          }

          if(markerArr.length > 0){ // 기존 재난위치마커를 삭제
            for (const marker of markerArr) {
              marker.setMap(null);
            }
          }

          const markerPosition = new kakao.maps.LatLng(lat, lon);  
          marker = new kakao.maps.Marker({ 
            position: markerPosition
          }); 

          marker.setMap(map); 
          marker.setPosition(markerPosition);
          markerArr.push(marker); // 마커를 담을 배열에 추가
          // 지도를 마커의 위치로 중심이동
          map.setCenter(markerPosition);

          getCarLocation() // 전체 차량위치 가져오기 호출
        }

        // 전체 차량위치 가져오기
        const getCarLocation = async () => {
          const dsid = sessionStorage.ondsid;
          const wstring = `${props.apiAddr}/get/getcarcoor?dsid=${dsid}`;
          console.log(wstring);
          const responsedata = await axios.get(wstring);
          const carlocation = responsedata.data;
          // console.log(carlocation)

          carlocation.map((car) => {
            const markerPosition = new kakao.maps.LatLng(car.mflat, car.mflon);
            //배경이 빨간색의 div를 사용하는 content 생성
            var content = document.createElement('div');
            content.title = car.mfcsgn;
            if(car.mfeqp.trim() == '소방펌프' || car.mfeqp.trim() == '소방험지'){content.style.background = 'red'}
            else if(car.mfeqp.trim() == '소방물탱크'){content.style.background = 'blue'}
            else{content.style.background = 'orange'}
            content.style.width = '15px';
            content.style.height = '15px';
            content.style.border = '1px solid #000000';
            // content.style.borderRadius = '50%';

            var customOverlay = new kakao.maps.CustomOverlay({
              position: markerPosition,
              content: content,
              clickable: false,
              yAnchor: 0.5,
            });

            nowOverlays.push(customOverlay);
            // sessionStorage.overlays = nowOverlays;
            customOverlay.setMap(map);
          })

          

          if(startRef.current > 2) {
            for (const overlay of beforeOverlays) {
              overlay.setMap(null);
              console.log(overlay.position)
            }
          }

          // beforeOverlays 에 nowOverlays 를 복사
          beforeOverlays.length = 0;
          beforeOverlays.push(...nowOverlays);
          // nowOverlays 초기화
          nowOverlays.length = 0;

          // console.log(beforeOverlays)
          // console.log(nowOverlays)
          // console.log(startRef.current )
        }

        const getPhotoList = async () => {
          const dsid = sessionStorage.ondsid;
          const wstring = `${props.apiAddr}/get/getphotolist?dsid=${dsid}`;
          console.log(wstring);
          const responsedata = await axios.get(wstring);
          const photolist = responsedata.data;
          // console.log(photolist)

          photolist.map((photo) => {
            const markerPosition = new kakao.maps.LatLng(photo.clat, photo.clon);
            const filepath = `https://mfm.119survey.org/uploads/${photo.cfilename}` // 파일경로  

            const spn = document.createElement('span');
            spn.classList.add('photo');
            const img = document.createElement('img');
            img.src = filepath;            
            img.title = photo.cname;
            const imgcontainer = document.createElement('div'); // 사진크게보기 컨테이너는 처음에 숨김으로 있다가 사진을 클릭하면 하단에 보이게 함
            imgcontainer.classList.add('imgcontainer');
            imgcontainer.style.display = 'none';
            img.addEventListener('click', () => { // 사진 클릭시   imgcontainer 보이기 토글
              if(imgcontainer.style.display == 'none'){
                imgcontainer.style.display = 'block'
                const zoomimg = document.createElement('img');
                zoomimg.addEventListener('click', () => { // 사진 클릭시  imgcontainer 숨기기 
                  imgcontainer.style.display = 'none'
                  imgcontainer.innerHTML = '';
                });
                zoomimg.src = filepath;
                console.log(filepath)
                // zoomimg를 가로세로중 큰값을 100% 설정하되 300px보다 크면 300px로 설정
                if(img.width > img.height){
                  if(img.width > 300){
                    zoomimg.style.width = '300px';
                    zoomimg.style.height = 'auto';
                  }else{
                    zoomimg.style.width = 'auto';
                    zoomimg.style.height = '100%';
                  }
                }else{
                  if(img.height > 300){
                    zoomimg.style.width = 'auto';
                    zoomimg.style.height = '300px';
                  }else{
                    zoomimg.style.width = '100%';
                    zoomimg.style.height = 'auto';
                  }
                }
                imgcontainer.appendChild(zoomimg);
              }else{
                imgcontainer.style.display = 'none'
                imgcontainer.innerHTML = '';
              }
            });
            spn.appendChild(img);
            spn.appendChild(imgcontainer);
            var content = spn
          
            var customPhotoOverlay = new kakao.maps.CustomOverlay({
              position: markerPosition,
              content: content,
              yAnchor: -0.5,
            });

            nowPhotoOverlays.push(customPhotoOverlay); // 현재마커를 배열에 저장
            customPhotoOverlay.setMap(map); // 지도에 마커를 표시합니다
          })

          if(startRef.current > 2) { 
            for (const overlay of beforePhotoOverlays) { // 기존마커 삭제
              overlay.setMap(null);
            }
          }

          // beforeOverlays 에 nowOverlays 를 복사
          beforePhotoOverlays.length = 0;  // 기존마커 삭제
          beforePhotoOverlays.push(...nowPhotoOverlays); // 현재마커를 기존마커에 저장
          nowPhotoOverlays.length = 0; // 현재마커 초기화

          // console.log(beforePhotoOverlays)
          // console.log(nowPhotoOverlays)
          // console.log(startRef.current )
        }
        getPhotoList()
        
        //마커추가
        // const markerPosition1 = new kakao.maps.LatLng(38.371602, 128.506218);
        // const marker1 = new kakao.maps.Marker({ 
        //   position: markerPosition1
        // });
        // marker1.setMap(map);

        // const marker = new kakao.maps.Marker({ 
        //   title: car.mfcsgn,
        //   // content:`<div className=${redmarker}></div>`,
        //   image: new kakao.maps.MarkerImage(null, new kakao.maps.Size(30, 30)),
        //   position: markerPosition
        // });
        //마커를 맵에 추가

        // alert(startRef.current)
        if(startRef.current === 2) { // 최초 맵실행이면 무조건 맵 생성(맵생성 여부 확인을 건너뜀)
          getDSlocation()
        }else{
          if(sessionStorage.ondsid == sessionStorage.firstSelDs){ // 재난위치변경이 없으면 생성하지 않음
            if(sessionStorage.ondsid != undefined){
              getCarLocation() // 전체 차량위치 가져오기 호출
            }
          }else{ // 재난위치변경이 있으면 맵 재생성
            getDSlocation() // 맵 재생성
          }
          if(ismap){// 맵이 있으며 생성하지 않음
            getCarLocation() // 전체 차량위치 가져오기 호출
          } 
        }

        if(startRef.current > 2) { // 최초 맵실행이면 무조건 맵 생성(맵생성 여부 확인을 건너뜀)
          if(maptype){
            map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
          }else{
            map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP); 
          }
        }

      });   
    }); 
    ++startRef.current;
  }, [props.mapfresh, getloc]);

  const changeMatype = (type) => {
    if(type == "roadmap") setMaptype(false)
    if(type == "hybrid") setMaptype(true)
    // setMaptype(!maptype)
    setGetloc(Math.random());
  }

  return (
    <div className="App">
      <Mapcontainer refreshloc={refreshLocation} maptype={changeMatype} viewmaptool={props.viewmaptool} />
    </div>
  );
}

export default KakaoMap;